<template>
    <v-card rounded="lg" class="bg-white py-4 px-5">
 
	<p class="text-uppercase text-h4">TOKEN</p>
    <p class="">
      Endpoint digunakan untuk menghasilkan token yang harus disertakan di setiap panggilan API. Token ini didapatkan setelah login JWTr.
    </p>
    </v-card>
</template>

<script>
export default {
    setup() {
        return {
        };
    },
};
</script>
