export default [
  { title: "Payment Point", icon: "", link: "/econtract/payment-point" },
  { title: "Standalone Stamping", icon: "", link: "/econtract/standalone" },
  { title: "Document", icon: "", link: "/docs/guide/document" },
  { title: "Auth", icon: "", link: "/docs/guide/auth" },
  { title: "Inventory", icon: "", link: "/docs/guide/inventory" },
  { title: "Contact", icon: "", link: "/docs/guide/contact" },
  { title: "Homepage", icon: "", link: "/docs/guide/homepage" },
  { title: "Order/Topup", icon: "", link: "/docs/api-services/top-up" },
  { title: "Notification", icon: "", link: "/docs/guide/notification" },
  { title: "Speciment", icon: "", link: "/docs/guide/speciment" },
  { title: "Master", icon: "", link: "/docs/guide/master" },
  { title: "Voucher", icon: "", link: "/docs/guide/voucher" }
];

