<template>
  <v-card rounded="lg" class="bg-white py-4">
		<!-- Create Schedule Disbursement -->
		<div class="col-md-8 ps-0">	
			<p class="text-h5">Create Schedule Disbursement</p>
			<p class="font-weight-bold">
				API ini digunakan untuk proses membuat pembayaran terjadwal. 
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabCr" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Content Type
							</th>
							<th class="text-left text-white">
								Client Id
							</th>
							<th class="text-left text-white">
								Api Key
							</th>
							<th class="text-left text-white">
								X-access-token
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in headCr" :key="item.key">
							<td>{{ item.type }}</td>
							<td>{{ item.id }}</td>
							<td>{{ item.api }}</td>
							<td>{{ item.token }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in bodyCr" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h6">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/etransfer/schedule/create' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNTEyNjY3NCwiZXhwIjoxNjM1MjEzMDc0fQ.Tb5LSaFHeZXZeOpbfIcUmMWEE2JK9Us5DeSttzAEaZw' \
				--data-raw '{<br>
						"title":"Tes",<br>
						"description":"deskripsi",<br>
						"recurring":false,<br>
						"recurring_periode":"anually",<br>
						"recurring_date":"2021-10-26",<br>
						"type":"bulk",<br>
						"receipients":[<br>
							{"receipient_bank_id":"7acb445c-c72b-4a49-911f-b374664d451b"<br>,"receipient_account_name":"Ngatiyem","receipient_account_number":"43546456567","amount":500,"note":"tidak ada"}<br>
						}'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>

		<!-- List Schedule Disbursement -->
		<div class="col-md-8 ps-0">	
			<p class="text-h5">List Schedule Disbursement</p>
			<p class="font-weight-bold">
				API ini digunakan untuk mengambil  / menampilkan daftar pembayaran terjadwal
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabList" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Content Type
							</th>
							<th class="text-left text-white">
								Client Id
							</th>
							<th class="text-left text-white">
								Api Key
							</th>
							<th class="text-left text-white">
								X-access-token
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in headCr" :key="item.key">
							<td>{{ item.type }}</td>
							<td>{{ item.id }}</td>
							<td>{{ item.api }}</td>
							<td>{{ item.token }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h6">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/etransfer/schedule' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNDI3ODA5NywiZXhwIjoxNjM0MzY0NDk3fQ.YXCkUfNE1aslw0V1Bei2lv5yKsLfnnEMG4SblV_E6HE'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>

		<!-- Schedule Detail -->
		<div class="col-md-8 ps-0">	
			<p class="text-h5">Schedule Detail</p>
			<p class="font-weight-bold">
				API ini digunakan untuk menampilkan detail jadwal pembayaran.
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabDetail" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Content Type
							</th>
							<th class="text-left text-white">
								Client Id
							</th>
							<th class="text-left text-white">
								Api Key
							</th>
							<th class="text-left text-white">
								X-access-token
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in headCr" :key="item.key">
							<td>{{ item.type }}</td>
							<td>{{ item.id }}</td>
							<td>{{ item.api }}</td>
							<td>{{ item.token }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h6">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/etransfer/schedule/receipients/dfadc8f7-b661-4257-b3f6-604d9d36a8b9' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNDgwMDk4NSwiZXhwIjoxNjM0ODg3Mzg1fQ.To-pTQHIxevyOuVnj9Ef3HhtZXVeZSYmHM5_qr8kDOE'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>

		<!-- Schedule List -->
		<div class="col-md-8 ps-0">	
			<p class="text-h5">Schedule Recipients List</p>
			<p class="font-weight-bold">
				API ini digunakan untuk menampilkan daftar penerima pembayaran secara terjadwal.
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabR" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h6">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/etransfer/disbursement/schedule/receipients/47e2ecb9-0798-430f-91e2-23904cfc22f0'</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>
	</v-card>
</template>

<script>
export default {
   data() {
    return {
			tabCr: 
			[
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/schedule/create',
        },
      ],
      bodyCr: 
			[
        {
          field: 'title',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'description',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'recurring',
          type: '',
          mandatory: '',
          length: '',
          info: 'Jika recurring adalah true, recurring_periode dan recurring date wajib diisi opsi untuk recurring periode : Weekly, monthly, yearly',
          ex:''
        },
        {
          field: 'recurring_periode',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'recurring_date',
          type: '',
          mandatory: '',
          length: '',
          info: 'dengan menggunakan format “YYYY/MM/DD”',
          ex:''
        },
        {
          field: 'receipient_bank_id',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'receipient_account_name',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'receipient_account_number',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'amount',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'type',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'note',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
      ],
      headCr:
      [
				{
					type:'',
					id:'',
					api:'',
					token:'Token JWT yang didapatkan setelah login'
				}
      ],
      tabList: 
			[
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/schedule',
        },
      ],
      tabDetail: 
			[
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/schedule/receipients/dfadc8f7-b661-4257-b3f6-604d9d36a8b9',
        },
      ],
      tabR: 
			[
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/disbursement/schedule/receipients/47e2ecb9-0798-430f-91e2-23904cfc22f0',
        },
      ],
    };
  },
};
</script>



