<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
    <vue-markdown :source="folders"> </vue-markdown>
  </v-card>
</template>

<script>
import folders from "./folders.md";
import prism from "prismjs"
export default {
    mounted() {
    prism.highlightAll()
  },
    setup() {
    return {
      folders,
    };
  },
};
</script>
