<template>
    <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">ERROR</p>
		<p class="">
		Error code mendeskripsikan tentang object kesalahan / error.</p>
		
		<div class="col-md-8 ps-0">			
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<tbody>
						<tr>
							<td>Pesan String</td>
							<td>
								Example:
								<ul>
									<li>"E-mail already exists"</li>
									<li>"E-mail sudah terdaftar"</li>
									<li>"Password Salah"</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
    </v-card>
</template>

<script>
export default {
    setup() {
        return {
        };
    },
};
</script>
