export default [
  { title: "Get Balance", icon: "", link: "/stamping/get-balance" },
  { title: "Upload File", icon: "", link: "/stamping/upload-file" },
  { title: "Upload Meterai", icon: "", link: "/stamping/upload-meterai" },
  { title: "Generate OTP", icon: "", link: "/stamping/generate-otp" },
  { title: "Verify OTP", icon: "", link: "/stamping/verify-otp" },
  { title: "Stamping", icon: "", link: "/stamping/stamping" },
  { title: "Generate QR & Link", icon: "", link: "/stamping/generate-qr-link" },
  { title: "Get Balance Agent", icon: "", link: "/stamping/get-balance-agent" },
];
