<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">Publish</p>
    <!-- <p class="font-weight-bold">
      API ini digunakan untuk menambahkan nama - nama channel doc yang digunakan. 
    </p> -->
    <publish />
	</v-card>
</template>

<script>
import publish from './content-certif/publish.vue';

export default {
	components: {
    publish,
   },
   data() {
    return {
    };
  },
};
</script>



