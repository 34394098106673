<template>
  <v-card rounded="lg" class="bg-white py-4">
		<!-- upload -->
		<div class="col-md-8 ps-0">	
			<p class="text-h5">Upload</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabUp" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in bodyUp" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h6">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api.momofin.com//disbursement/upload' \
				--form 'file=@"/home/haku/Documents/test.xlsx"'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>

		<!-- Validate -->
		<div class="col-md-8 ps-0">	
			<p class="text-h5">Validate</p>
			<!-- <p class="font-weight-bold">
				Api ini digunakan untuk menambahkan detail akun bank yang digunakan.
			</p> -->
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabVal" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Content Type
							</th>
							<th class="text-left text-white">
								Client Id
							</th>
							<th class="text-left text-white">
								Api Key
							</th>
							<th class="text-left text-white">
								X-access-token
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in headVal" :key="item.key">
							<td>{{ item.type }}</td>
							<td>{{ item.id }}</td>
							<td>{{ item.api }}</td>
							<td>{{ item.token }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in bodyVal" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h6">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/etransfer/disbursement/validate' \
				--header 'x-access-token: token' \
				--data-raw '{
						"id":"disbusement id",
						"type":"disbursement type , tidak dibutuhkan untuk single disbursement, untuk bulk, isi dengan bulk"
				}'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>

		<!-- Approval -->
		<div class="col-md-8 ps-0">	
			<p class="text-h5">Approval</p>
			<!-- <p class="font-weight-bold">
				Api ini digunakan untuk menambahkan detail akun bank yang digunakan.
			</p> -->
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabAp" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Content Type
							</th>
							<th class="text-left text-white">
								Client Id
							</th>
							<th class="text-left text-white">
								Api Key
							</th>
							<th class="text-left text-white">
								X-access-token
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in headVal" :key="item.key">
							<td>{{ item.type }}</td>
							<td>{{ item.id }}</td>
							<td>{{ item.api }}</td>
							<td>{{ item.token }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in bodyVal" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h6">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/etransfer/disbursement/approval' \
				--header 'x-access-token: token' \
				--data-raw '{
						"id":"disbusement id",
						"type":"disbursement type , tidak dibutuhkan untuk single disbursement, untuk bulk, isi dengan bulk"
				}'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>
	</v-card>
</template>

<script>
export default {
   data() {
    return {
			tabUp: 
			[
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api.momofin.com/disbursement/upload',
        },
      ],
      bodyUp: 
			[
        {
          field: 'file',
          type: 'file',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
      ],
      tabVal: 
			[
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/disbursement/validate',
        },
      ],
      bodyVal: 
			[
        {
          field: 'id',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'type',
          type: 'String',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
      ],
      headVal:
      [
				{
					type:'',
					id:'',
					api:'',
					token:'Token JWT yang didapatkan setelah login'
				}
      ],
      tabAp: 
			[
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/disbursement/approval',
        },
      ],
    };
  },
};
</script>



