<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
    <p class="text-uppercase text-h4">Approve & Release</p>    
    <approved />
  </v-card>
</template>

<script>
import approved from './content-disb/approved.vue';

export default {
  components: {
    approved,
   },
   data() {
    return {
    };
  },
};
</script>



