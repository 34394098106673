<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">User Management</p>
		<register />
		<login />
		<refresh />
		<forgot />
		<reset />

    <p class="text-capitalize text-h5">check token</p>
    <p class="font-weight-bold">
      Informasi Umum
    </p>
    <p class="font-weight-bold">
      API ini digunakan untuk cek token yang digunakan service yang lain.
    </p>
    <div class="col-md-8 ps-0">     
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tab" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Key
              </th>
              <th class="text-left text-white">
                Value
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Deskripsi
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in head" :key="item.key">
              <td>{{ item.key }}</td>
              <td>{{ item.val }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.desc }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="text-h5">Request & Response Payload Sample</p>      
      <p class="font-weight-bold mb-1">Request</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/auth/users/check_token' \
        --header 'x-access-key: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImQyYzM2ZDMwLTAxNWEtMTFlYy05ZWZjLThiZGFjNzM2ZjdmNCIsImlhdCI6MTYyOTY4MzkyNCwiZXhwIjoxNjMwMjg4NzI0fQ.xgSHR49wDZpTrMg5FnHTLVibQ7CegZ_uj0N-aQ3t9Ag'
        </pre></vue-code-highlight>
        </p>
      </v-card>
    </div>

    <p class="text-capitalize text-h5">resend email verfication</p>
    <p class="font-weight-bold">
      Informasi Umum
    </p>
    <p class="font-weight-bold">
      API ini digunakan untuk melakukan proses resend / kirim ulang email verifikasi.
    </p>
    <div class="col-md-8 ps-0">     
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabsend" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Body Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Field
              </th>
              <th class="text-left text-white">
                Data Type
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Informasi Umum
              </th>
              <th class="text-left text-white">
                Contoh
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in body" :key="item.key">
              <td>{{ item.field }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.info }}</td>
              <td>{{ item.ex }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="text-h5">Request & Response Payload Sample</p>      
      <p class="font-weight-bold mb-1">Request</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/auth/users/email_verification' \
        --data-raw '{
            "email":"budi123@gmail.com"
        }'
        </pre></vue-code-highlight>
        </p>
      </v-card>
    </div>

    <p class="text-capitalize text-h5">email verification</p>
    <p class="font-weight-bold">
      Informasi Umum
    </p>
    <p class="font-weight-bold">
      API ini digunakan untuk mengirimkan email verifikasi.
    </p>
    <div class="col-md-8 ps-0">     
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabEm" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="text-h5">Request & Response Payload Sample</p>      
      <p class="font-weight-bold mb-1">Request</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/auth/users/verification-account/e8b7d827f220bff8ce5a01fbae38fb181b107b3aebc52777a4abc347b9075eac'</pre></vue-code-highlight>
        </p>
      </v-card>
    </div>
	</v-card>
</template>

<script>
import register from './registrasi.vue';
import login from './login.vue';
import refresh from './refresh-token.vue';
import forgot from './lupa-password.vue';
import reset from './reset-password.vue';
export default {
	components: {
    register,
    login,
    refresh,
    forgot,
    reset
  },
   data() {
    return {
			tab: 
      [
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/auth/users/check_token',
        },
      ],
      head: 
      [
        {
          key: '',
          val: ' ',
          mandatory: '',
          length: ' ',
          desc: 'Token JWT yang didapatkan setelah login.',
        },
      ],
      tabsend: 
      [
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/auth/users/email_verification',
        },
      ],
      body: 
      [
        {
          field: 'Email',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: 'Email User',
          ex:'abc@abc.com'
        },
      ],
      tabEm: 
      [
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/auth/users/verification-account/e8b7d827f220bff8ce5a01fbae38fb181b107b3aebc52777a4abc347b9075eac',
        },
      ],
    };
  },
};
</script>



