<template>
    <v-card rounded="lg" class="bg-white py-4 px-5">
        <vue-markdown :source="pythonSdk"> </vue-markdown>
    </v-card>
</template>

<script>
import pythonSdk from "./python-sdk.md";
export default {
    setup() {
        return {
            pythonSdk,
        };
    },
};
</script>
