<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">E-TRANSFER</p>
		<bank />
		<trans />
	</v-card>
</template>

<script>
import bank from './content-trans/bank.vue';
import trans from './content-trans/trans.vue';

export default {
	components: {
    bank,
    trans
   },
   data() {
    return {
    };
  },
};
</script>



