<template>
  <v-card rounded="lg" class="bg-white py-4">
		<!-- Detail -->
		<div class="col-md-8 ps-0">	
			<p class="text-h5">Detail</p>
			<!-- p class="font-weight-bold">
				API ini digunakan untuk proses membuat pembayaran terjadwal. 
			</p> -->
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabDetail" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Content Type
							</th>
							<th class="text-left text-white">
								Client Id
							</th>
							<th class="text-left text-white">
								Api Key
							</th>
							<th class="text-left text-white">
								X-access-token
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in headCr" :key="item.key">
							<td>{{ item.type }}</td>
							<td>{{ item.id }}</td>
							<td>{{ item.api }}</td>
							<td>{{ item.token }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h6">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/etransfer/disbursement/detail/73c2047b-5291-47f2-8378-70c3756926bd' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNDE3OTg4MSwiZXhwIjoxNjM0MjY2MjgxfQ.yJ2UidqXpMi7xp0oq29ONPUgSjDf6vWQbyL4GNYTDgo'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>

		<!-- Summary -->
		<div class="col-md-8 ps-0">	
			<p class="text-h5">Summary</p>
			<p class="font-weight-bold">
				API ini digunakan untuk menampilkan rangkuman / ringkasan detail pembayaran
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabSummary" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Content Type
							</th>
							<th class="text-left text-white">
								Client Id
							</th>
							<th class="text-left text-white">
								Api Key
							</th>
							<th class="text-left text-white">
								X-access-token
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in headCr" :key="item.key">
							<td>{{ item.type }}</td>
							<td>{{ item.id }}</td>
							<td>{{ item.api }}</td>
							<td>{{ item.token }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h6">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/etransfer/disbursement/summary' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNDgwMDk4NSwiZXhwIjoxNjM0ODg3Mzg1fQ.To-pTQHIxevyOuVnj9Ef3HhtZXVeZSYmHM5_qr8kDOE'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>

		<!-- Create Disbursement -->
		<div class="col-md-8 ps-0">	
			<p class="text-h5">Create Disbursement</p>
			<p class="font-weight-bold">
				API ini digunakan untuk membuat transaksi pembayaran individual / personal / satuan. 
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabCreate" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Content Type
							</th>
							<th class="text-left text-white">
								Client Id
							</th>
							<th class="text-left text-white">
								Api Key
							</th>
							<th class="text-left text-white">
								X-access-token
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in headCr" :key="item.key">
							<td>{{ item.type }}</td>
							<td>{{ item.id }}</td>
							<td>{{ item.api }}</td>
							<td>{{ item.token }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in bodyCreate" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h6">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/etransfer/disbursement/create' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjJkNzA1MjMwLWY3OGMtMTFlYi05NTc0LTAzOTZmNDg3ZTQwZSIsImJ1c2luZXNzX2lkIjoiMzIwNGNmMzAtMjFhMS0xMWVjLTllZjEtYzFmZWRlNzU5NTVlIiwicm9sZXMiOltdLCJpYXQiOjE2MzI5NzM2NzYsImV4cCI6MTYzNDcwMTY3Nn0.N5gIn2o0M9derS3PZ6paWFJbuIfZFvqiyOfDplmJ8-M' \
				--data-raw '{<br>
						"receipient_bank_id": "02fb1098-c2ed-44a6-823d-767f0f9729af",<br>
						"receipient_account_name":"fdsfds",<br>
						"receipient_account_number":"rewrwrw",<br>
						"amount":10000,<br>
						"receipient_email":"ffsfsd",<br>
						"remark":"remark",<br>
						"description":"description",<br>
						"recurring":true<br>
				}'<br>
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>

		<!-- Edit Disbursement -->
		<div class="col-md-8 ps-0">	
			<p class="text-h5">Edit Disbursement</p>
			<p class="font-weight-bold">
				API ini digunakan untuk mengubah transaksi pembayaran individual / personal / satuan
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabEdit" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Content Type
							</th>
							<th class="text-left text-white">
								Client Id
							</th>
							<th class="text-left text-white">
								Api Key
							</th>
							<th class="text-left text-white">
								X-access-token
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in headCr" :key="item.key">
							<td>{{ item.type }}</td>
							<td>{{ item.id }}</td>
							<td>{{ item.api }}</td>
							<td>{{ item.token }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in bodyEdit" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h6">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/etransfer/disbursement/edit' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjJkNzA1MjMwLWY3OGMtMTFlYi05NTc0LTAzOTZmNDg3ZTQwZSIsImJ1c2luZXNzX2lkIjoiMzIwNGNmMzAtMjFhMS0xMWVjLTllZjEtYzFmZWRlNzU5NTVlIiwicm9sZXMiOltdLCJpYXQiOjE2MzI5NzM2NzYsImV4cCI6MTYzNDcwMTY3Nn0.N5gIn2o0M9derS3PZ6paWFJbuIfZFvqiyOfDplmJ8-M' \
				--data-raw '{<br>
						"id": "e50187a6-0981-4473-93e5-902eea5f6197",<br>
						"receipient_bank_id": "02fb1098-c2ed-44a6-823d-767f0f9729af",<br>
						"receipient_account_name": "budi s",<br>
						"receipient_account_number": "12378123",<br>
						"amount": 2000,<br>
						"receipient_email": "budi@gmail.com",<br>
						"remark": "remark",<br>
						"description": "description",<br>
						"recurring": false<br>
				}'<br>
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>

		<!-- List Disbursement -->
		<div class="col-md-8 ps-0">	
			<p class="text-h5">List Disbursement</p>
			<p class="font-weight-bold">
				API ini digunakan untuk menampilkan daftar pembayaran satuan.
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabList" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Content Type
							</th>
							<th class="text-left text-white">
								Client Id
							</th>
							<th class="text-left text-white">
								Api Key
							</th>
							<th class="text-left text-white">
								X-access-token
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in headCr" :key="item.key">
							<td>{{ item.type }}</td>
							<td>{{ item.id }}</td>
							<td>{{ item.api }}</td>
							<td>{{ item.token }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>		
		<div class="col-md-8 ps-0">			
			<p class="text-h6">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>ccurl --location --request GET 'https://api-v1.momofin.com/v1/etransfer/disbursement/list' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNDE3NzkzMCwiZXhwIjoxNjM0MjY0MzMwfQ.dOX8pN8ijceYIB_Xjdm5WDTpruGDwaHWrxtA5aOa7Lk'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>
	</v-card>
</template>

<script>
export default {
   data() {
    return {
			tabDetail: 
			[
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/disbursement/detail/73c2047b-5291-47f2-8378-70c3756926bd',
        },
      ],
      tabSummary: 
			[
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/disbursement/summary',
        },
      ],
      tabCreate: 
			[
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/disbursement/create',
        },
      ],
      bodyCreate: 
			[
        {
          field: 'receipient_bank_id',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'receipient_account_name',
          type: 'String',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'receipient_account_number',
          type: 'String',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'amount',
          type: 'Integer',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'receipient_email',
          type: 'date',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'remark',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        }, 
        {
          field: 'description',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        }, 
        {
          field: 'recurring',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },        
      ],
      headCr:
      [
				{
					type:'',
					id:'',
					api:'',
					token:'Token JWT yang didapatkan setelah login'
				}
      ],
      tabList: 
			[
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/disbursement/list',
        },
      ],
      tabEdit: 
			[
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/disbursement/edit',
        },
      ],
      bodyEdit: 
			[
        {
          field: 'id',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'receipient_bank_id',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'receipient_account_name',
          type: 'String',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'receipient_account_number',
          type: 'String',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'amount',
          type: 'Integer',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'receipient_email',
          type: 'date',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'remark',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        }, 
        {
          field: 'description',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        }, 
        {
          field: 'recurring',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },        
      ],
    };
  },
};
</script>



