export default [
  { title: "eSignature concepts", icon: "", link: "/docs/concepts" },
  { title: "Envelopes", icon: "", link: "/docs/concepts/envelopes/" },
  { title: "Searching for envelopes", icon: "", link: "/docs/concepts/envelopes/search" },
  { title: "Envelope locks", icon: "", link: "/docs/concepts/envelopes/lock" },
  { title: "Bulk sending envelopes", icon: "", link: "/docs/concepts/envelopes/bulk-sending" },
  { title: "Folders", icon: "", link: "/docs/concepts/envelopes/folders" },
  { title: "Templates", icon: "", link: "/docs/concepts/templates" },
  { title: "Creating and using templates", icon: "", link: "/docs/concepts/templates/creating" },
  { title: "Sharing templates", icon: "", link: "/docs/concepts/templates/sharing" },
  { title: "Composite templates", icon: "", link: "/docs/concepts/templates/composite" },

  { title: "Tabs", icon: "", link: "/docs/concepts/tabs" },
  { title: "Auto-place (anchor tagging)", icon: "", link: "/docs/concepts/tabs/auto-place" },
  { title: "Fixed positioning", icon: "", link: "/docs/concepts/tabs/fixed" },
  { title: "PDF from field transformation", icon: "", link: "/docs/concepts/tabs/pdf-transform" },
  { title: "Data replication", icon: "", link: "/docs/concepts/tabs/data-replication" },
  { title: "Calculated fields", icon: "", link: "/docs/concepts/tabs/calculated-fields" },
  { title: "Conditional fields", icon: "", link: "/docs/concepts/tabs/conditional-fields" },
  { title: "Custom tabs", icon: "", link: "/docs/concepts/tabs/custom-tabs" },
  { title: "Requesting payment with tabs", icon: "", link: "/docs/concepts/tabs/payment" },
  { title: "Pre-filled tabs", icon: "", link: "/docs/concepts/tabs/prefilled-tabs" },

  { title: "Documents", icon: "", link: "/docs/concepts/documents" },
  { title: "Purging documents", icon: "", link: "/docs/concepts/documents/purging" },
  { title: "Authoritative copies", icon: "", link: "/docs/concepts/documents/authoritative-copies" },
  { title: "Attachments", icon: "", link: "/docs/concepts/documents/attachments" },
  { title: "Supplemental documents", icon: "", link: "/docs/concepts/documents/supplemental" },

  { title: "Recipients", icon: "", link: "/docs/concepts/recipients" },
  { title: "Recipient authentication", icon: "", link: "/docs/concepts/recipients/auth" },
  { title: "Pause and unpause a signature workflow", icon: "", link: "/docs/concepts/recipients/pause-unpause" },
  { title: "Specify conditional recipients", icon: "", link: "/docs/concepts/recipients/conditional-recipients" },
  { title: "Embedded signing and sending", icon: "", link: "/docs/concepts/embedding/" },
  { title: "Branding", icon: "", link: "/docs/concepts/branding" },
  { title: "Permissions", icon: "", link: "/docs/concepts/permissions" },
  { title: "Standards-based signatures", icon: "", link: "/docs/concepts/standards-based-signatures" },
  { title: "Responsive signing", icon: "", link: "/docs/concepts/responsive/" },
  { title: "Responsive signing request parameters", icon: "", link: "/docs/concepts/responsive/params/" },
  { title: "Setting tabs in HTML documents", icon: "", link: "/docs/concepts/responsive/setting-tabs/" },
  { title: "SMS delivery", icon: "", link: "/docs/concepts/sms-delivery/" },
  { title: "Using SMS delivery with the eSignature REST API", icon: "", link: "/docs/concepts/sms-delivery/using-sms-esignature/" },
  { title: "SMS delivery notes and limitations", icon: "", link: "/docs/concepts/sms-delivery/sms-delivery-limitations" },
];
