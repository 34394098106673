<template>
    <v-card rounded="lg" class="bg-white py-4 px-5">
        <vue-markdown :source="requestSignatureEmbed"> </vue-markdown>
    </v-card>
</template>

<script>
import requestSignatureEmbed from "./request-signature-embed.md";
import prism from "prismjs"

export default {
    mounted() {
    prism.highlightAll()
  },
    setup() {
        return {
            requestSignatureEmbed,
        };
    },
};
</script>
