<template>
  <v-card rounded="lg" class="bg-white py-4">
		<p class="text-h5">Send Dokumen</p>
		<p class="font-weight-bold">
			API ini digunakan untuk mengirimkan dokumen.
		</p>
		<!-- <p class="font-weight-bold">
			waiting ..
		</p> -->
		<div class="col-md-8 ps-0">			
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tab" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in body" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/econtract/signing/upload_documents' \
--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjhlMmQ2NjkwLTM2MDEtMTFlYy04MjNkLTMxYzBhNWMxYWI3YyIsImJ1c2luZXNzX2lkIjoiOGUyZDY2OTEtMzYwMS0xMWVjLTgyM2QtMzFjMGE1YzFhYjdjIiwicm9sZXMiOltdLCJpYXQiOjE2MzkyNDAwNTAsImV4cCI6MTY0MDk2ODA1MH0.N6Pt5LbvK9fhQKqClTdmaXFH9cLAyG8m6Ffwkal3JHI' \
--data-raw '{<br>
    "document_id": 2,<br>
    "signer": [<br>
        {<br>
            "signers": {<br>
                "emet": false,<br>
                "isVisualSign": "YES",<br>
                "llx": 51,<br>
                "lly": 261,<br>
                "urx": 151,<br>
                "ury": 261,<br>
                "name": "hanan",<br>
                "email": "kusuma.hanan135@gmail.com",<br>
                "userid": "3b307120-5bc8-11ec-93c6-79c1e7921fcb",<br>
                "certificateLevel": "NOT_CERTIFIED",<br>
                "varLocation": "Yogyakarta",<br>
                "varReason": "Signed"<br>
            },<br>
            "page": 1<br>
        },<br>
        {<br>
            "signers": {<br>
                "emet": false,<br>
                "isVisualSign": "YES",<br>
                "llx": 207,<br>
                "lly": 261,<br>
                "urx": 307,<br>
                "ury": 261,<br>
                "name": "marulli",<br>
                "email": "rulliwij99@gmail.com",<br>
                "userid": "3b307120-5bc8-11ec-93c6-79c1e7921fcb",<br>
                "certificateLevel": "NOT_CERTIFIED",<br>
                "varLocation": "Yogyakarta",<br>
                "varReason": "Signed"<br>
            },<br>
            "page": 1<br>
        },<br>
        {<br>
            "signers": {<br>
                "emet": false,<br>
                "isVisualSign": "YES",<br>
                "llx": 139,<br>
                "lly": 369,<br>
                "urx": 239,<br>
                "ury": 369,<br>
                "name": "eringga",<br>
                "email": "team@momofin.com",<br>
                "userid": "3b307120-5bc8-11ec-93c6-79c1e7921fcb",<br>
                "certificateLevel": "NOT_CERTIFIED",<br>
                "varLocation": "Yogyakarta",<br>
                "varReason": "Signed"<br>
            },<br>
            "page": 1<br>
        }<br>
    ]<br>
}'<br>
</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>
	</v-card>
</template>

<script>
export default {
   data() {
    return {
			tab: 
			[
        {
					req: 'Input Format',
					post: 'application/json',
        },
        {
					req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/econtract/signing/upload_documents',
        },
      ],
      head: 
			[
        {
          key: '',
          val: ' ',
          mandatory: '',
          length: ' ',
          desc: 'Token JWT yang didapatkan setelah login',
        },
      ],
      body: 
			[
        {
          field: 'document_id',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'emet',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'isVisualSign',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'llx',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'lly',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'urx',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'ury',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'name',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'email',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'userid',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'certificateLevel',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'varLocation',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'varReason',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
      ],
    };
  },
};
</script>



