<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">Inventory</p>
    <!-- <p class="font-weight-bold">
      API ini digunakan untuk menambahkan nama - nama channel doc yang digunakan. 
    </p> -->
    <inv />
    <order />
	</v-card>
</template>

<script>
import order from './content-contract/order.vue';
import inv from './content-contract/inventory.vue';

export default {
	components: {
    order,
    inv
   },
   data() {
    return {
    };
  },
};
</script>



