<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
    <p class="text-uppercase text-h4">penempatan tanda tangan</p>
    <p class="font-weight-bold">
      Informasi Umum
    </p>
    <p class="font-weight-bold">
      waiting ..
    </p>
    <div class="col-md-8 ps-0">
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                POST
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tab" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Key
              </th>
              <th class="text-left text-white">
                Value
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Deskripsi
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in head" :key="item.key">
              <td>{{ item.key }}</td>
              <td>{{ item.val }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.desc }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="font-weight-bold mb-1">Body Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Field
              </th>
              <th class="text-left text-white">
                Data Type
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Informasi Umum
              </th>
              <th class="text-left text-white">
                Contoh
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in body" :key="item.key">
              <td>{{ item.field }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.info }}</td>
              <td>{{ item.ex }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="text-h5">Request & Response Payload Sample</p>
      <p class="font-weight-bold mb-1">Request</p>
      <v-card class="pa-2" outlined>
        <p>
          <vue-code-highlight lang="curl">
            <pre>
curl --location --request POST 'https://api-v1.momofin.com/v1/econtract/document/upload_edited_documents' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjNiMzA3MTIwLTViYzgtMTFlYy05M2M2LTc5YzFlNzkyMWZjYiIsImJ1c2luZXNzX2lkIjoiM2IzMDcxMjEtNWJjOC0xMWVjLTkzYzYtNzljMWU3OTIxZmNiIiwicm9sZXMiOltdLCJpYXQiOjE2NDIyNTg1NDIsImV4cCI6MTY0MzEyMjU0Mn0.1AsvqVFEfcgkZ7j8U-rLe6hDz93px4sOqXDnRTFmHRE' \
				--form 'filename="Kontrak"' \
				--form 'document_id="138"' \
				--form 'signer="[{\"signers\":{\"emet\":false,\"llx\":51,\"lly\":261,\"urx\":151,\"ury\":261,\"name\":\"hanan\",\"email\":\"kusuma.hanan135@gmail.com\"},\"page\":1},{\"signers\":{\"emet\":false,\"llx\":207,\"lly\":261,\"urx\":307,\"ury\":261,\"name\":\"Hanan\",\"email\":\"kusuma.hanan135@gmail.com\"},\"page\":2},{\"signers\":{\"emet\":false,\"llx\":139,\"lly\":369,\"urx\":239,\"ury\":369,\"name\":\"hanan\",\"email\":\"kusuma.hanan135@gmail.com\"},\"page\":2}]"' \
				--form 'files=@"/home/haku/myCodes/node_projects/momofin/econtract/public/upload/edited.pdf-edited-1641556892253.pdf"'
				</pre>
          </vue-code-highlight>
        </p>
      </v-card>
      <p class="font-weight-bold mb-1">Normal Response</p>
      <v-card class="pa-2" outlined>
        <p>
          <vue-code-highlight lang="json"
            ><pre>
						{
							"code": 201,
							"success": true,
							"message": "Berhasil mengirimkan dokumen.",
							"response": {
								"data": {
									"document_id": "138",
									"signer": "[{\"signers\":{\"emet\":false,\"llx\":51,\"lly\":261,\"urx\":151,\"ury\":261,\"name\":\"hanan\",\"email\":\"kusuma.hanan135@gmail.com\"},\"page\":1},{\"signers\":{\"emet\":false,\"llx\":207,\"lly\":261,\"urx\":307,\"ury\":261,\"name\":\"Hanan\",\"email\":\"kusuma.hanan135@gmail.com\"},\"page\":2},{\"signers\":{\"emet\":false,\"llx\":139,\"lly\":369,\"urx\":239,\"ury\":369,\"name\":\"hanan\",\"email\":\"kusuma.hanan135@gmail.com\"},\"page\":2}]"
								}
							}
						}</pre
            ></vue-code-highlight
          >
        </p>
      </v-card>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      tab: [
        {
          req: "Input Format",
          post: "multipart/form-data"
        },
        {
          req: "Output Format",
          post: "application/json"
        },
        {
          req: "Endpoint",
          post: "v1/econtract/document/upload_edited_documents"
        }
      ],
      head: [
        {
          key: "",
          val: " ",
          mandatory: "",
          length: " ",
          desc: "Token JWT yang didapatkan setelah login"
        },
        {
          key: "",
          val: " ",
          mandatory: "",
          length: " ",
          desc: ""
        }
      ],
      body: [
        {
          field: "filename",
          type: "String",
          mandatory: "Ya",
          length: "",
          info: "",
          ex: ""
        },
        {
          field: "document_id",
          type: "String",
          mandatory: "Ya",
          length: "",
          info: "",
          ex: ""
        },
        {
          field: "files",
          type: "file/pdf",
          mandatory: "Ya",
          length: "",
          info: "",
          ex: ""
        },
        {
          field: "signer",
          type: "array",
          mandatory: "Ya",
          length: "",
          info: "",
          ex: ""
        }
      ],
      resp: [
        {
          field: "",
          type: "",
          mandatory: "",
          length: "",
          info: "",
          ex: ""
        },
        {
          field: "",
          type: "",
          mandatory: "",
          length: "",
          info: "",
          ex: ""
        }
      ]
    };
  }
};
</script>
