<template>
  <v-card rounded="lg" class="bg-white py-4">
		<!-- <p class="font-weight-bold">
			waiting ..
		</p> -->
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Publish</p>
			<p class="font-weight-bold">
				API ini digunakan untuk menerbitkan sertifikat yang sudah dibuat. 
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tab" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in bodyReq" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl">curl --location --request POST 'https://api-v1.momofin.com/v1/certificate/publish' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNTgyNTgxNCwiZXhwIjoxNjM1OTEyMjE0fQ.h0gjyAh5aZWZHkPMTo2uYcJkpp1PlXgiD-h3jzsNhz4' \
				--data-raw '{
					"template_id": "293d89da-a463-4ae1-b946-d0764ee4788f",
					"expiration_date": "2021-11-30",
					"issuance_date": "2021-10-28 16:41:21"
				}'
				</vue-code-highlight>
				</p>
			</v-card>
		</div>

	</v-card>
</template>

<script>
export default {
   data() {
    return {
			tab: 
			[
        {
          req: 'Input Format',
          post: 'multipart/form-data',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/certificate/publish',
        },
      ],
      head: 
			[
        {
          key: '',
          val: ' ',
          mandatory: '',
          length: ' ',
          desc: 'Token JWT yang didapatkan setelah login',
        },
      ],
      bodyReq: 
			[
        {
          field: 'template_id',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:'',
        },
        {
          field: 'expiration_date',
          type: '',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:'',
        },
        {
          field: 'issuance_date',
          type: '',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:'',
        },
      ],
    };
  },
};
</script>



