<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">Single Disbursement</p>
    <p class="font-weight-bold">Api ini digunakan untuk melakukan pembayaran secara individual, dimana dengan cara menginputkan data penerima pembayaran secara lengkap dan proses pembayaran tersebut.</p>
		<single />
	</v-card>
</template>

<script>
import single from './content-disb/single.vue';

export default {
	components: {
    single,
   },
   data() {
    return {
    };
  },
};
</script>



