<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">top up</p>
		<!-- <p class="font-weight-bold">
			Informasi Umum
		</p>
		<p class="font-weight-bold">
			waiting ..
		</p> -->
		<div class="col-md-8 ps-0">	
			<p class="text-h5">Create Topup</p>		
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tab" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in body" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div> 
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/econtract/order/create' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImExZWFlMmMwLTYyMjUtMTFlYy04NGNlLWRiMjBlZDEwZmZlYiIsImJ1c2luZXNzX2lkIjoiYTFlYWUyYzEtNjIyNS0xMWVjLTg0Y2UtZGIyMGVkMTBmZmViIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTY0MTQ0MDYxMSwiZXhwIjoxNjQyMzA0NjExfQ.7JlR1lRMR6AQIfo6pZg1IQGYxXaqhdWOEO1bA9rEQXg' \
				--data-raw '{
						"product_id":"1",
						"qty":"1",
						"payment_channel":{
						"name":"virtual_bank_account",
						"attributes":{
						"id": "7acb445c-c72b-4a49-911f-b3124164d451",
						"name": "Bank Rakyat Indonesia",
						"code": "bri",
						"image": "https://res.cloudinary.com/dmm2j57v5/image/upload/v1635394327/banks/bank-bri_a25zrh.jpg"
						}
					}
				}</pre></vue-code-highlight>
				</p>
			</v-card>
			<p class="font-weight-bold mb-1">Normal Response</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p>
					<vue-code-highlight lang="json"><pre>
						{
							"code": 200,
							"success": true,
							"message": "Silahkan proses pembayaran Anda",
							"data": {
							"status": "waiting payment",
							"product": {
							"name": "e-Meterai"
							},
							"payment": {
							"invoice_code": "EC-000458964",
							"type": "virtual_bank_account",
							"expired_at": "2022-01-07T14:51:45+00:00",
							"amount": "10000.0",
							"instruction": {
							"bankShortCode": "BRI",
							"accountNo": "7777700000002077",
							"displayName": "IKN-Momofin"
							}
							}
							}
							}</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>

		<!-- detail order -->
		<div class="col-md-8 ps-0">	
			<p class="text-h5">Detail Order/Topup</p>		
			<p class="font-weight-bold">API ini digunakan untuk menampilkan detail pesanan / topup.</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabDetail" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>		
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in headDetail" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Params</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<tbody>						
						<tr>
							<td>order_id</td>
							<td>6fbe5ffc-1302-4eb2-b1c2-27362c34848a</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in bodyDetail" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div> 
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/econtract/order/detail?order_id=6fbe5ffc-1302-4eb2-b1c2-27362c34848a' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImExZWFlMmMwLTYyMjUtMTFlYy04NGNlLWRiMjBlZDEwZmZlYiIsImJ1c2luZXNzX2lkIjoiYTFlYWUyYzEtNjIyNS0xMWVjLTg0Y2UtZGIyMGVkMTBmZmViIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTY0MTQ0MDYxMSwiZXhwIjoxNjQyMzA0NjExfQ.7JlR1lRMR6AQIfo6pZg1IQGYxXaqhdWOEO1bA9rEQXg' \
				--data-raw '{
					"product_id":1,
					"qty":1
				}'</pre></vue-code-highlight>
				</p>
			</v-card>
			<p class="font-weight-bold mb-1">Normal Response</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p>
					<vue-code-highlight lang="json"><pre>
					{
						"code": 200,
						"success": false,
						"message": "",
						"data": {
							"id": "6fbe5ffc-1302-4eb2-b1c2-27362c34848a",
							"product_name": "e-Meterai",
							"invoice_code": "EC-000334991",
							"product_item": [
								{
									"product_qty": 1,
									"product": {
									"id": 1,
									"name": "e-Meterai",
									"unit": "EMET"
									}
								}
							],
							"product_qty": 1,
							"product_price": 10000,
							"product_price_discount": 0,
							"product_description": "",
							"total_price": 10000,
							"status": "pending",
							"order_datetime": "2022-01-06T10:46:00.000Z",
							"payment_datetime": null,
							"expired_at": null
						}
					}</pre></vue-code-highlight>
				</p>
			</v-card>
		</div> 

		<!-- payment channel -->
		<div class="col-md-8 ps-0">	
			<p class="text-h5">Payment Channel</p>		
			<p class="font-weight-bold">API yang digunakan untuk menampilkan channel pembayaran. </p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabPay" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>		
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in headDetail" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Params</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<tbody>						
						<tr>
							<td>order_id</td>
							<td>6fbe5ffc-1302-4eb2-b1c2-27362c34848a</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/econtract/order/payment_channel' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImExZWFlMmMwLTYyMjUtMTFlYy04NGNlLWRiMjBlZDEwZmZlYiIsImJ1c2luZXNzX2lkIjoiYTFlYWUyYzEtNjIyNS0xMWVjLTg0Y2UtZGIyMGVkMTBmZmViIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTY0MTQ0MDYxMSwiZXhwIjoxNjQyMzA0NjExfQ.7JlR1lRMR6AQIfo6pZg1IQGYxXaqhdWOEO1bA9rEQXg' \
				--data-raw ''
				</pre></vue-code-highlight>
				</p>
			</v-card>
			<p class="font-weight-bold mb-1">Normal Response</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p>
					<vue-code-highlight lang="json"><pre>{
  "code": 200,<br>
  "success": true,<br>
  "data": {<br>
    "fund": [<br>
      {<br>
        "name": "virtual_bank_account",<br>
        "label": "Virtual Account",<br>
        "enabled": true,<br>
        "data": [<br>
          {<br>
            "id": "7acb445c-c72b-4a49-911f-b3124164d451",<br>
            "name": "Bank Rakyat Indonesia",<br>
            "code": "bri",<br>
            "image": "https://res.cloudinary.com/dmm2j57v5/image/upload/v1635394327/banks/bank-bri_a25zrh.jpg"<br>
          },<br>
          {<br>
            "id": "7acb445c-c72b-4a49-911f-b37s3224d451",<br>
            "name": "Bank Mandiri",<br>
            "code": "mandiri",<br>
            "image": "https://res.cloudinary.com/dmm2j57v5/image/upload/v1635394327/banks/bank-mandiri_peoyad.jpg"<br>
          }<br>
        ]<br>
      }<br>
    ]<br>
  }<br>
}<br>
</pre></vue-code-highlight>
				</p>
			</v-card>
		</div> 


		<!-- payment Instructions -->
		<div class="col-md-8 ps-0">	
			<p class="text-h5">Payment Instructions</p>		
			<p class="font-weight-bold">API ini digunakan untuk menampilkan perintah pembayaran.</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabPi" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>		
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in headDetail" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/econtract/order/payment_channel?order_id=6fbe5ffc-1302-4eb2-b1c2-27362c34848a' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImExZWFlMmMwLTYyMjUtMTFlYy04NGNlLWRiMjBlZDEwZmZlYiIsImJ1c2luZXNzX2lkIjoiYTFlYWUyYzEtNjIyNS0xMWVjLTg0Y2UtZGIyMGVkMTBmZmViIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTY0MTQ0MDYxMSwiZXhwIjoxNjQyMzA0NjExfQ.7JlR1lRMR6AQIfo6pZg1IQGYxXaqhdWOEO1bA9rEQXg' \
				--data-raw ''
				</pre></vue-code-highlight>
				</p>
			</v-card>
			<p class="font-weight-bold mb-1">Normal Response</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p>
					<vue-code-highlight lang="json"><pre>{<br>
  "code": 200,<br>
  "success": true,<br>
  "data": {<br>
    "total_price": 10000,<br>
    "fund": [<br>
      {<br>
        "name": "virtual_bank_account",<br>
        "label": "Virtual Account",<br>
        "enabled": true,<br>
        "data": [<br>
          {<br>
            "id": "7acb445c-c72b-4a49-911f-b3124164d451",<br>
            "name": "Bank Rakyat Indonesia",<br>
            "code": "bri",<br>
            "image": "https://res.cloudinary.com/dmm2j57v5/image/upload/v1635394327/banks/bank-bri_a25zrh.jpg"<br>
          },<br>
          {<br>
            "id": "7acb445c-c72b-4a49-911f-b37s3224d451",<br>
            "name": "Bank Mandiri",<br>
            "code": "mandiri",<br>
            "image": "https://res.cloudinary.com/dmm2j57v5/image/upload/v1635394327/banks/bank-mandiri_peoyad.jpg"<br>
          }<br>
        ]<br>
      }<br>
    ]<br>
  }<br>
}<br>
</pre></vue-code-highlight>
				</p>
			</v-card>
		</div> 
	</v-card>
</template>

<script>
export default {
   data() {
    return {
			tab: 
			[
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/econtract/order/create',
        },
      ],
      head: 
			[
        {
          key: '',
          val: ' ',
          mandatory: '',
          length: ' ',
          desc: 'Token JWT yang didapatkan setelah Login',
        },
      ],
      body: 
			[
        {
          field: 'product_id',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'qty',
          type: '',
          mandatory: '',
          length: '',
          info: ' ',
          ex:''
        },
        {
          field: 'payment_channel',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'name',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'attributes',
          type: '',
          mandatory: '',
          length: '',
          info: ' ',
          ex:''
        },
        {
          field: 'id',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'name',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'code',
          type: '',
          mandatory: '',
          length: '',
          info: ' ',
          ex:''
        },
      ],

      tabDetail: 
			[
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/econtract/order/detail?order_id="ID ORder"',
        },
      ],
      headDetail: 
			[
        {
          key: '',
          val: ' ',
          mandatory: '',
          length: ' ',
          desc: 'Token JWT yang didapatkan setelah Login',
        },
      ],
      bodyDetail: 
			[
        {
          field: 'product_id',
          type: '',
          mandatory: 'Yes',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'qty',
          type: '',
          mandatory: 'Yes',
          length: '',
          info: '',
          ex:''
        },
      ],

      tabPay: 
			[
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/econtract/order/payment_channel?order_id=6fbe5ffc-1302-4eb2-b1c2-27362c34848a',
        },
      ],

      tabPi: 
			[
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/econtract/order/payment_instruction?order_id=6fbe5ffc-1302-4eb2-b1c2-27362c34848a',
        },
      ],
    };
  },
};
</script>



