<template>
  <v-card rounded="lg" class="bg-white py-4">
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Data Profile Header</p>
			<p class="font-weight-bold">
				API ini digunakan untuk menampilkan bagian atas / margin berupa data profile.
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabGet" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/econtract/homepage/data_profile' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjNiMzA3MTIwLTViYzgtMTFlYy05M2M2LTc5YzFlNzkyMWZjYiIsImJ1c2luZXNzX2lkIjoiM2IzMDcxMjEtNWJjOC0xMWVjLTkzYzYtNzljMWU3OTIxZmNiIiwicm9sZXMiOltdLCJpYXQiOjE2NDMxOTk1OTEsImV4cCI6MTY0NDA2MzU5MX0.uYzF2Cepff9KGM26xHbixGrkUiEm5ZIlHi4CgSGk86Q'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>

		<!-- balance -->
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Balance</p>
			<p class="font-weight-bold">
				API  ini digunakan untuk menampilkan deposit / saldo. 
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabBal" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/econtract/homepage/balance' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjNiMzA3MTIwLTViYzgtMTFlYy05M2M2LTc5YzFlNzkyMWZjYiIsImJ1c2luZXNzX2lkIjoiM2IzMDcxMjEtNWJjOC0xMWVjLTkzYzYtNzljMWU3OTIxZmNiIiwicm9sZXMiOltdLCJpYXQiOjE2NDMxOTk1OTEsImV4cCI6MTY0NDA2MzU5MX0.uYzF2Cepff9KGM26xHbixGrkUiEm5ZIlHi4CgSGk86Q'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>

		<!-- Summary Dokumen -->
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Summary Dokumen</p>
			<p class="font-weight-bold">
				API ini digunakan untuk menampilkan ringkasan dokumen. 
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabSum" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/econtract/homepage/summary_docs' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImExZWFlMmMwLTYyMjUtMTFlYy04NGNlLWRiMjBlZDEwZmZlYiIsImJ1c2luZXNzX2lkIjoiYTFlYWUyYzEtNjIyNS0xMWVjLTg0Y2UtZGIyMGVkMTBmZmViIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTY0MDgzMzQ2OSwiZXhwIjoxNjQxNjk3NDY5fQ.BE_mZWDONtLbvI9w7npWSTPxLjCLqrtMEKeY_WhopXA'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>

		<!-- History Dokumen -->
		<div class="col-md-8 ps-0">			
			<p class="text-h5">History Dokumen</p>
			<p class="font-weight-bold">
				API ini digunakan untuk menampilkan riwayat dokumen 
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabHis" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>ccurl --location --request GET 'https://api-v1.momofin.com/v1/econtract/homepage/history_docs' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImExZWFlMmMwLTYyMjUtMTFlYy04NGNlLWRiMjBlZDEwZmZlYiIsImJ1c2luZXNzX2lkIjoiYTFlYWUyYzEtNjIyNS0xMWVjLTg0Y2UtZGIyMGVkMTBmZmViIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTY0MDgzMzQ2OSwiZXhwIjoxNjQxNjk3NDY5fQ.BE_mZWDONtLbvI9w7npWSTPxLjCLqrtMEKeY_WhopXA'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>
	</v-card>
</template>

<script>
export default {
   data() {
    return {
			tabList: 
			[
        {
					req: 'Input Format',
					post: 'application/json',
        },
        {
					req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/econtract/contact',
        },
      ],
      head: 
			[
        {
          key: '',
          val: ' ',
          mandatory: '',
          length: ' ',
          desc: 'Token JWT yang didapatkan setelah login',
        },
      ],
      tabAdd: 
			[
        {
					req: 'Input Format',
					post: 'application/json',
        },
        {
					req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/econtract/contact/add',
        },
      ],
      bodyAdd: 
			[
        {
          field: 'name',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
				{
          field: 'email',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'phone_number',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
				{
          field: 'idcard_number',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'organization',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
				{
          field: 'division',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'position',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
      ],
      tabEdit: 
			[
        {
					req: 'Input Format',
					post: 'application/json',
        },
        {
					req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/econtract/contact/edit',
        },
      ],
      bodyEdit: 
			[
        {
          field: 'id',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'name',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
				{
          field: 'email',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'phone_number',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
				{
          field: 'idcard_number',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'organization',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
				{
          field: 'division',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'position',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
      ],
      tabDel: 
			[
        {
					req: 'Input Format',
					post: 'application/json',
        },
        {
					req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/econtract/homepage/data_profile',
        },
      ],
      bodyDel: 
			[
        {
          field: 'id',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
       ]
    };
  },
};
</script>



