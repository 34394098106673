<template>
  <v-card rounded="lg" class="bg-white py-4">
		<p class="text-capitalize text-h5">login</p>
		<p class="font-weight-bold">
			Informasi Umum
		</p>
		<p class="font-weight-bold">
			API ini digunakan untuk masuk ke dalam sistem Momofin. Proses Login ini dapat dilakukan sesuai dengan user yang didaftarkan sebelumnya dan nanti sifatnya bisa berhasil ataupun gagal. Jika proses login berhasil, maka akan user dapat melanjutkan untuk mengakses menu selanjutnya. Apabila gagal, hal tersebut bisa dikarenakan karena kesalahan dalam memasukkan data login (username / password).
		</p>
		<div class="col-md-8 ps-0">			
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tab" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<!-- <div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div> -->
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in body" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'localhost:3000/v1/auth/users/login' \
				--data-raw '{
				"email":"manghaku@gmail.com",
				"password":"abcde"
				}'</pre></vue-code-highlight>
				</p>
			</v-card>
			<p class="font-weight-bold mb-1">Normal Response</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p>
					<vue-code-highlight lang="json"><pre>
						{
							"code": 200,
							"success": true,
							"data": {
							"token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImQyYzM2ZDMwLTAxNWEtMTFlYy05ZWZjLThiZGFjNzM2ZjdmNCIsImlhdCI6MTYzMDM4NDM0MiwiZXhwIjoxNjMwOTg5MTQyfQ.TeUaXd-IkW5ChclJugqr6AjRhySNrkdjERFcO0wA8Z0",
							"refreshtoken": "16d3386a-c97a-47da-b31a-8b97d13a0aaf",
							"roles": []
							}
						}
					</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>
	</v-card>
</template>

<script>
export default {
   data() {
    return {
			tab: 
			[
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/auth/users/login',
        },
      ],
      head: 
			[
        {
          key: '',
          val: ' ',
          mandatory: '',
          length: ' ',
          desc: '',
        },
        {
          key: '',
          val: ' ',
          mandatory: '',
          length: ' ',
          desc: '',
        },
      ],
      body: 
			[
        {
          field: 'Email',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: 'Email User',
          ex:'abc@abc.com'
        },
        {
          field: 'Password',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: 'Password User dengan ketentuan minimal 8 karakter',
          ex:'********'
        },
      ],
      resp: 
			[
        {
          field: '',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: '',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
      ]
    };
  },
};
</script>



