<template>
  <v-card rounded="lg" class="bg-white py-4">
		<!-- <p class="font-weight-bold">
			waiting ..
		</p> -->
		<div class="col-md-8 ps-0">			
			<p class="text-h5">List Template</p>
			<p class="font-weight-bold">
				API ini digunakan untuk menampilkan list template / contoh sertifikat. 
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tab" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Params</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<tbody>
						<tr>
							<td>Page</td>
							<td>2</td>
						</tr>
						<tr>
							<td>Search</td>
							<td>Certificate Name</td>
						</tr>
						<tr>
							<td>Sort</td>
							<td>ASC</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl">ccurl --location --request GET 'https://api-v1.momofin.com/v1/certificate/template?search=Certificate%20Name' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNTkwNzQ1OSwiZXhwIjoxNjM1OTkzODU5fQ.hn59aq05DvyVX_dW7l1B6GwjXZdS1pSEEvPEkrpn1nk'
				</vue-code-highlight>
				</p>
			</v-card>
		</div>

		<div class="col-md-8 ps-0">			
			<p class="text-h5">Detail</p>
			<p class="font-weight-bold">
				API ini digunakan untuk menampilkan detail sertifikat. 
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabDetail" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl">curl --location --request GET 'https://api-v1.momofin.com/v1/certificate/template/4b1f2fe5-0d59-4239-b466-cf93a0b190f7' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNTkwNzQ1OSwiZXhwIjoxNjM1OTkzODU5fQ.hn59aq05DvyVX_dW7l1B6GwjXZdS1pSEEvPEkrpn1nk'
				</vue-code-highlight>
				</p>
			</v-card>
		</div>

		<div class="col-md-8 ps-0">			
			<p class="text-h5">Create Template</p>
			<p class="font-weight-bold">
				API ini digunakan untuk membuat template / format sertifikat baru. 
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabCreate" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in bodyCreate" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl">curl --location --request POST 'https://api-v1.momofin.com/v1/certificate/template/create' \
--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNTMyNzk5MiwiZXhwIjoxNjM1NDE0MzkyfQ.YLkVB-yEojgbUUUbnALuK5gf2tR6sF-b9epJ_hznr8E' \
--data-raw '{<br>
    "design_id": "05899ac5-b8de-4d9a-ae64-2f03d879d4e8",<br>
    "type": "Degree",<br>
    "title": "Certificate Name",<br>
    "name": "Certificate Name",<br>
    "description": "Certificate Name\n\n",<br>
    "fields": {<br>
        "authorizedSignature": [<br>
            {<br>
                "name": "Certificate Name",<br>
                "position": "Certificate Name"<br>
            }<br>
        ],<br>
        "degree.type": "Certificate Name",<br>
        "issuer.accreditationNumber": "Certificate Name",<br>
        "degree.major": "Certificate Name",<br>
        "degree.title": "Certificate Name",<br>
        "degree.accreditationNumber": "Certificate Name",<br>
        "issuance.place": "Certificate Name",<br>
        "issuanceDate": "Certificate Name",<br>
        "credentialSubject.title": "Certificate Name",<br>
        "credentialSubject.credentialNumber": "Certificate Name",<br>
        "credentialSubject.graduationDate": "Certificate Name",<br>
        "recipient.studentNumber": "Certificate NameCertificate Name",<br>
        "recipient.name": "Certificate Name",<br>
        "recipient.birthPlace": "Certificate Name",<br>
        "recipient.birthDate": "Certificate Name"<br>
    }<br>
}'<br>
</vue-code-highlight>
				</p>
			</v-card>
		</div>

		<!-- edit template -->
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Edit Template</p>
			<p class="font-weight-bold">
				API ini digunakan untuk mengubah template / format sertifikat yang sudah dibuat. 
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabEdit" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in bodyEdit" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl">curl --location --request POST 'https://api-v1.momofin.com/v1/certificate/template/edit' \
--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNTgzNDI2OSwiZXhwIjoxNjM1OTIwNjY5fQ.g4StVGwZtyNw_eY5CnF5xW0c3g6OX9Q-kmGD_vVfe_4' \
--data-raw '{<br>
    "id": "4b1f2fe5-0d59-4239-b466-cf93a0b190f7",<br>
    "title": "judul 1",<br>
    "description": "deskripsi",<br>
    "fields": {<br>
        "credentialSubject": {<br>
            "title": "judul coba",<br>
            "description": "deskripsi"<br>
        }<br>
    }<br>
}'<br>
</vue-code-highlight>
				</p>
			</v-card>
		</div>
	</v-card>
</template>

<script>
export default {
   data() {
    return {
			tab: 
			[
        {
          req: 'Input Format',
          post: 'multipart/form-data',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/certificate/template?search=Certificate Name',
        },
      ],
      tabDetail: 
			[
        {
          req: 'Input Format',
          post: 'multipart/form-data',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/certificate/template/4b1f2fe5-0d59-4239-b466-cf93a0b190f7',
        },
      ],
      head: 
			[
        {
          key: '',
          val: ' ',
          mandatory: '',
          length: ' ',
          desc: 'Token JWT yang didapatkan setelah login',
        },
      ],
      tabCreate: 
			[
        {
          req: 'Input Format',
          post: 'multipart/form-data',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/certificate/template/create',
        },
      ],
      bodyCreate: 
			[
        {
          field: 'design_id',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'type',
          type: 'String ',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:'',
        },
        {
          field: 'name',
          type: 'String ',
          mandatory: 'Ya ',
          length: '',
          info: '',
          ex:'',
        },
        {
          field: 'description',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'fields',
          type: 'String ',
          mandatory: 'Ya',
          length: '',
          info: 'Name:Certificate Name Position : Certificate Name',
          ex:'',
        },
        {
          field: 'degree.type',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:'',
        },
        {
          field: 'issuer.accreditationNumber',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:'',
        },
        {
          field: 'degree.major',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:'',
        },
        {
          field: 'degree.title',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:'',
        },
        {
          field: 'degree.accreditationNumber',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:'',
        },
        {
          field: 'issuance.place',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:'',
        },
        {
          field: 'issuance.Date',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:'',
        },
        {
          field: 'credentialSubject.title',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:'',
        },
        {
          field: 'credentialSubject.credentialNumber',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:'',
        },
        {
          field: 'credentialSubject.credentialDate',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:'',
        },
        {
          field: 'recipient.studentNumber',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:'',
        },
        {
          field: 'recipient.name',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:'',
        },
        {
          field: 'recipient.birthPlace',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:'',
        },
        {
          field: 'recipient.birthDate',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:'',
        },
      ],
      tabEdit: 
			[
        {
          req: 'Input Format',
          post: 'multipart/form-data',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/certificate/template/edit',
        },
      ],
      bodyEdit: 
			[
        {
          field: 'id',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'title',
          type: 'String ',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:'',
        },
        {
          field: 'description',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'fields',
          type: 'String ',
          mandatory: 'Ya',
          length: '',
          info: 'Name:Certificate Name Position : Certificate Name',
          ex:'',
        },
      ],
    };
  },
};
</script>



