<template>
    <v-card rounded="lg" class="bg-white py-4 px-5">
    <p class="text-uppercase text-h4">OVERVIEW</p>
    <p class="">
    Selamat datang di halaman Dokumentasi API. Dokumentasi API ini dikembangkan oleh tim MOMOFIN untuk memungkinan integrasi antara aplikasi anda dengan MOMOFIN. Anda dapat menemukan proses detail integrasi dengan mudah sesuai dengan step menu yang tersedia.</p>
    <p>
    Momofin merupakan layanan terintegrasi yang dapat menunjang seluruh kebutuhan pekerjaan anda. Beberapa produk - produk dalam Momofin yang dapat anda gunakan diantaranya adalah E-Transfer, E-Contract , E-Materai , E-Stamp, E-Signature, E-Certificate serta sistem berbasis mobile EMET App. 
    </p>
    <p>
      MOMOFIN menawarkan kemudahan kebutuhan administrasi anda dari proses pembayaran, kontrak dokumen, tanda tangan digital, materai digital serta proses pembuatan dan pengiriman sertifikat.
    </p>
    <div class="col-md-8 ps-0"> 
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead>
            <tr>
              <th colspan="3" class="text-center text-black">
                Informasi API
              </th>
            </tr>
            <tr style="background: #0a3564;">
              <th class="text-center text-white">
                Versi
              </th>
              <th class="text-center text-white">
                Tanggal Perubahan
              </th>
              <th class="text-center text-white">
                Deskripsi Perubahaan
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tab" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
              <td>{{ item.test }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>    
    </div>
    <div class="col-md-8 ps-0"> 
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead>
            <tr>
              <th colspan="3" class="text-center text-black">
                Catatan Revisi
              </th>
            </tr>
            <tr style="background: #0a3564;">
              <th class="text-center text-white">
                Versi Dokumen
              </th>
              <th class="text-center text-white">
                Tanggal Perubahan
              </th>
              <th class="text-center text-white">
                Deskripsi Perubahaan
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabs" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
              <td>{{ item.test }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>    
    </div>
      <p class="text-h5">Target Audience</p>
      <p>
        Target utama pembaca dokumen ini adalah implementor teknis. Sebagian konten juga dapat menjadi rujukan untuk pihak manajemen atau tim operasional lainnya. 
      </p>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
          tab: 
      [
        {
          req: ' ',
          post: ' ',
          test: ' ',
        },
      ],
      tabs: 
      [
        {
          req: '0.1',
          post: 'Februari 2022',
          test: '1. Dokumentasi awal belum ada perubahaan / pertama diluncurkan',
        },
      ],
        };
    },
};
</script>

<!-- <template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
    <vue-markdown :source='overview'>
    </vue-markdown>
  </v-card>
</template>

<script>
// import { onMounted } from '@vue/composition-api';
import VueMarkdown from 'vue-markdown'
import overview from "../docs/overview.md";
// import prism from 'prismjs';

export default {
  name: "dashboard",
  components: {
    VueMarkdown
  },
  setup() {
    return { overview } 
  }
};
</script>

<style>

</style> -->