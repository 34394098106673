<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
    <vue-markdown :source="template"> </vue-markdown>
  </v-card>
</template>

<script>
import template from "./composite-template.md";
import prism from "prismjs";

export default {
  mounted() {
    prism.highlightAll()
  },
  setup() {
    return {
      template,
    };
  },
};
</script>
