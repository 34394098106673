<template>
  <v-card rounded="lg" class="bg-white py-4">
		<!-- <p class="text-h4">e-KYC</p>
		<p class="font-weight-bold">
			Informasi Umum
		</p>
		<p class="font-weight-bold">
			waiting ...
		</p> -->
		<div class="col-md-8 ps-0">
			<p class="text-h5">KYC KTP</p>			
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tab" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Content Type
							</th>
							<th class="text-left text-white">
								Client Id
							</th>
							<th class="text-left text-white">
								Api Key
							</th>
							<th class="text-left text-white">
								X-access-token
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.type }}</td>
							<td>{{ item.id }}</td>
							<td>{{ item.api }}</td>
							<td>{{ item.token }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in body" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/auth/users/kyc/idcard' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjcyM2JlMzkwLTVjYmItMTFlYy1iYTY5LTMxNjljNWYzMmU1MCIsImJ1c2luZXNzX2lkIjoiNzIzYmUzOTEtNWNiYi0xMWVjLWJhNjktMzE2OWM1ZjMyZTUwIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzOTQ3MjQxNywiZXhwIjoxNjQwMzM2NDE3fQ.7eN5ZjgU3bo5wWDTP1ZFYDT6J_UUxzWUPPdP1SbeP1c' \
				--form 'ktp=@"/home/haku/Documents/mycodes/momofin/dev/user_management/public/images/ktp.jpg"'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>
		<div class="col-md-8 ps-0">
			<p class="text-h5">Check KYC</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabCek" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Content Type
							</th>
							<th class="text-left text-white">
								Client Id
							</th>
							<th class="text-left text-white">
								Api Key
							</th>
							<th class="text-left text-white">
								X-access-token
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.type }}</td>
							<td>{{ item.id }}</td>
							<td>{{ item.api }}</td>
							<td>{{ item.token }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in bodyCek" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/auth/users/kyc_status' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjNiMzA3MTIwLTViYzgtMTFlYy05M2M2LTc5YzFlNzkyMWZjYiIsImJ1c2luZXNzX2lkIjoiM2IzMDcxMjEtNWJjOC0xMWVjLTkzYzYtNzljMWU3OTIxZmNiIiwicm9sZXMiOltdLCJpYXQiOjE2NDMxOTk1OTEsImV4cCI6MTY0NDA2MzU5MX0.uYzF2Cepff9KGM26xHbixGrkUiEm5ZIlHi4CgSGk86Q' \
				--form 'ktp=@"/home/haku/Documents/mycodes/momofin/dev/user_management/public/images/ktp.jpg"'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>
		<!-- REQUEST & RESPONSE -->
		<!-- <div class="col-md-8 ps-0">
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Normal Response</p>
			<p>1. Kelengkapan Informasi Pribadi</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabNormal" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in bodyNormal" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Response Structure</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in resp" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div> -->
		<div class="col-md-8 ps-0">
			<p class="text-h5">KYC Video</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabUpload" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Content Type
							</th>
							<th class="text-left text-white">
								Client Id
							</th>
							<th class="text-left text-white">
								Api Key
							</th>
							<th class="text-left text-white">
								X-access-token
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.type }}</td>
							<td>{{ item.id }}</td>
							<td>{{ item.api }}</td>
							<td>{{ item.token }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in bodyUpload" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/auth/users/kyc/video' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyMGQ1MWYwLTVjNzItMTFlYy1hYTk1LWU1ZjI4MzhmOWI3MyIsImJ1c2luZXNzX2lkIjoiNjIwZDUxZjEtNWM3Mi0xMWVjLWFhOTUtZTVmMjgzOGY5YjczIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzOTQ4MTkzNCwiZXhwIjoxNjQwMzQ1OTM0fQ.u9tjO-GT3LTg_OoqRGgK68X2Gt664gPS0bHpEEPIowk' \
				--form 'video=@"/home/haku/Videos/video_2021-11-22_14-19-36.webm"'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>
		<div class="col-md-8 ps-0">
			<p class="text-h5">Update Avatar</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabProf" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Content Type
							</th>
							<th class="text-left text-white">
								Client Id
							</th>
							<th class="text-left text-white">
								Api Key
							</th>
							<th class="text-left text-white">
								X-access-token
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.type }}</td>
							<td>{{ item.id }}</td>
							<td>{{ item.api }}</td>
							<td>{{ item.token }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in bodyProf" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/auth/users/update_avatar' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyMGQ1MWYwLTVjNzItMTFlYy1hYTk1LWU1ZjI4MzhmOWI3MyIsImJ1c2luZXNzX2lkIjoiNjIwZDUxZjEtNWM3Mi0xMWVjLWFhOTUtZTVmMjgzOGY5YjczIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzOTQ4MTkzNCwiZXhwIjoxNjQwMzQ1OTM0fQ.u9tjO-GT3LTg_OoqRGgK68X2Gt664gPS0bHpEEPIowk' \
				--form 'avatar=@"OJJlsOgew/image_2021-12-30_17-09-38.png"'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>
	</v-card>
</template>

<script>
export default {
   data() {
    return {
			tab: [
        {
          req: 'Input Format',
          post: 'multipart/form-data',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/auth/users/kyc/idcard',
        },
      ],
      head:
      [
				{
					type:'',
					id:'',
					api:'',
					token:'Token JWT yang didapatkan setelah login'
				}
      ],
      body: 
			[
        {
          field: 'KTP',
          type: 'file',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        }
      ],
      resp: 
			[
        {
          field: '',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: '',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
      ],

      tabNormal: [
        {
          req: 'Input Format',
          post: 'multipart/form-data',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/auth/users/users/profile',
        },
      ],
      bodyNormal: 
			[
        {
          field: 'fullname',
          type: 'file/video',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'phone_number',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'birth_place',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'birth_date',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: 'Menggunakan format "DD/MM/YYYY"',
          ex:''
        },
        {
          field: 'id_card_number',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'gender',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: 'Value: “M” dan “F”',
          ex:''
        },
        {
          field: 'address',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'city',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'province',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'district',
          type: 'String',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'postal_code',
          type: 'String',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        }
      ],

      tabUpload: [
        {
          req: 'Input Format',
          post: 'multipart/form-data',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/auth/users/kyc/video',
        },
      ],
      bodyUpload: 
			[
        {
          field: 'video',
          type: 'file',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        }
      ],

      tabCek: [
        {
          req: 'Input Format',
          post: '-',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/auth/users/kyc_status',
        },
      ],
      bodyCek: 
			[
        {
          field: 'ktp',
          type: 'file',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        }
      ],
      tabProf: 
			[
        {
					req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api.momofin.com//v1/auth/users/update_avatar',
        },
      ],
      bodyProf: 
			[
        {
          field: 'avatar',
          type: 'file/foto',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        }
      ],
    };
  },
};
</script>



