<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">E-CERTIFICATE</p>
    <!-- <p class="font-weight-bold">
      API ini digunakan untuk menambahkan nama - nama channel doc yang digunakan. 
    </p> -->
    <certif />
	</v-card>
</template>

<script>
import certif from './content-certif/certif.vue';

export default {
	components: {
    certif,
   },
   data() {
    return {
    };
  },
};
</script>



