<template>
  <v-card rounded="lg" class="bg-white py-4">
		<!-- <p class="font-weight-bold">
			waiting ..
		</p> -->

		<!-- create order -->
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Create Order</p>
			<p class="font-weight-bold">
				API  ini digunakan untuk membuat sebuah pesanan / transaksi pesanan. 
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tab" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in body" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/econtract/inventory/client/order' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjcyM2JlMzkwLTVjYmItMTFlYy1iYTY5LTMxNjljNWYzMmU1MCIsImJ1c2luZXNzX2lkIjoiNzIzYmUzOTEtNWNiYi0xMWVjLWJhNjktMzE2OWM1ZjMyZTUwIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzOTYzNjk3NCwiZXhwIjoxNjQwNTAwOTc0fQ.vk89DZIj2HpBkBKMrkNl6D7eKHHr9ZNJJdfoVZ3KLEQ' \
				--data-raw '{
					"product_id":1,
					"qty":10
				}'
				</pre></vue-code-highlight>
				</p>
			</v-card>
			<p class="font-weight-bold mb-1">Response</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="json"><pre>{
  "code": 201,<br>
  "success": true,<br>
  "data": {<br>
    "id": "3a12d2a1-9dc9-4154-8d4c-a98782679c98",<br>
    "user_id": "723be390-5cbb-11ec-ba69-3169c5f32e50",<br>
    "client_id": "723be391-5cbb-11ec-ba69-3169c5f32e50",<br>
    "invoice_code": "EC-000548458",<br>
    "product_id": 1,<br>
    "product_name": "e-Meterai",<br>
    "product_item": [<br>
      {<br>
        "product_qty": 1,<br>
        "product": {<br>
          "name": "e-Meterai",<br>
          "unit": "EMET"<br>
        }<br>
      }<br>
    ],<br>
    "product_qty": 10,<br>
    "product_description": [],<br>
    "price": 11500,<br>
    "discount": 0,<br>
    "total_price": 115000,<br>
    "status": "pending",<br>
    "order_datetime": "2021-12-22T08:12:54.000Z"<br>
  }<br>
}<br>
</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>

		<!-- detail order -->
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Detail Order</p>
			<p class="font-weight-bold">
				API ini digunakan untuk menampilkan detail /rincian pesanan. 
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabDetail" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Params</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<tbody>
						<tr>
							<td>order_id</td>
							<td>3a12d2a1-9dc9-4154-8d4c-a98782679c98</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/econtract/inventory/client/order?order_id=3a12d2a1-9dc9-4154-8d4c-a98782679c98' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjcyM2JlMzkwLTVjYmItMTFlYy1iYTY5LTMxNjljNWYzMmU1MCIsImJ1c2luZXNzX2lkIjoiNzIzYmUzOTEtNWNiYi0xMWVjLWJhNjktMzE2OWM1ZjMyZTUwIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzOTYzNjk3NCwiZXhwIjoxNjQwNTAwOTc0fQ.vk89DZIj2HpBkBKMrkNl6D7eKHHr9ZNJJdfoVZ3KLEQ' \
				--data-raw ''
				</pre></vue-code-highlight>
				</p>
			</v-card>
			<p class="font-weight-bold mb-1">Response</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="json"><pre>{<br>
  "code": 200,<br>
  "success": false,<br>
  "message": "",<br>
  "data": {<br>
    "id": "3a12d2a1-9dc9-4154-8d4c-a98782679c98",<br>
    "user_id": "723be390-5cbb-11ec-ba69-3169c5f32e50",<br>
    "client_id": "723be391-5cbb-11ec-ba69-3169c5f32e50",<br>
    "invoice_code": "EC-000548458",<br>
    "product_id": 1,<br>
    "product_name": "e-Meterai",<br>
    "product_item": "[{\"product_qty\":1,\"product\":{\"name\":\"e-Meterai\",\"unit\":\"EMET\"}}]",<br>
    "product_qty": 10,<br>
    "product_description": "[]",<br>
    "price": 11500,<br>
    "discount": 0,<br>
    "unique_code": null,<br>
    "total_price": 115000,<br>
    "total_invoice": null,<br>
    "status": "pending",<br>
    "order_datetime": "2021-12-22 15:12:54",<br>
    "payment_channel": null,<br>
    "payment_channel_detail": null,<br>
    "payment_datetime": null,<br>
    "updated_datetime": null<br>
  }<br>
}<br>
</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>
		<!-- checkout order -->
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Checkout Order</p>
			<p class="font-weight-bold">
				API ini digunakan untuk memeriksa pesanan sebelum pesanan diproses.
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabCheckout" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in bodyCheckout" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/econtract/inventory/client/order/checkout' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjcyM2JlMzkwLTVjYmItMTFlYy1iYTY5LTMxNjljNWYzMmU1MCIsImJ1c2luZXNzX2lkIjoiNzIzYmUzOTEtNWNiYi0xMWVjLWJhNjktMzE2OWM1ZjMyZTUwIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzOTYzNjk3NCwiZXhwIjoxNjQwNTAwOTc0fQ.vk89DZIj2HpBkBKMrkNl6D7eKHHr9ZNJJdfoVZ3KLEQ' \
				--data-raw '{
						"order_id":"3a12d2a1-9dc9-4154-8d4c-a98782679c98",
						"product_id":1,
						"qty":5
				}'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>

		<!-- Cancel order -->
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Cancel Order</p>
			<p class="font-weight-bold">
				API ini digunakan untuk membatalkan pesanan. 
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabCancel" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in bodyCheckout" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/econtract/inventory/client/order/cancel' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjcyM2JlMzkwLTVjYmItMTFlYy1iYTY5LTMxNjljNWYzMmU1MCIsImJ1c2luZXNzX2lkIjoiNzIzYmUzOTEtNWNiYi0xMWVjLWJhNjktMzE2OWM1ZjMyZTUwIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzOTYzNjk3NCwiZXhwIjoxNjQwNTAwOTc0fQ.vk89DZIj2HpBkBKMrkNl6D7eKHHr9ZNJJdfoVZ3KLEQ' \
				--data-raw '{
						"order_id":"3a12d2a1-9dc9-4154-8d4c-a98782679c98",
						"product_id":1,
						"qty":5
				}'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>

		<!-- Get Payment Channel -->
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Get Payment Channel</p>
			<p class="font-weight-bold">
				API ini digunakan untuk menampilkan channel pembayaran / pilihan metode pembayaran.  
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabGet" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Params</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<tbody>
						<tr>
							<td>order_id</td>
							<td>d702f323-f625-42ec-a465-6bb5590a1732</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/econtract/inventory/client/payment_channel?order_id=d702f323-f625-42ec-a465-6bb5590a1732' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjcyM2JlMzkwLTVjYmItMTFlYy1iYTY5LTMxNjljNWYzMmU1MCIsImJ1c2luZXNzX2lkIjoiNzIzYmUzOTEtNWNiYi0xMWVjLWJhNjktMzE2OWM1ZjMyZTUwIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzOTYzNjk3NCwiZXhwIjoxNjQwNTAwOTc0fQ.vk89DZIj2HpBkBKMrkNl6D7eKHHr9ZNJJdfoVZ3KLEQ' \
				--data-raw ''
				</pre></vue-code-highlight>
				</p>
			</v-card>
			<p class="font-weight-bold mb-1">Response</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="json"><pre>{<br>
  "code": 200,<br>
  "success": true,<br>
  "data": {<br>
    "total_price": 115000,<br>
    "balance": {<br>
      "saldo": 0,<br>
      "enable": false<br>
    },<br>
    "fund": [<br>
      {<br>
        "name": "balance",<br>
        "label": "Use Balance",<br>
        "enabled": false<br>
      },<br>
      {<br>
        "name": "bank_transfer",<br>
        "label": "Bank Transfer",<br>
        "enabled": true,<br>
        "data": [<br>
          {<br>
            "id": "7acb445c-c72b-4a49-911f-b3124164d451",<br>
            "name": "Bank Rakyat Indonesia",<br>
            "code": "bri",<br>
            "image": "https://res.cloudinary.com/dmm2j57v5/image/upload/v1635394327/banks/bank-bri_a25zrh.jpg"<br>
          },<br>
          {<br>
            "id": "7acb445c-c72b-4a49-911f-b37s3224d451",<br>
            "name": "Bank Mandiri",<br>
            "code": "mandiri",<br>
            "image": "https://res.cloudinary.com/dmm2j57v5/image/upload/v1635394327/banks/bank-mandiri_peoyad.jpg"<br>
          }<br>
        ]<br>
      }<br>
    ],<br>
    "enabled": true<br>
  }<br>
}<br>
</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>
	</v-card>
</template>

<script>
export default {
   data() {
    return {
			tab: 
			[
        {
					req: 'Input Format',
					post: 'application/json',
        },
        {
					req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/econtract/inventory/client/order',
        },
      ],
      head: 
			[
        {
          key: '',
          val: ' ',
          mandatory: '',
          length: ' ',
          desc: 'Token JWT yang didapatkan setelah login',
        },
      ],
      body: 
			[
        {
          field: 'product_id',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
				{
          field: 'qty',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
      ],
      tabDetail: 
			[
        {
					req: 'Input Format',
					post: 'application/json',
        },
        {
					req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/econtract/inventory/client/order?order_id=3a12d2a1-9dc9-4154-8d4c-a98782679c98',
        },
      ],
      tabCheckout: 
			[
        {
					req: 'Input Format',
					post: 'application/json',
        },
        {
					req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/econtract/inventory/client/order/checkout',
        },
      ],
      bodyCheckout: 
			[
        {
          field: 'order_id',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'product_id',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
				{
          field: 'qty',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
      ],
      tabCancel: 
			[
        {
					req: 'Input Format',
					post: 'application/json',
        },
        {
					req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/econtract/inventory/client/order/cancel',
        },
      ],
      tabGet: 
			[
        {
					req: 'Input Format',
					post: 'application/json',
        },
        {
					req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/econtract/inventory/client/payment_channel?order_id=d702f323-f625-42ec-a465-6bb5590a1732',
        },
      ],
    };
  },
};
</script>



