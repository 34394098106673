<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">Homepage</p>
    <p class="font-weight-bold">
      API ini digunakan untuk menampilkan data yang berada pada homepage / halaman awal. 
    </p>
    <home />
	</v-card>
</template>

<script>
import home from './content-contract/home.vue';

export default {
	components: {
    home,
   },
   data() {
    return {
    };
  },
};
</script>



