<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
    <p class="text-uppercase text-h4">ACTIVATION OPTIONS</p>
    <!-- <p class="font-weight-bold">
			Informasi Umum
		</p> -->
    <!-- <p class="font-weight-bold">
			API ini digunakan untuk proses menambahkan, mengubah, menampilkan dan juga menghapus data permissions (izin/validasi roles)
		</p> -->
    <div class="col-md-8 ps-0">
      <p class="text-h5">Get Options</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tab" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="text-h6">Request & Response Payload Sample</p>
      <p class="font-weight-bold mb-1">Request</p>
      <v-card class="pa-2" outlined>
        <p>
          <vue-code-highlight lang="curl"
            >curl --location --request GET
            'https://api-v1.momofin.com/v1/auth/activation/options'
          </vue-code-highlight>
        </p>
      </v-card>
      <p class="font-weight-bold mb-1">Response</p>
      <v-card class="pa-2" outlined>
        <p>
          <vue-code-highlight lang="json"
            >{ "code": 201,<br />
            "success": true<br />
            "data": <br />
            "activation_option": <br />
            <br />
            "step": 1<br />
            "options": <br />
            "organization": <br />
            {<br />
            "_id": "901bdd30-0a1c-11ec-ae4a-877681a90a2f"<br />
            "name": "CV"<br />
            "created_at": "2021-08-31T05:30:33.475Z"<br />
            "__": <br />
            }<br />
            <br />
            "_id": "901b6800-0a1c-11ec-ae4a-877681a90a2f"<br />
            "name": "Perseorangan"<br />
            "created_at": "2021-08-31T05:30:33.472Z"<br />
            "__v": <br />
            }<br />
            <br />
            "_id": "901c0440-0a1c-11ec-ae4a-877681a90a2f"<br />
            "name": "PT"<br />
            "created_at": "2021-08-31T05:30:33.476Z"<br />
            "__v": <br />
            <br />
            ]<br />
            "organization_size": [<br />
            {<br />
            "_id": "901e0010-0a1c-11ec-ae4a-877681a90a2f",<br />
            "name": "medium",<br />
            "value": "11-100 karyawan",<br />
            "created_at": "2021-08-31T05:30:33.489Z",<br />
            "__v": 0<br />
            },<br />
            {<br />
            "_id": "901e0011-0a1c-11ec-ae4a-877681a90a2f",<br />
            "name": "big",<br />
            "value": "> 100 karyawan",<br />
            "created_at": "2021-08-31T05:30:33.489Z",<br />
            "__v": 0<br />
            },<br />
            {<br />
            "_id": "901dd900-0a1c-11ec-ae4a-877681a90a2f",<br />
            "name": "small",<br />
            "value": "0-10 karyawan",<br />
            "created_at": "2021-08-31T05:30:33.488Z",<br />
            "__v": 0 }<br />
            ],<br />
            "transaction_volume": [<br />
            {<br />
            "_id": "902022f0-0a1c-11ec-ae4a-877681a90a2f",<br />
            "name": "low",<br />
            "value": "0-10 transaksi per hari",<br />
            "created_at": "2021-08-31T05:30:33.503Z",<br />
            "__v": 0<br />
            },<br />
            {<br />
            "_id": "90204a00-0a1c-11ec-ae4a-877681a90a2f",<br />
            "name": "medium",<br />
            "value": "11-100 transaksi per hari",<br />
            "created_at": "2021-08-31T05:30:33.504Z",<br />
            "__v": 0<br />
            },<br />
            {<br />
            "_id": "90204a01-0a1c-11ec-ae4a-877681a90a2f",<br />
            "name": "high",<br />
            "value": "> 100 transaksi per hari",<br />
            "created_at": "2021-08-31T05:30:33.504Z",<br />
            "__v": 0 }<br />
            ],<br />
            "transaction_volume_avg": [<br />
            {<br />
            "_id": "90213460-0a1c-11ec-ae4a-877681a90a2f",<br />
            "name": "low",<br />
            "value": "0-10 transaksi per hari",<br />
            "created_at": "2021-08-31T05:30:33.510Z",<br />
            "__v": 0<br />
            },<br />
            {<br />
            "_id": "90215b70-0a1c-11ec-ae4a-877681a90a2f",<br />
            "name": "medium",<br />
            "value": "11-100 transaksi per hari",<br />
            "created_at": "2021-08-31T05:30:33.511Z",<br />
            "__v": 0<br />
            },<br />
            {<br />
            "_id": "90215b71-0a1c-11ec-ae4a-877681a90a2f",<br />
            "name": "high",<br />
            "value": "> 100 transaksi per hari",<br />
            "created_at": "2021-08-31T05:30:33.511Z",<br />
            "__v": 0<br />
            }<br />
            ]<br />
            }<br />
            },<br />
            {<br />
            "step": 2,<br />
            "options": {<br />
            "industry": [<br />
            {<br />
            "_id": "901f1181-0a1c-11ec-ae4a-877681a90a2f",<br />
            "name": "Logistik",<br />
            "created_at": "2021-08-31T05:30:33.496Z",<br />
            "__v": 0<br />
            },<br />
            {<br />
            "_id": "901eea70-0a1c-11ec-ae4a-877681a90a2f",<br />
            "name": "Fintech",<br />
            "created_at": "2021-08-31T05:30:33.495Z",<br />
            "__v": 0<br />
            },<br />
            {<br />
            "_id": "901f1180-0a1c-11ec-ae4a-877681a90a2f",<br />
            "name": "Manufaktur",<br />
            "created_at": "2021-08-31T05:30:33.496Z",<br />
            "__v": 0<br />
            },<br />
            {<br />
            "_id": "901f1182-0a1c-11ec-ae4a-877681a90a2f",<br />
            "name": "Pendidikan",<br />
            "created_at": "2021-08-31T05:30:33.496Z",<br />
            "__v": 0<br />
            },<br />
            {<br />
            "_id": "901f3890-0a1c-11ec-ae4a-877681a90a2f",<br />
            "name": "Manufaktur",<br />
            "created_at": "2021-08-31T05:30:33.497Z",<br />
            "__v": 0<br />
            }<br />
            ],<br />
            "bank": [<br />
            {<br />
            "id": "02fb1098-c2ed-44a6-823d-767f0f9729af",<br />
            "name": "BCA",<br />
            "code": "bca"<br />
            },<br />
            {<br />
            "id": "7acb445c-c72b-4a49-911f-b374664d451b",<br />
            "name": "BNI",<br />
            "code": "bni"<br />
            }<br />
            ]<br />
            }<br />
            },<br />
            {<br />
            "step": 3,<br />
            "options": {<br />
            "company_roles": [<br />
            {<br />
            "_id": "90221ec0-0a1c-11ec-ae4a-877681a90a2f",<br />
            "name": "Owner",<br />
            "created_at": "2021-08-31T05:30:33.517Z",<br />
            "__v": 0<br />
            },<br />
            {<br />
            "_id": "902245d0-0a1c-11ec-ae4a-877681a90a2f",<br />
            "name": "Director",<br />
            "created_at": "2021-08-31T05:30:33.517Z",<br />
            "__v": 0<br />
            },<br />
            {<br />
            "_id": "902245d1-0a1c-11ec-ae4a-877681a90a2f",<br />
            "name": "Manager",<br />
            "created_at": "2021-08-31T05:30:33.517Z",<br />
            "__v": 0<br />
            },<br />
            {<br />
            "_id": "902245d2-0a1c-11ec-ae4a-877681a90a2f",<br />
            "name": "Admin",<br />
            "created_at": "2021-08-31T05:30:33.517Z",<br />
            "__v": 0<br />
            }<br />
            ]<br />
            }<br />
            }<br />
            ]<br />
            }<br />
            }
          </vue-code-highlight>
        </p>
      </v-card>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      tab: [
        {
          req: "Input Format",
          post: "application/json"
        },
        {
          req: "Output Format",
          post: "application/json"
        },
        {
          req: "Endpoint",
          post: "https://api-v1.momofin.com/v1/auth/activation/options"
        }
      ]
    };
  }
};
</script>
