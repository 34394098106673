import axios from "axios";
import url from "./api";

// const access_key = process.env.VUE_APP_ACCESS_KEY;
const offline = false

const state = () => {
  return {
    user: null,
  };
};

const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  RESET_USER(state) {
    state.user = null;
  },
};

const getters = {
  user: (state) => state.user,
  isLoggedIn: (state) => !!state.user && !!state.user.token,
};

const actions = {
  async login({ commit }, user) {
    if (offline) {
        if (user.email == 'admin@gm.com' && user.password == 'admin') {
          const token = 'Basic ' + 'token'
          const user = user
          sessionStorage.setItem('token', token)
          axios.defaults.headers.common['x-access-token'] = token
          commit('SET_USER', { token: token, user: user })
        }
      } 
      else {
    return new Promise((resolve, reject) => {
    axios
      .post(url.admin_login, user, {
        headers: { "Content-Type": "application/json" }        
      })
      .then((res) => res.data)
      .then((res) => {
        if (res.code ===200) {          
          let user = res.data;
          let token = `Basic ${user.token}`;

          // console.log('data',user);        
          axios.defaults.headers.common["x-access-token"] = token;
          localStorage.setItem("momofin_auth", JSON.stringify(user));
          commit("SET_USER", user);
          resolve(res);
        }
        else{
          resolve(res);
        }
      })
      .catch((error_code) => {
          reject(error_code);
        });      
    });
  }
  },
  async logout({ commit }) {
    return new Promise((resolve) => {
      commit("RESET_USER");
      localStorage.removeItem("momofin_auth");
      delete axios.defaults.headers.common["x-access-token"];
      resolve("logged out");
    });
  },
  
  // async update_password({ commit }, data) {
  //   return new Promise((resolve, reject) => {
  //     axios.put(url.admin_update_password, data)
  //       .then(res => { resolve(res.data) })
  //       .catch(error => { reject(error) })
  //   })
  // },

  async get_user({ commit, dispatch }) {
    const user = JSON.parse(localStorage.getItem("momofin_auth"));
    // console.log('user',user)

    if (user) {
      axios.defaults.headers.common["x-access-token"] = user.token;
      axios
        .get(url.admin_detail)
        .then(commit("SET_USER", user))
        .catch((error) => {
          dispatch("logout");
          console.error(error)
        });
    }
  },
};

export default { state, mutations, getters, actions };
