<template>
  <v-row align-content="center" class="white">
    <v-col class="py-0 mx-auto" md="12" style="min-height: 100vh;padding:0;overflow-y: hidden;">
      <v-row style="margin:0;">
        <v-col class="pa-0" md="6" style="height: 100vh;background: rgba(0, 104, 214, 1);">
          <div :style="{
          backgroundImage: `url('${require('@/assets/images/bg-login.png')}')`}" style="height: 100vh;background-size: cover;">            
            <v-spacer class="py-16"></v-spacer>          
            <v-card class="transparent px-10">          
              <img src="@/assets/images/logo.png" class="mb-3">
              <p class="text-white font-weight-bold title-bg-login py-8 px-2">Solution for your digital transactions mobility.</p>
              <div class="text-center d-lg-none d-md-none d-sm-block">
                <v-btn
                  rounded
                  color="white"
                  outlined
                  large
                >
                  Login                   
                </v-btn>
                <p class="py-3"><v-icon color="white">ri-arrow-down-circle-fill</v-icon></p>                               
              </div>
            </v-card>
          </div>
        </v-col>
        <v-col class="white" md="6" style="height: 100vh">
          <v-spacer class="py-16"></v-spacer>
          <v-form
            ref="form"
            class="px-8"
            @submit.prevent="submit"
            v-model="valid"
          >
            <div class="mx-auto text-left mb-3 text-black">
              <p class="font-weight-bold title-login">Sign in to your account</p>
              <!-- <img src="@/assets/images/logo-yec.png" class="me-2"> -->
            </div>
            <v-alert
                type="error"
                text
                dense
                prominent
                v-show="response !== null"
                >{{ response }}</v-alert
              >
            <v-row>
              <v-col>
                <div class="subtitle-2 mb-2 text-black mt-3">Email</div>
                <v-text-field
                  filled
                  v-model="email"
                  :rules="[...rules('Email', 'required'), validate]"
                  required
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <div class="mb-2">
                  <span class="subtitle-2 text-black">Password</span>                  
                </div>
                <v-text-field
                  filled
                  v-model="password"
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                  @click:append="show = !show"
                  outlined
                  hide-details="auto"
                  :rules="rules('Password', 'required')"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-checkbox
                  v-model="checkbox"
                  label="Remember Me"
                  class="mt-0"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-btn
                  large
                  elevation="0"
                  type="submit"
                  color="primary"
                  :loading="submiting"
                  block
                >
                  Log In
                </v-btn>
              </v-col>
              <v-col cols="12" class="text-center">
                <router-link
                  class="font-weight-medium text-decoration-none"
                  tabindex="-1"
                  color="primary"
                  :to="{ name: 'send-reset' }"
                >
                  Forgot Password?
                </router-link>
              </v-col>
            </v-row>
            <v-spacer class="py-8"></v-spacer>
            <v-row>
              <v-col cols="12" class="text-center">
                <span class="text-black">
                Don’t have an account?    
                <router-link
                  class="font-weight-medium text-decoration-none"
                  tabindex="-1"
                  color="primary"
                  :to="{ name: 'registration' }"
                >
                  Register
                </router-link>
                </span>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import validationMixin from "../_mixins/validation";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      valid: true,
      errors: null,
      email: "",
      password: "",
      checkbox: false,
      submiting: false,
      show:false,
      response:null,
      emailRules: [
        (v) => !!v || "Email tidak boleh kosong",
        v => /.+@.+\..+/.test(v) || 'Email tidak valid',
      ],
    };
  },
  computed: {
    validate() {
      return !this.errors || this.errors.message;
    },
  },
  methods: {
    
    submit() {
      this.errors = null;

      setTimeout(() => {
        if (this.$refs.form.validate()) {
          this.submiting = true;
          let email = this.email;
          let password = this.password;
  
          this.$store
            .dispatch("login", { email, password })
            .then((res) => {
              this.submiting = false;
              // console.log("res",res)
              if (res.code === 200) {              
                this.$store.dispatch('get_user');
                this.redirectAfterLogin();
                                    
              }
              else{
                this.response = res.msg;
                setTimeout( () => {                
                  this.response=null;
                }
                , 3000);
                // this.$refs.form.reset()
              }
            })
            .catch((error) => {
              this.submiting = false;
              // console.log(error.response)
              this.response = error.response.data.msg;
              setTimeout( () => {                
                  this.response=null;
                }
                , 3000);
            });
        } 
      }, 50);
    },
    redirectAfterLogin() {
     if (localStorage.getItem("momofin_redirect") != null) {
        this.$router.push(localStorage.getItem("momofin_redirect"));
        localStorage.removeItem("momofin_redirect");
      } else {
        this.$router.push("/dashboard");
      }
    },
  },
};
</script>

<style>
</style>