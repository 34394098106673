<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">Xfers Service</p>
    <!-- <p class="font-weight-bold">
      Api ini digunakan untuk melakukan proses penerimaan uang atau untuk memmpermudah orang lain melakukan pembayaran dengan cara klik tautan yang sudah didapatkan.
    </p> -->

    <!-- Test Update Status Payment -->
    <div class="col-md-8 ps-0"> 
      <p class="text-h5">Test Update Status Payment</p>
      <!-- <p class="font-weight-bold">
        API ini digunakan untuk menampilkan pilihan metode / jenis pembayaran.
      </p> -->
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabTu" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <!-- <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Content Type
              </th>
              <th class="text-left text-white">
                Client Id
              </th>
              <th class="text-left text-white">
                Api Key
              </th>
              <th class="text-left text-white">
                X-access-token
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in headCr" :key="item.key">
              <td>{{ item.type }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.api }}</td>
              <td>{{ item.token }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="text-h6">Request & Response Payload Sample</p>      
      <p class="font-weight-bold mb-1">Request</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/etransfer/service/payment_options'</pre></vue-code-highlight>
        </p>
      </v-card>
    </div> -->

    <!-- List Payment -->
    <div class="col-md-8 ps-0"> 
      <p class="text-h5">List Payment</p>
      <!-- <p class="font-weight-bold">
        API ini digunakan untuk menampilkan pilihan metode / jenis pembayaran.
      </p> -->
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabList" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <!-- <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Content Type
              </th>
              <th class="text-left text-white">
                Client Id
              </th>
              <th class="text-left text-white">
                Api Key
              </th>
              <th class="text-left text-white">
                X-access-token
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in headCr" :key="item.key">
              <td>{{ item.type }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.api }}</td>
              <td>{{ item.token }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="text-h6">Request & Response Payload Sample</p>      
      <p class="font-weight-bold mb-1">Request</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/etransfer/service/payment_options'</pre></vue-code-highlight>
        </p>
      </v-card>
    </div> -->

    <!-- Xfers Payment Process -->
    <div class="col-md-8 ps-0"> 
      <p class="text-h5">Xfers Payment Process</p>
      <p class="font-weight-bold">
        API ini digunakan untuk proses pembayaran Xfers
      </p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabXf" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Body Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Field
              </th>
              <th class="text-left text-white">
                Data Type
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Informasi Umum
              </th>
              <th class="text-left text-white">
                Contoh
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in bodyXf" :key="item.key">
              <td>{{ item.field }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.info }}</td>
              <td>{{ item.ex }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="text-h6">Request & Response Payload Sample</p>      
      <p class="font-weight-bold mb-1">Request</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/etransfer/xfers/payment-process' \
        --data-raw '{<br>
            "invoice_code":"D-000376000",<br>
            "action":"settle"<br>
        }'<br>
        </pre></vue-code-highlight>
        </p>
      </v-card>
    </div>

    <!-- Xfers Disbursement Process -->
    <div class="col-md-8 ps-0"> 
      <p class="text-h5">Xfers Disbursement Process</p>
      <!-- <p class="font-weight-bold">
        API ini digunakan untuk menampilkan pilihan metode / jenis pembayaran.
      </p> -->
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabXfd" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <!-- <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Content Type
              </th>
              <th class="text-left text-white">
                Client Id
              </th>
              <th class="text-left text-white">
                Api Key
              </th>
              <th class="text-left text-white">
                X-access-token
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in headCr" :key="item.key">
              <td>{{ item.type }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.api }}</td>
              <td>{{ item.token }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="text-h6">Request & Response Payload Sample</p>      
      <p class="font-weight-bold mb-1">Request</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/etransfer/service/payment_options'</pre></vue-code-highlight>
        </p>
      </v-card>
    </div> -->
	</v-card>
</template>

<script>

export default {
	components: {
   },
   data() {
    return {
      tabTu: 
      [
        {
          req: 'Input Format',
          post: '',
        },
        {
          req: 'Output Format',
          post: '',
        },
        {
          req: 'Endpoint',
          post: '',
        },
      ],
      tabList: 
      [
        {
          req: 'Input Format',
          post: '',
        },
        {
          req: 'Output Format',
          post: '',
        },
        {
          req: 'Endpoint',
          post: '',
        },
      ],
      tabXf: 
      [
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/xfers/payment-process',
        },
      ],
      bodyXf: 
      [
        {
          field: 'invoice_code',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'action',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },  
      ],
      tabXfd: 
      [
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/transaction',
        },
      ]
    };
  },
};
</script>



