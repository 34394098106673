<template>
    <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">AUTENTIKASI</p>
		<p class="">
		MomofinAPI menggunakan Auth sebagai proses otorisasi dalam menentukan hak akses pengguna. Sebelum melakukan proses API request ke produk MOMOFIN, anda harus mendapatkan token dengan cara memanggil endpoint Get Token.</p>
		<p>
		Parameter dibawah ini disertakan dalam header request di setiap panggilan API.
		</p>
		<div class="col-md-8 ps-0">			
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<tbody>
						<tr>
							<td>Key</td>
							<td>Value</td>
						</tr>
						<tr>
							<td>Authorization</td>
							<td>Bearer {api_key}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
    </v-card>
</template>

<script>
export default {
    setup() {
        return {
        };
    },
};
</script>
