<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
    <p class="text-uppercase text-h4">ACCOUNT ACTIVATION</p>
    <!-- <p class="font-weight-bold">
			Informasi Umum
		</p> -->
    <p class="font-weight-bold">
      API ini digunakan untuk proses aktivasi akun corporate (perusahaan) secara
      detail.
    </p>
    <div class="col-md-8 ps-0">
      <p class="text-h5">STEP 1 (Business Info)</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                POST
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tab" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Key
              </th>
              <th class="text-left text-white">
                Value
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Deskripsi
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in head" :key="item.key">
              <td>{{ item.key }}</td>
              <td>{{ item.val }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.desc }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="font-weight-bold mb-1">Body Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Field
              </th>
              <th class="text-left text-white">
                Data Type
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Informasi Umum
              </th>
              <th class="text-left text-white">
                Contoh
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in body" :key="item.key">
              <td>{{ item.field }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.info }}</td>
              <td>{{ item.ex }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="text-h6">Request & Response Payload Sample</p>
      <p class="font-weight-bold mb-1">Request</p>
      <v-card class="pa-2" outlined>
        <p>
          <vue-code-highlight lang="json">
            <pre lang="curl">
curl --location --request POST 'https://api.momofin.com//activation/business' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjgyMzE0MjIwLTBhMTUtMTFlYy04ZmFiLTMxNjk0MWU2MjFjMyIsImlhdCI6MTYzMDQwNjQ3MiwiZXhwIjoxNjMwNDA3NjcyfQ.kmxKHZY1rO_NnV2rlGttCnFfxIOY7GRLfwB3Y9Y2HuQ' \
				--data-raw '{
						"business_type":"corporate",
						"organization_type":"901bdd30-0a1c-11ec-ae4a-877681a90a2f",
						"organization_size":"901e0010-0a1c-11ec-ae4a-877681a90a2f",
						"transaction_volume":"902022f0-0a1c-11ec-ae4a-877681a90a2f",
						"average_transaction_volume":"90213460-0a1c-11ec-ae4a-877681a90a2f"
				}'
				</pre
            ></vue-code-highlight
          >
        </p>
      </v-card>
      <p class="font-weight-bold mb-1">Response</p>
      <v-card class="pa-2" outlined>
        <p>
          <vue-code-highlight lang="json"
            ><pre>
{
						"code": 201,
						"success": true,
						"message": "Informasi Bisnis berhasil disimpan"
					}
        </pre
            >
          </vue-code-highlight>
        </p>
      </v-card>
    </div>

    <div class="col-md-8 ps-0">
      <p class="text-h5">STEP 2 (Business Info)</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                POST
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tab2" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Key
              </th>
              <th class="text-left text-white">
                Value
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Deskripsi
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in head" :key="item.key">
              <td>{{ item.key }}</td>
              <td>{{ item.val }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.desc }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="font-weight-bold mb-1">Body Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Field
              </th>
              <th class="text-left text-white">
                Data Type
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Informasi Umum
              </th>
              <th class="text-left text-white">
                Contoh
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in body2" :key="item.key">
              <td>{{ item.field }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.info }}</td>
              <td>{{ item.ex }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="text-h6">Request & Response Payload Sample</p>
      <p class="font-weight-bold mb-1">Request</p>
      <v-card class="pa-2" outlined>
        <p>
          <vue-code-highlight lang="curl">
            <pre>
            curl --location --request POST 'https://api.momofin.com//activation/business_detail' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjgyMzE0MjIwLTBhMTUtMTFlYy04ZmFiLTMxNjk0MWU2MjFjMyIsImlhdCI6MTYzMDQwODAzMSwiZXhwIjoxNjMwNDA5MjMxfQ.CmipRzYQRbkF_5y4oHE31Q7vP7sgD0l4fYRReL2lh-8' \
				--data-raw '{
						"name":"corporate",
						"industry_type":"901f1181-0a1c-11ec-ae4a-877681a90a2f",
						"bank_id":"62cfd630-fe0d-11eb-ba7f-2f98623dfae2",
						"account_name":"62d81390-fe0d-11eb-ba7f-2f98623dfae2",
						"account_number":"de311f60-0177-11ec-ad43-619fedd469fe"
				}'
          </pre
            >
          </vue-code-highlight>
        </p>
      </v-card>
      <p class="font-weight-bold mb-1">Response</p>
      <v-card class="pa-2" outlined>
        <p>
          <vue-code-highlight lang="json"
            ><pre>
{
						"code": 201,
						"success": true,
						"message": "Informasi Bisnis berhasil disimpan"
					}
					</pre
            ></vue-code-highlight
          >
        </p>
      </v-card>
    </div>

    <div class="col-md-8 ps-0">
      <p class="text-h5">STEP 3 (Personal Info)</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                POST
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tab3" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Key
              </th>
              <th class="text-left text-white">
                Value
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Deskripsi
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in head" :key="item.key">
              <td>{{ item.key }}</td>
              <td>{{ item.val }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.desc }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="font-weight-bold mb-1">Body Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Field
              </th>
              <th class="text-left text-white">
                Data Type
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Informasi Umum
              </th>
              <th class="text-left text-white">
                Contoh
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in body3" :key="item.key">
              <td>{{ item.field }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.info }}</td>
              <td>{{ item.ex }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="text-h6">Request & Response Payload Sample</p>
      <p class="font-weight-bold mb-1">Request</p>
      <v-card class="pa-2" outlined>
        <p>
          <vue-code-highlight lang="curl">
            <pre>
            curl --location --request POST 'https://api.momofin.com//activation/personal' \
          --header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjgyMzE0MjIwLTBhMTUtMTFlYy04ZmFiLTMxNjk0MWU2MjFjMyIsImlhdCI6MTYzMDQwODAzMSwiZXhwIjoxNjMwNDA5MjMxfQ.CmipRzYQRbkF_5y4oHE31Q7vP7sgD0l4fYRReL2lh-8' \
          --data-raw '{
              "fullname":"Hanan Kusuma",
              "email":"kusuma",
              "birth_date":"2020-01-01"
          }'
          </pre
            >
          </vue-code-highlight>
        </p>
      </v-card>
      <p class="font-weight-bold mb-1">Response</p>
      <v-card class="pa-2" outlined>
        <p>
          <vue-code-highlight lang="json"
            ><pre>
{
						"code": 201,
						"success": true,
						"message": "Informasi Personal berhasil disimpan"
					}
					</pre
            ></vue-code-highlight
          >
        </p>
      </v-card>
    </div>

    <div class="col-md-8 ps-0">
      <p class="text-h5">STEP 4 (Business Document)</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                POST
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tab4" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Key
              </th>
              <th class="text-left text-white">
                Value
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Deskripsi
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in head" :key="item.key">
              <td>{{ item.key }}</td>
              <td>{{ item.val }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.desc }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="font-weight-bold mb-1">Body Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Field
              </th>
              <th class="text-left text-white">
                Data Type
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Informasi Umum
              </th>
              <th class="text-left text-white">
                Contoh
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in body4" :key="item.key">
              <td>{{ item.field }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.info }}</td>
              <td>{{ item.ex }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="text-h6">Request & Response Payload Sample</p>
      <p class="font-weight-bold mb-1">Request</p>
      <v-card class="pa-2" outlined>
        <p>
          <vue-code-highlight lang="curl">
            <pre>
            curl --location --request POST 'https://api.momofin.com//activation/documents' \
          --header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjgyMzE0MjIwLTBhMTUtMTFlYy04ZmFiLTMxNjk0MWU2MjFjMyIsImlhdCI6MTYzMDQwODAzMSwiZXhwIjoxNjMwNDA5MjMxfQ.CmipRzYQRbkF_5y4oHE31Q7vP7sgD0l4fYRReL2lh-8' \
          --form 'owner_id_card=@"/media/haku/DATA/joker_new/jelajah-api/public/images/hosts/arkandian.jpeg"' \
          --form 'owner_npwp=@"/path/to/file"'
          </pre
            >
          </vue-code-highlight>
        </p>
        <p class="font-weight-bold mb-1">Response</p>
        <v-card class="pa-2" outlined>
          <p>
            \
            <vue-code-highlight lang="curl"
              >\
              <pre>
            {
          "code": 201,
          "success": true,
          "message": "Dokumen Usaha berhasil disimpan"
        }
          </pre
              >
            </vue-code-highlight>
          </p>
        </v-card>
      </v-card>
    </div>

    <div class="col-md-8 ps-0">
      <p class="text-h5">Activation Detail</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tab5" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Key
              </th>
              <th class="text-left text-white">
                Value
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Deskripsi
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in head" :key="item.key">
              <td>{{ item.key }}</td>
              <td>{{ item.val }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.desc }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="text-h6">Request & Response Payload Sample</p>
      <p class="font-weight-bold mb-1">Request</p>
      <v-card class="pa-2" outlined>
        <p>
          <vue-code-highlight lang="curl"
            >curl --location --request GET
            'https://api-v1.momofin.com/v1/auth/activation/detail' \ --header
            'x-access-token:
            eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNTEyNDQxMiwiZXhwIjoxNjM1MjEwODEyfQ.g7pM8RLJr9Jnos3j_C2s7aeZRvbjhv3CSK912KyqLGs'
          </vue-code-highlight>
        </p>
      </v-card>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      tab: [
        {
          req: "Input Format",
          post: "application/json"
        },
        {
          req: "Output Format",
          post: "application/json"
        },
        {
          req: "Endpoint",
          post: "https://api-v1.momofin.com/v1/auth/activation/business"
        }
      ],
      head: [
        {
          key: "",
          val: " ",
          mandatory: "",
          length: " ",
          desc: "Token JWT yang didapatkan setelah login."
        }
      ],
      body: [
        {
          field: "business_type",
          type: "",
          mandatory: "Ya",
          length: "",
          info: "",
          ex: ""
        },
        {
          field: "organization_type",
          type: "",
          mandatory: "",
          length: "",
          info: "",
          ex: ""
        },
        {
          field: "organization_size",
          type: "",
          mandatory: "",
          length: "",
          info: "",
          ex: ""
        },
        {
          field: "transaction_volume",
          type: "",
          mandatory: "",
          length: "",
          info: "",
          ex: ""
        },
        {
          field: "average_transaction_volume",
          type: "",
          mandatory: "",
          length: "",
          info: "",
          ex: ""
        }
      ],

      tab2: [
        {
          req: "Input Format",
          post: "application/json"
        },
        {
          req: "Output Format",
          post: "application/json"
        },
        {
          req: "Endpoint",
          post: "https://api-v1.momofin.com/v1/auth/activation/business"
        }
      ],
      body2: [
        {
          field: "name",
          type: "",
          mandatory: "Ya",
          length: "",
          info: "",
          ex: ""
        },
        {
          field: "industry_type",
          type: "",
          mandatory: "",
          length: "",
          info: "",
          ex: ""
        },
        {
          field: "address",
          type: "",
          mandatory: "",
          length: "",
          info: "",
          ex: ""
        },
        {
          field: "bank_id",
          type: "",
          mandatory: "",
          length: "",
          info: "",
          ex: ""
        },
        {
          field: "account_number",
          type: "",
          mandatory: "",
          length: "",
          info: "",
          ex: ""
        }
      ],

      tab3: [
        {
          req: "Input Format",
          post: "application/json"
        },
        {
          req: "Output Format",
          post: "application/json"
        },
        {
          req: "Endpoint",
          post: "https://api-v1.momofin.com/v1/auth/activation/personal"
        }
      ],
      body3: [
        {
          field: "fullname",
          type: "",
          mandatory: "Ya",
          length: "",
          info: "",
          ex: ""
        },
        {
          field: "email",
          type: "",
          mandatory: "",
          length: "",
          info: "",
          ex: ""
        },
        {
          field: "birth_date",
          type: "",
          mandatory: "",
          length: "",
          info: "Format : yyyy-mm-dd",
          ex: "2000-01-01"
        },
        {
          field: "bank_id",
          type: "",
          mandatory: "",
          length: "",
          info: "",
          ex: ""
        },
        {
          field: "account_number",
          type: "",
          mandatory: "",
          length: "",
          info: "",
          ex: ""
        }
      ],
      tab4: [
        {
          req: "Input Format",
          post: "application/json"
        },
        {
          req: "Output Format",
          post: "application/json"
        },
        {
          req: "Endpoint",
          post: "https://api-v1.momofin.com/v1/auth/activation/documents"
        }
      ],
      body4: [
        {
          field: "owner_npwp",
          type: "",
          mandatory: "Ya",
          length: "",
          info: "",
          ex: ""
        }
      ],
      tab5: [
        {
          req: "Input Format",
          post: "application/json"
        },
        {
          req: "Output Format",
          post: "application/json"
        },
        {
          req: "Endpoint",
          post: "https://api-v1.momofin.com/v1/auth/activation/detail"
        }
      ]
    };
  }
};
</script>
