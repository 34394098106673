<template>
  <v-row align-content="center" class="">
    <v-col class="mx-auto" md="12" style="min-height: 100vh;">
      <v-row v-show="regis">
        <v-col class="pa-0" md="6" style="height: 100vh;background: rgba(0, 104, 214, 1);">
          <div :style="{
          backgroundImage: `url('${require('@/assets/images/bg-login.png')}')`}" style="min-height: 100vh;background-size: cover;">            
            <v-spacer class="py-16"></v-spacer>          
            <v-card class="transparent px-10">          
              <img src="@/assets/images/logo.png" class="mb-3">
              <p class="text-white font-weight-bold title-bg-login px-2 py-8">Solution for your digital transactions mobility.</p>
              <div class="text-center d-lg-none d-md-none d-sm-block">
                <v-btn
                  rounded
                  color="white"
                  outlined
                  large
                >
                  Registration                   
                </v-btn>
                <p class="py-3"><v-icon color="white">ri-arrow-down-circle-fill</v-icon></p>                               
              </div>
            </v-card>
          </div>
        </v-col>
        <v-col class="white" md="6">
          <v-spacer class="py-5"></v-spacer>
          <v-form
            ref="form"
            class="px-8"
            @submit.prevent="submit"
            v-model="valid"
          > 
            <div class="mx-auto text-left mb-3 text-black">
              <p class="font-weight-bold title-login">Register now to gain effortless digital transaction solution</p>
            </div>
            <v-alert
              class="mt-2"
                type="error"
                text
                dense
                prominent
                v-show="response !== null"
                >{{ response }}</v-alert
              > 
            <v-row>
              <v-col class="py-0">
                <div class="subtitle-2 mb-2 text-black mt-3">Name</div>
                <v-text-field
                  filled
                  v-model="fullname"
                  :rules="[...rules('Fullname', 'required'), validate]"
                  required
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>           
            <v-row>
              <v-col class="py-0">
                <div class="subtitle-2 mb-2 text-black mt-3">Email</div>
                <v-text-field
                  filled
                  v-model="email"
                  :rules="[...rules('Email', 'required'), validate]"
                  required
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="py-0">
                <div class="mb-2 subtitle-2 text-black mt-3">Password</div>
                <v-text-field
                  filled
                  v-model="password"
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                  @click:append="show = !show"
                  outlined
                  hide-details="auto"
                  :rules="rules('Password', 'required')"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="6">
                <div class="subtitle-2 mb-2 text-black mt-3">Company Name</div>
                <v-text-field
                  filled
                  v-model="company_name"
                  :rules="[...rules('Company Name', 'required'), validate]"
                  required
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col md="6">
                <div class="subtitle-2 mb-2 text-black mt-3">Phone</div>
                <v-text-field
                  type="number"
                  filled
                  v-model="phone"
                  :rules="[...rules('Phone', 'required'), validate]"
                  required
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0 text-center">
                <p class="text-muted">By creating an account, you accept our Terms and Conditions.</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-btn
                  large
                  elevation="0"
                  type="submit"
                  color="primary"
                  :loading="submiting"
                  block
                  @click="submit"
                >
                  Get Started Now
                </v-btn>
              </v-col>              
            </v-row>
            <v-spacer class="py-8"></v-spacer>
            <v-row>
              <v-col cols="12" class="text-center">
                <span class="text-black">
                Already have an account?    
                <router-link
                  class="font-weight-medium text-decoration-none"
                  tabindex="-1"
                  color="primary"
                  :to="{ name: 'login' }"
                >
                  Log in
                </router-link>
                </span>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-show="step" class="mx-auto" md="6">
          <v-spacer class="py-10"></v-spacer>          
          <div
            class="px-8 white rounded py-3 text-center"           
          > 
            <div class="mx-auto text-center">            
              <img src="@/assets/images/success.png" class="py-3">          
              <p class="font-weight-bold title-login text-black">One step closer!</p>
            </div>
            <div class="mx-auto text-center mb-3 py-3 text-black">
              <p>Congratulation! You have sucessfully created a Momofin Account, please verify your email by clicking the link sent to your email.</p>
              <p>
                If you do not receive the activation email in your inbox, please ceck your spam folder. Still can’t find the email?
              </p>            
            </div>                      
            <router-link
              class="font-weight-medium text-decoration-none text-center"
              tabindex="-1"
              color="primary"
              @click="submit()"
            >
              Resend the link
            </router-link>          
          </div>
          
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import validationMixin from "../_mixins/validation";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      valid: true,
      errors: null,

      regis:true,
      step:false,
      
      email: "",
      password: "",
      fullname:"",
      phone:"",
      company_name:"",
      
      checkbox: false,
      submiting: false,
      show:false,
      response:null,
    };
  },
  computed: {
    validate() {
      return !this.errors || this.errors.message;
    },
  },
  methods: {
    submit() {
      
      setTimeout(() => {
      if (this.$refs.form.validate()) {
        this.submiting = true;
        let data = {
          fullname: this.fullname, 
          phone: this.phone,
          email:this.email,
          company_name:this.company_name,
          password:this.password
        };

        this.axios
          .post(
            `auth/users/register`,data,
            {
              headers: {"Content-Type": "application/json"},
            })
          .then(() => {
            this.submiting = false;            
            this.regis=false;
            this.step=true;
          })
          .catch((error) => {
            this.submiting = false;
            this.$refs.form.reset();
            // console.log('er',error.response)            
            this.response = error.response.data.message;
            setTimeout( () => {                
              this.response=null;
            }
            , 3000);
          });
        } 
      }, 50);
    },
  },
};
</script>

<style>
</style>