<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">Balance</p>
    <!-- <p class="font-weight-bold">
      Api ini digunakan untuk melakukan proses penerimaan uang atau untuk memmpermudah orang lain melakukan pembayaran dengan cara klik tautan yang sudah didapatkan.
    </p> -->

    <!-- Get Balance -->
    <div class="col-md-8 ps-0"> 
      <p class="text-h5">Get Balance</p>
      <p class="font-weight-bold">
        API ini digunakan untuk menampilkan saldo / balance.
      </p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabGet" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Content Type
              </th>
              <th class="text-left text-white">
                Client Id
              </th>
              <th class="text-left text-white">
                Api Key
              </th>
              <th class="text-left text-white">
                X-access-token
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in headCr" :key="item.key">
              <td>{{ item.type }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.api }}</td>
              <td>{{ item.token }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="text-h6">Request & Response Payload Sample</p>      
      <p class="font-weight-bold mb-1">Request</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/etransfer/balance' \
        --header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNTQyODg1OSwiZXhwIjoxNjM1NTE1MjU5fQ.En8c9wMryEpFVRMxo2enaT1e_B-BwekVnB3v-N5sKec'
        </pre></vue-code-highlight>
        </p>
      </v-card>
    </div>

    <!-- Get Company Bank -->
    <div class="col-md-8 ps-0"> 
      <p class="text-h5">Get Company Bank</p>
      <p class="font-weight-bold">
        API ini digunakan untuk menampilkan daftar bank.
      </p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabGc" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Content Type
              </th>
              <th class="text-left text-white">
                Client Id
              </th>
              <th class="text-left text-white">
                Api Key
              </th>
              <th class="text-left text-white">
                X-access-token
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in headCr" :key="item.key">
              <td>{{ item.type }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.api }}</td>
              <td>{{ item.token }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="text-h6">Request & Response Payload Sample</p>      
      <p class="font-weight-bold mb-1">Request</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/etransfer/bank/destination' \
        --header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNTQyODg1OSwiZXhwIjoxNjM1NTE1MjU5fQ.En8c9wMryEpFVRMxo2enaT1e_B-BwekVnB3v-N5sKec'
        </pre></vue-code-highlight>
        </p>
      </v-card>
    </div>

    <!-- Request Deposit -->
    <div class="col-md-8 ps-0"> 
      <p class="text-h5">Request Deposit</p>
      <p class="font-weight-bold">
        API ini digunakan untuk menambahkan / meminta saldo.  
      </p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                POST
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabReq" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Content Type
              </th>
              <th class="text-left text-white">
                Client Id
              </th>
              <th class="text-left text-white">
                Api Key
              </th>
              <th class="text-left text-white">
                X-access-token
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in headCr" :key="item.key">
              <td>{{ item.type }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.api }}</td>
              <td>{{ item.token }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Body Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Field
              </th>
              <th class="text-left text-white">
                Data Type
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Informasi Umum
              </th>
              <th class="text-left text-white">
                Contoh
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in bodyReq" :key="item.key">
              <td>{{ item.field }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.info }}</td>
              <td>{{ item.ex }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="text-h6">Request & Response Payload Sample</p>      
      <p class="font-weight-bold mb-1">Request</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/etransfer/balance/deposit' \
        --header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNTQyODg1OSwiZXhwIjoxNjM1NTE1MjU5fQ.En8c9wMryEpFVRMxo2enaT1e_B-BwekVnB3v-N5sKec' \
        --data-raw '{
            "amount":100000,
            "bank_id":"7acb445c-c72b-4a49-911f-b374664d451b"
        }'
        </pre></vue-code-highlight>
        </p>
      </v-card>
    </div>

    <!-- Request Deposit Confirmation -->
    <div class="col-md-8 ps-0"> 
      <p class="text-h5">Request Deposit Confirmation</p>
      <p class="font-weight-bold">
        API ini digunakan untuk meminta konfirmasi / persetujuan untuk menambahkan saldo.  
      </p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                POST
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabRd" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Content Type
              </th>
              <th class="text-left text-white">
                Client Id
              </th>
              <th class="text-left text-white">
                Api Key
              </th>
              <th class="text-left text-white">
                X-access-token
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in headCr" :key="item.key">
              <td>{{ item.type }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.api }}</td>
              <td>{{ item.token }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Body Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Field
              </th>
              <th class="text-left text-white">
                Data Type
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Informasi Umum
              </th>
              <th class="text-left text-white">
                Contoh
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in bodyRd" :key="item.key">
              <td>{{ item.field }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.info }}</td>
              <td>{{ item.ex }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="text-h6">Request & Response Payload Sample</p>      
      <p class="font-weight-bold mb-1">Request</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/etransfer/balance/deposit-confirmation' \
        --header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNTQyODg1OSwiZXhwIjoxNjM1NTE1MjU5fQ.En8c9wMryEpFVRMxo2enaT1e_B-BwekVnB3v-N5sKec' \
        --data-raw '{
            "invoice_code":"D-000241367"
        }'
        </pre></vue-code-highlight>
        </p>
      </v-card>
    </div>

    <!-- Detail Deposit -->
    <div class="col-md-8 ps-0"> 
      <p class="text-h5">Detail Deposit</p>
      <p class="font-weight-bold">
        API ini digunakan untuk menampilkan detail saldo. 
      </p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabDetail" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Content Type
              </th>
              <th class="text-left text-white">
                Client Id
              </th>
              <th class="text-left text-white">
                Api Key
              </th>
              <th class="text-left text-white">
                X-access-token
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in headCr" :key="item.key">
              <td>{{ item.type }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.api }}</td>
              <td>{{ item.token }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Body Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Field
              </th>
              <th class="text-left text-white">
                Data Type
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Informasi Umum
              </th>
              <th class="text-left text-white">
                Contoh
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in bodyDetail" :key="item.key">
              <td>{{ item.field }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.info }}</td>
              <td>{{ item.ex }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="text-h6">Request & Response Payload Sample</p>      
      <p class="font-weight-bold mb-1">Request</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/etransfer/balance/deposit-detail?invoice_code=D-000241367' \
        --header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNTQyODg1OSwiZXhwIjoxNjM1NTE1MjU5fQ.En8c9wMryEpFVRMxo2enaT1e_B-BwekVnB3v-N5sKec' \
        --data-raw '{
            "amount":100000,
            "bank_id":"7acb445c-c72b-4a49-911f-b374664d451b"
        }'
        </pre></vue-code-highlight>
        </p>
      </v-card>
    </div>
	</v-card>
</template>

<script>

export default {
	components: {
   },
   data() {
    return {
      tabGet: 
      [
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/balance',
        },
      ],
      tabGc: 
      [
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/bank/destination',
        },
      ],
      tabReq: 
      [
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/balance/deposit',
        },
      ],
      bodyReq: 
      [
        {
          field: 'amount',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        }, 
        {
          field: 'bank_id',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        }, 
      ],
      tabRd: 
      [
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/balance/deposit-confirmation',
        },
      ],
      bodyRd: 
      [
        {
          field: 'invoice_code',
          type: 'String',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        }, 
      ],
      headCr:
      [
        {
          type:'',
          id:'',
          api:'',
          token:'Token JWT yang didapatkan setelah login'
        }
      ],
      tabDetail: 
      [
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/balance/deposit-detail?invoice_code=D-000241367',
        },
      ],
      bodyDetail: 
      [
        {
          field: 'amount',
          type: 'String',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        }, 
        {
          field: 'bank_id',
          type: 'String',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        }, 
      ],
    };
  },
};
</script>



