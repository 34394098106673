export default [
  { title: "E-Transfer", icon: "", link: "/docs/guide/e-transfer" },
  { title: "Disbursement", icon: "", link: "/docs/guide/disbursement" },
  { title: "Bulk Disbursement", icon: "", link: "/docs/guide/bulk-disbursement" },
  { title: "Schedule Disbursement", icon: "", link: "/docs/guide/schedule-disbursement" },
  { title: "Single Disbursement", icon: "", link: "/docs/guide/single-disbursement" },
  { title: "Approve & Release", icon: "", link: "/docs/guide/approve-release" },
  // { title: "Add Bank Account", icon: "", link: "/docs/auth/request-header" },	
  // { title: "Get Bank", icon: "", link: "/docs/auth/request-header" },	
  // { title: "Get Bank Account", icon: "", link: "/docs/auth/request-header" },	
  // { title: "Delete Bank ", icon: "", link: "/docs/auth/request-header" },	
  // { title: "Delete Bank Account", icon: "", link: "/docs/auth/request-header" },
  // { title: "Update Bank ", icon: "", link: "/docs/auth/request-header" },	
  // { title: "Update Bank Account", icon: "", link: "/docs/auth/request-header" },	
  // { title: "Transfer Request ", icon: "", link: "/docs/auth/request-header" },	
  // { title: "Confirm Payment ", icon: "", link: "/docs/auth/request-header" },	
  // { title: "list Transfer ", icon: "", link: "/docs/auth/request-header" },	
  // { title: "Approval", icon: "", link: "/docs/auth/request-header" },	
  // { title: "Payment Approval", icon: "", link: "/docs/auth/request-header" },	
  // { title: "Validate Token", icon: "", link: "/docs/auth/request-header" },	
  // { title: "New Request", icon: "", link: "/docs/auth/request-header" },	
  // { title: "Check Recipient", icon: "", link: "/docs/auth/request-header" },	
  // { title: "Disbursement", icon: "", link: "/docs/auth/request-header" },	
  // { title: "Bulk Disbursement", icon: "", link: "/docs/auth/request-header" },	
  // { title: "Scheduled Bulk Disbursement", icon: "", link: "/docs/auth/request-header" },	
  // { title: "Single Disbursement", icon: "", link: "/docs/auth/request-header" },	
  // { title: "Approve & Release", icon: "", link: "/docs/auth/request-header" },	
  { title: "Payment Link", icon: "", link: "/docs/guide/payment-link" },	
  { title: "Invoice", icon: "", link: "/docs/guide/invoice" },	
  { title: "Transaction", icon: "", link: "/docs/guide/transaction" },	
  { title: "Balance", icon: "", link: "/docs/guide/balance" },	
  { title: "Services", icon: "", link: "/docs/guide/services" },	
  { title: "Payment", icon: "", link: "/docs/guide/payment" },	
  { title: "Xfers Service", icon: "", link: "/docs/guide/xfers" },	
];
