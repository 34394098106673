<template>
    <v-card rounded="lg" class="bg-white py-4 px-5">
        <vue-markdown :source="index"> </vue-markdown>
    </v-card>
</template>

<script>
import index from "./list-envelope-status.md";
import prism from "prismjs"

export default {
    mounted() {
    prism.highlightAll()
  },
    setup() {
        return {
            index,
        };
    },
};
</script>
