<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
    <p class="text-uppercase text-h4">Scheduled Bulk Disbursement</p>
    <p class="font-weight-bold">
      Api ini digunakan untuk melakukan proses pembayaran terjadwal, yang mana sebelum melakukan pembayaran anda dapat menentukan waktu / jadwal transfer yang diinginkan. 
    </p>
    <schedule />
  </v-card>
</template>

<script>
import schedule from './content-disb/schedule.vue';

export default {
  components: {
    schedule
   },
   data() {
    return {
    };
  },
};
</script>



