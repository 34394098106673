<template>
  <v-card rounded="lg" class="bg-white py-4">
		<p class="text-h5">Get Document Payment Status</p>
		<p class="font-weight-bold">
			API ini digunakan untuk menampilkan status pembayaran dokumen.
		</p>
		<!-- <p class="font-weight-bold">
			waiting ..
		</p> -->
		<div class="col-md-8 ps-0">			
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tab" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<!-- <div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in body" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div> -->
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>ccurl --location --request GET 'https://api-v1.momofin.com/v1/econtract/document/billing?document_id=138' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjNiMzA3MTIwLTViYzgtMTFlYy05M2M2LTc5YzFlNzkyMWZjYiIsImJ1c2luZXNzX2lkIjoiM2IzMDcxMjEtNWJjOC0xMWVjLTkzYzYtNzljMWU3OTIxZmNiIiwicm9sZXMiOltdLCJpYXQiOjE2NDIyNTg1NDIsImV4cCI6MTY0MzEyMjU0Mn0.1AsvqVFEfcgkZ7j8U-rLe6hDz93px4sOqXDnRTFmHRE'
				</pre></vue-code-highlight>
				</p>
			</v-card>
			<p class="font-weight-bold mb-1">Normal Response</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p>
					<vue-code-highlight lang="json"><pre>
{
    "code": 201,
    "success": true,
    "data": {
    "document": {
    "name": "signing_tes-1642259325219.pdf",
    "size": "",
    "page": 1,
    "is_owner": true
    },
    "billing": [
    {
    "id": 164,
    "is_owner": true,
    "name": "hanan",
    "billing": {
    "emet": 0,
    "esign": 3
    },
    "is_paid": false,
    "payment_info": null,
    "paid_by_me": true
    }
    ],
    "total_billing": {
    "emet": 0,
    "esign": 3
    },
    "balance": {
    "esign": 0,
    "emet": 0
    }
  }
}</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>
	</v-card>
</template>

<script>
export default {
   data() {
    return {
			tab: 
			[
        {
					req: 'Input Format',
					post: 'application/json',
        },
        {
					req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/econtract/document/payment_status/141',
        },
      ],
      head: 
			[
        {
          key: '',
          val: ' ',
          mandatory: '',
          length: ' ',
          desc: 'Token JWT yang didapatkan setelah login',
        },
      ],
      body: 
			[
        {
          field: 'document_id',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'id',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'is_owner',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'name',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'emet',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'esign',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'is_paid',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'payment_info',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'paid_by_me',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'voucher',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
      ],
      resp: 
			[
        {
          field: '',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: '',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
      ]
    };
  },
};
</script>



