<template>
  <v-card rounded="lg" class="bg-white py-4">
		<!-- <p class="text-capitalize text-h5">Get User Profile</p> -->
		<!-- <p class="font-weight-bold">
			Informasi Umum
		</p>
		<p class="font-weight-bold">
			API ini digunakan untuk menampilkan detail profile user sistem, mengubah detail profile serta mengubah foto profil /avatar user sistem.
		</p> -->
		<div class="col-md-8 ps-0">
			<p class="text-h5">Get User Profile</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tab" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Headers</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Content Type
							</th>
							<th class="text-left text-white">
								Client Id
							</th>
							<th class="text-left text-white">
								Api Key
							</th>
							<th class="text-left text-white">
								X-access-token
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.type }}</td>
							<td>{{ item.id }}</td>
							<td>{{ item.api }}</td>
							<td>{{ item.token }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api.momofin.com//users/profile' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjgyMzE0MjIwLTBhMTUtMTFlYy04ZmFiLTMxNjk0MWU2MjFjMyIsImlhdCI6MTYzMDQwNzc0MywiZXhwIjoxNjMwNDA4OTQzfQ.rlIRBQ7KtT0r4iFeZQ4qFqAHslLInY6T_ZsS_bVr6d0'
				</pre></vue-code-highlight>
				</p>
			</v-card>
			<p class="font-weight-bold mb-1">Response</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p>
					<vue-code-highlight lang="curl"><pre>
						{
							"data": [
								{
									"_id": "82314220-0a15-11ec-8fab-316941e621c3",
									"roles": [
										"5c267d20-0a15-11ec-8fab-316941e621c3"
									],
									"fullname": "hanan kusuma",
									"password": "$2a$12$OE1OutJpeuAwUVBLcGJdN.cqYyCSDEC8zhwnwjDmq6icTDO9FJcUu",
									"email": "kusuma.hanan135@gmail.com",
									"verification_code": "830e1a31f485c12c0d43f7f1feeb18f409462f70ebbd93070d6b3e2fd93afd54",
									"verified_at": null,
									"__v": 0,
									"business_id": "fb1fac10-0a49-11ec-b0ff-578628e5e849",
									"profile": [],
									"business_info": [
									{
									"_id": "fb1fac10-0a49-11ec-b0ff-578628e5e849",
									"account_type": "corporate",
									"organization_type": "901bdd30-0a1c-11ec-ae4a-877681a90a2f",
									"organization_size": "901e0010-0a1c-11ec-ae4a-877681a90a2f",
									"transaction_volume": "902022f0-0a1c-11ec-ae4a-877681a90a2f",
									"average_transaction_volume": "90213460-0a1c-11ec-ae4a-877681a90a2f",
									"created_at": "2021-08-31T10:55:40.369Z",
									"updated_at": "2021-08-31T10:55:40.369Z",
									"__v": 0
									}
								],
								"business_detail": [],
								"business_documents": []
								}
							]
						}
					</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>
		<div class="col-md-8 ps-0">
			<p class="text-h5 mb-1">Update Profile</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tab" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Content Type
							</th>
							<th class="text-left text-white">
								Client Id
							</th>
							<th class="text-left text-white">
								Api Key
							</th>
							<th class="text-left text-white">
								X-access-token
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.type }}</td>
							<td>{{ item.id }}</td>
							<td>{{ item.api }}</td>
							<td>{{ item.token }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in body" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/auth/users/profile' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyMGQ1MWYwLTVjNzItMTFlYy1hYTk1LWU1ZjI4MzhmOWI3MyIsImJ1c2luZXNzX2lkIjoiNjIwZDUxZjEtNWM3Mi0xMWVjLWFhOTUtZTVmMjgzOGY5YjczIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzOTQ4MTkzNCwiZXhwIjoxNjQwMzQ1OTM0fQ.u9tjO-GT3LTg_OoqRGgK68X2Gt664gPS0bHpEEPIowk' \
				--data-raw '{
						"fullname":"Eringga Sutandang",
						"phone_number":"085729920996",
						"birth_place":"jogja",
						"birth_date":"01/01/1990",
						"idcard_number":"1234567891234565",
						"gender":"M",
						"address":"jogja",
						"city":"jogja",
						"province":"DIY",
						"district":"kecamatan",
						"postal_code":"42323"
				}'</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>
		<!-- <div class="col-md-8 ps-0">
			<p class="font-weight-bold mb-1">Update Foto Profile/Avatar</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabProf" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in bodyProf" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/auth/users/update_avatar' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyMGQ1MWYwLTVjNzItMTFlYy1hYTk1LWU1ZjI4MzhmOWI3MyIsImJ1c2luZXNzX2lkIjoiNjIwZDUxZjEtNWM3Mi0xMWVjLWFhOTUtZTVmMjgzOGY5YjczIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzOTQ4MTkzNCwiZXhwIjoxNjQwMzQ1OTM0fQ.u9tjO-GT3LTg_OoqRGgK68X2Gt664gPS0bHpEEPIowk' \
				--form 'avatar=@"OJJlsOgew/image_2021-12-30_17-09-38.png"'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div> -->
	</v-card>
</template>

<script>
export default {
   data() {
    return {
			tab: 
			[
        {
					req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/auth/users/profile',
        },
      ],
      head:
      [
				{
					type:'',
					id:'',
					api:'',
					token:'Token JWT yang didapatkan setelah login'
				}
      ],
      resp: 
			[
        {
          field: '',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: '',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
      ],
      body: 
			[
        {
          field: 'fullname',
          type: 'file/video',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'phone_number',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: 'Minimal 10 digit',
          ex:'08523451xxxx'
        },
        {
          field: 'birth_place',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'birth_date',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: 'Menggunakan format "DD/MM/YYYY"',
          ex:'01/01/1990'
        },
        {
          field: 'id_card_number',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:'1234567891234565'
        },
        {
          field: 'gender',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: 'Value: “M” dan “F”',
          ex:''
        },
        {
          field: 'address',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'city',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'province',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'district',
          type: 'String',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'postal_code',
          type: 'String',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        }
      ],

      tabProf: 
			[
        {
					req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api.momofin.com//v1/auth/users/update_avatar',
        },
      ],
      bodyProf: 
			[
        {
          field: 'avatar',
          type: 'file/foto',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        }
      ],
    };
  },
};
</script>



