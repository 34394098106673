<template>
    <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">HTTP RESULT CODE</p>
		<!-- <p class="">
		MomofinAPI menggunakan Auth sebagai proses otorisasi dalam menentukan hak akses pengguna. Sebelum melakukan proses API request ke produk MOMOFIN, anda harus mendapatkan token dengan cara memanggil endpoint Get Token.</p>
		<p>
		Parameter dibawah ini disertakan dalam header request di setiap panggilan API.
		</p> -->
		<div class="col-md-8 ps-0">
			<p class="text-h5">Enumeration</p>			
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<tbody>
						<tr>
							<td>100</td>
							<td></td>
						</tr>
						<tr>
							<td>200</td>
							<td>The call was successful</td>
						</tr>
						<tr>
							<td>201</td>
							<td>The call was successful</td>
						</tr>
						<tr>
							<td>404</td>
							<td></td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
    </v-card>
</template>

<script>
export default {
    setup() {
        return {
        };
    },
};
</script>
