<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">Transaksi</p>
    <!-- <p class="font-weight-bold">
      Api ini digunakan untuk melakukan proses penerimaan uang atau untuk memmpermudah orang lain melakukan pembayaran dengan cara klik tautan yang sudah didapatkan.
    </p> -->

    <!-- List Transaksi -->
    <div class="col-md-8 ps-0"> 
      <p class="text-h5">List Transaksi</p>
      <p class="font-weight-bold">
        API ini digunakan untuk menampilkan daftar transaksi yang sudah dibuat.
      </p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabList" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Content Type
              </th>
              <th class="text-left text-white">
                Client Id
              </th>
              <th class="text-left text-white">
                Api Key
              </th>
              <th class="text-left text-white">
                X-access-token
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in headCr" :key="item.key">
              <td>{{ item.type }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.api }}</td>
              <td>{{ item.token }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="text-h6">Request & Response Payload Sample</p>      
      <p class="font-weight-bold mb-1">Request</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/etransfer/transaction' \
        --header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNDU0NDc2MiwiZXhwIjoxNjM0NjMxMTYyfQ.Ymw2b5ckVPpqqPncr9rBWHVDAlgtqgiaZjGLNOAPBAQ'
        </pre></vue-code-highlight>
        </p>
      </v-card>
    </div>

	</v-card>
</template>

<script>

export default {
	components: {
   },
   data() {
    return {
      tabList: 
      [
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/transaction',
        },
      ],
      headCr:
      [
        {
          type:'',
          id:'',
          api:'',
          token:'Token JWT yang didapatkan setelah login'
        }
      ],
    };
  },
};
</script>



