<template>
  <v-card rounded="lg" class="bg-white py-4">
		<!-- <p class="font-weight-bold">
			waiting ..
		</p> -->
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Cek Status</p>
			<p class="font-weight-bold">
				API ini digunakan untuk cek status sertifikat yang sudah dibuat. 
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tab" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/certificate/auth/status' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNDA5MDQ4OSwiZXhwIjoxNjM0MTc2ODg5fQ.u1xnlT0gW2M98d02gj8Yd7O6dSCb0r4Sozn5bVep24M'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>

		<div class="col-md-8 ps-0">			
			<p class="text-h5">Auth</p>
			<p class="font-weight-bold">
				API ini digunakan sebagai proses autentikasi e-certificate. 
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabA" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/certificate/auth/auth' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNDA5MDQ4OSwiZXhwIjoxNjM0MTc2ODg5fQ.u1xnlT0gW2M98d02gj8Yd7O6dSCb0r4Sozn5bVep24M'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>
	</v-card>
</template>

<script>
export default {
   data() {
    return {
			tab: 
			[
        {
          req: 'Input Format',
          post: 'multipart/form-data',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/certificate/auth/status',
        },
      ],
      tabA: 
			[
        {
          req: 'Input Format',
          post: 'multipart/form-data',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/certificate/auth/auth',
        },
      ],
      head: 
			[
        {
          key: '',
          val: ' ',
          mandatory: '',
          length: ' ',
          desc: 'Token JWT yang didapatkan setelah login',
        },
      ],
      body: 
			[
        {
          field: 'filename',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: 'signature (filename from user)',
          ex:''
        },
        {
          field: 'document_id',
          type: ' ',
          mandatory: '',
          length: '',
          info: '',
          ex:'',
        },
        {
          field: 'files',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:'',
        },
      ],
      resp: 
			[
        {
          field: '',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: '',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
      ]
    };
  },
};
</script>



