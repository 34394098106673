<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">Notification Service</p>
		<!-- p class="font-weight-bold">
			Informasi Umum
		</p>
		<p class="font-weight-bold">
			API ini digunakan untuk proses menampilkan keseluruhan, mengubah data pengguna, ubah password pengguna, proses KYC (rekam identitas, video) serta update avatar pengguna.
		</p> -->
		<div class="col-md-8 ps-0">	
			<p class="text-h5">Get Zenziva Balance</p>
			<p class="font-weight-bold">
				API ini digunakan untuk menampilkan notifikasi balance / saldo. 
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabGet" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h6">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api.momofin.com//v1/notifications/balance'</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>

		<div class="col-md-8 ps-0">	
			<p class="text-h5">Send SMS</p>
			<p class="font-weight-bold">
				API ini digunakan untuk mengirimkan notifikasi via SMS. 
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabSms" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Body Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Field
              </th>
              <th class="text-left text-white">
                Data Type
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Informasi Umum
              </th>
              <th class="text-left text-white">
                Contoh
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in bodySms" :key="item.key">
              <td>{{ item.field }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.info }}</td>
              <td>{{ item.ex }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
		<div class="col-md-8 ps-0">			
			<p class="text-h6">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/notifications/sms' \
				--data-raw '{
						"receiver":"085716838002",
						"message":"test kirim sms dari postman"
				}'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>

		<div class="col-md-8 ps-0">	
			<p class="text-h5">Send WhatsApp</p>
			<p class="font-weight-bold">
				API ini digunakan untuk mengirimkan notifikasi via WhatsApp. 
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabWa" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h6">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/notifications/wa'</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>

		<div class="col-md-8 ps-0">	
			<p class="text-h5">Send Email</p>
			<p class="font-weight-bold">
				API ini digunakan untuk mengirimkan notifikasi via Email. 
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabEmail" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Body Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Field
              </th>
              <th class="text-left text-white">
                Data Type
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Informasi Umum
              </th>
              <th class="text-left text-white">
                Contoh
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in bodyEmail" :key="item.key">
              <td>{{ item.field }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.info }}</td>
              <td>{{ item.ex }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">			
			<p class="text-h6">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/notifications/email' \
				--data-raw '{"receivers":"kusuma.hanan@gmail.com","receivers_name":"hanan kusuma","type":"verification","link":"localhost:3000/v1/auth/users/verification-account/8f9e853493c3c822bccfe336175a2faa4f55742cf4b881a7b7b008767190825a","subject":"Account Verification"}'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>
	</v-card>
</template>

<script>

export default {
	components: {

  },
   data() {
    return {
			tabGet: 
			[
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/notifications/balance',
        },
      ],
      tabSms: 
			[
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/notifications/sms',
        },
      ],
      bodySms: 
      [
        {
          field: 'receiver',
          type: 'Int',
          mandatory: 'Ya',
          length: '',
          info: 'Nomor Telepon Pengguna',
          ex:''
        },
        {
          field: 'message',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
      ],
      tabWa: 
			[
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/notifications/wa',
        },
      ],
      tabEmail: 
			[
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/notifications/email',
        },
      ],
      bodyEmail: 
      [
        {
          field: 'receiver',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: 'Email user',
          ex:''
        },
        {
          field: 'receivers_name',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: 'Nama user',
          ex:''
        },
        {
          field: 'Type',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'Link',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: 'https://api-v1.momofin.com/v1/auth/users/verification-account/8f9e853493c3c822bccfe336175a2faa4f55742cf4b881a7b7b008767190825a',
          ex:''
        },
        {
          field: 'Subject',
          type: '',
          mandatory: '',
          length: '',
          info: 'Account Verification',
          ex:''
        },
      ],
      head:
      [
				{
					type:'',
					id:'',
					api:'',
					token:'Token JWT yang didapatkan setelah login'
				}
      ],

    };
  },
};
</script>



