<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">Document</p>
    <!-- <p class="font-weight-bold">
      API ini digunakan untuk menambahkan nama - nama channel doc yang digunakan. 
    </p> -->
    <doc />
    <document />
    <upload />
    <!-- <edit /> -->
    <docbill />
    <checkout />
    <setspe />
    <docstatus />
    <sendoc />
    <sign />
    <newotp />
		<newdoc />
	</v-card>
</template>

<script>
import doc from './content-contract/doc.vue';
// import edit from './content-contract/upload-edit.vue';
import document from '.././api-services/document.vue';
import upload from '.././api-services/upload-document.vue';
import docbill from './content-contract/doc-billing.vue';
import checkout from '.././api-services/checkout-document.vue';
import setspe from './content-contract/set-spesimen.vue';
import docstatus from '.././api-services/get-document.vue';
import sendoc from './content-contract/send-doc.vue';
import sign from './content-contract/signing.vue';
import newotp from './content-contract/new-otp.vue';
import newdoc from './content-contract/new-doc.vue';

export default {
	components: {
    doc,
    document,
    upload,
    // edit,
    docbill,
    checkout,
    setspe,
    docstatus,
    sendoc,
    sign,
    newotp,
    newdoc
   },
   data() {
    return {
    };
  },
};
</script>



