require("./lib");

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./lib/vuetify";
import VueCompositionAPI from '@vue/composition-api'
import VueMarkdown from "vue-markdown";
import prism from "prismjs"
import 'prismjs/plugins/show-language/prism-show-language'
import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard'
import 'prismjs/plugins/line-numbers/prism-line-numbers'
import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-liquid'
import 'prismjs/components/prism-markdown'
import 'prismjs/components/prism-http'
import 'prismjs/components/prism-markup-templating'
import 'prismjs/components/prism-uri'
import 'prismjs/components/prism-php'
import 'prismjs/components/prism-scss'
import 'prismjs/themes/prism-okaidia.css'

import { component as VueCodeHighlight } from 'vue-code-highlight';

Vue.component('vue-code-highlight',VueCodeHighlight)
Vue.use(VueCompositionAPI)
Vue.use(VueMarkdown);
Vue.component('vue-markdown', VueMarkdown);

Vue.config.productionTip = false;


(async () => {
  await store.dispatch("get_user");
  prism.highlightAll();
  console.log('hello', prism.plugins);
  
  Object.keys(prism.plugins);
  new Vue({
    mounted() {
      prism.highlightAll();
    },
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
})();
