<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">New Request</p>
		<p class="font-weight-bold">
			Informasi Umum
		</p>
		<p class="font-weight-bold">
			waiting ..
		</p>

	</v-card>
</template>

<script>
export default {
   data() {
    return {

    };
  },
};
</script>



