<template>
  <transition name="fade">
    <v-app v-if="loaded">
      <!-- Sidenav / Navigation drawer -->
      <v-navigation-drawer
        id="navigasi"
        style="width: 350px"
        fixed
        floating
        v-model="drawer"
        :permanent="$vuetify.breakpoint.mdAndUp"
        :temporary="!$vuetify.breakpoint.mdAndUp"
        class="elevation-1 bg-navy"
      >
        <!-- style="width:300px;" -->
        <img src="@/assets/images/logo.png" alt="Avatar" class="ma-3 ml-5" style="width: 150px;" />

        <!-- Overview -->
        <v-list flat>
          <template v-for="item in menu">
            <v-list-item
              active-class="menu-active"
              class="transparent fs-14"
              :key="item.title"
              :to="item.link"
              @click="pageChanged(0, item)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>

        <v-divider style="border-color:#0C3664;"></v-divider>

        <!-- e-Signature API 101 -->
        <!-- <v-list flat>
          <v-list-group :value="false">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  e-Signature API 101
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-for="item in esignature">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <p
              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              Versioning
            </p>
            <template v-for="item in versioning">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <p
              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              eSignature concepts
            </p>
            <template v-for="item in concepts">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <p
              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              Rules and resource limits
            </p>
            <template v-for="item in rulesandresources">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </v-list>
        
        <v-divider style="border-color:#0C3664;"></v-divider> -->

        <!-- Quickstart -->
        <!-- <v-list flat>
          <v-list-group :value="false">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  Quickstart
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-for="item in quickstart">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </v-list>

        <v-divider style="border-color:#0C3664;"></v-divider> -->

        <!-- How-to guides -->
        <!-- <v-list flat>
          <v-list-group :value="false">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  How-to guides
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-for="item in howtoguide">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <p
              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              Requesting a signature
            </p>
            <template v-for="item in requestingsignature">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <p
              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              Working with envelopes and templates
            </p>
            <template v-for="item in workingenvelope">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <p
              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              Working with documents
            </p>
            <template v-for="item in workingdocument">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <p
              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              Working with tabs
            </p>
            <template v-for="item in workingtab">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <p
              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              Working with brands
            </p>
            <template v-for="item in workingbrand">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <p
              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              Working with permissions
            </p>
            <template v-for="item in workingpermission">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <p
              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              Implementing multi-factor recipient (signer) authentication
            </p>
            <template v-for="item in implementingfactor">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </v-list>

        <v-divider style="border-color:#0C3664;"></v-divider> -->

        <!-- SDKs and tools -->
        <!-- <v-list flat>
          <v-list-group :value="false">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  SDKs and tools
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-for="item in sdkandtools">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            
            <p
              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              C# SDK
            </p>
            <template v-for="item in csdk">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            
            <p
              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              Java SDK
            </p>
            <template v-for="item in javasdk">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            
            <p
              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              Node.js SDK
            </p>
            <template v-for="item in nodejssdk">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            
            <p
              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              PHP SDK
            </p>
            <template v-for="item in phpsdk">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            
            <p
              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              Python SDK
            </p>
            <template v-for="item in pythonsdk">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            
            <p
              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              Ruby SDK
            </p>
            <template v-for="item in rubysdk">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </v-list>
        
        <v-divider style="border-color:#0C3664;"></v-divider> -->

        <!-- Go-Live -->
        <!-- <v-list flat>
          <v-list-group :value="false">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  Go-Live
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-for="item in golive">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </v-list>

        <v-divider style="border-color:#0C3664;"></v-divider> -->

        <!-- Autentifikasi -->
        <v-list flat>
          <v-list-group :value="false">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  Autentifikasi
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <!-- <p
              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              Token
            </p> -->
            <template v-for="item in token">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <!-- <p
              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              Signature
            </p>
            <template v-for="item in signature">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-for="item in description">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template> -->
          </v-list-group>
        </v-list>

        <v-divider style="border-color:#0C3664;"></v-divider>

        <!-- API SERVICES -->
        <!-- <v-list flat>
          <v-list-group :value="false">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  API Services
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <p
              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              User Management
            </p>
            <template v-for="item in akun">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <p
             v-if="false"
              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              Notification Service
            </p>
            <template v-for="item in notif">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <p
              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              E-Signature & E-Stamp
            </p>
            <template v-for="item in eSignatures">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <p
              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              Top UP
            </p>
            <template v-for="item in topup">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </v-list> -->

        <v-divider style="border-color:#0C3664;"></v-divider>

        <!-- How to guide -->
        <v-list flat>
          <v-list-group :value="false">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  How to guide
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <p
              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              E-Contract
            </p>
            <template v-for="item in toCont">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <p
             v-if="false"
              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              E-Certificate
            </p>
            <template v-for="item in toCertif">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <p
             v-if="false"

              class="mb-1 pt-2 pl-12 font-weight-medium fs-12 text-blue-menu"
              style="letter-spacing: 0.25em;"
            >
              Payment
            </p>
            <template v-for="item in toPay">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </v-list>

        <v-divider style="border-color:#0C3664;"></v-divider>

        <!-- Error Code -->
        <v-list flat>
          <v-list-group :value="false">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  Error Code
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-for="item in errorCode">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </v-list>

        <!-- API Reference -->
        <!-- <v-list flat>
          <template v-for="item in history">
            <v-list-item
              active-class="menu-active"
              class="transparent text-semi fs-14"
              :key="item.title"
              :to="item.link"
              @click="pageChanged(0, item)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list> -->

        <v-divider style="border-color:#0C3664;"></v-divider>
      </v-navigation-drawer>

      <!-- Topnav / App bar -->
      <v-main
        id="tool"
        class="background"
        :style="{ marginLeft: $vuetify.breakpoint.mdAndUp ? '350px' : 0 }"
      >
        <v-app-bar
          id="tool"
          color="background bg-navy"
          :style="{ marginLeft: $vuetify.breakpoint.mdAndUp ? '350px' : 0 }"
          flat
          fixed
          class="bg-navy"
        >
          <v-app-bar-nav-icon
            v-if="!$vuetify.breakpoint.mdAndUp"
            class="ma-2"
            @click="drawer = true"
          ></v-app-bar-nav-icon>
          <v-spacer v-if="!$vuetify.breakpoint.mdAndUp"></v-spacer>

          <img
            v-if="!$vuetify.breakpoint.mdAndUp"
            src="@/assets/images/logo.png"
            alt="Avatar"
            class="ma-3 ml-5"
            style="width: 150px;"
          />

          <!-- <v-toolbar-title>
            <h3 class="font-weight-regular">{{ pageTitle }}</h3>
          </v-toolbar-title> -->
          <!-- <v-text-field
            outlined
            append-icon="$search"
            class="pt-0 mt-0 mr-4 mx-width-85 d-none d-lg-block"
            placeholder="Search"
            solo
            flat
          ></v-text-field> -->

          <v-spacer></v-spacer>

          <!-- <div class="d-none d-lg-block">
            <v-menu offset-y>
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  color="white"
                  class="mx-2 my-3"
                  small
                  icon
                  elevation="1"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>ri-questionnaire-line</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item class="fs-1">
                  <v-list-item-title
                    v-text="'Tidak ada notifikasi'"
                  ></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-divider
              vertical
              class="my-auto"
              style="height: 40px; min-height: auto"
            ></v-divider>
          </div>
          <div class="d-none d-lg-block">
            <v-menu offset-y>
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  color="white"
                  class="mx-2 my-3"
                  small
                  icon
                  elevation="1"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>$notification</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item class="fs-1">
                  <v-list-item-title
                    v-text="'Tidak ada notifikasi'"
                  ></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-divider
              vertical
              class="my-auto"
              style="height: 40px; min-height: auto"
            ></v-divider>
          </div> -->
          <!-- <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div class="ml-6 rounded-pill d-none d-lg-block" v-ripple v-bind="attrs" v-on="on">
                <v-avatar class="elevation-1 white mr-2" size="40px">
                  <img src="@/assets/images/no-pict.png" alt="Avatar" />
                </v-avatar>
                <span class="font-weight-medium">{{ user.fullname }}</span>
                <v-icon class="mx-2" small>$dropdown</v-icon>
              </div>
            </template>

            <v-list dense>
              <v-list-item link @click="logout" class="fs-1">
                <v-list-item-title v-text="'Logout'"></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y >
            <template v-slot:activator="{ on, attrs }">
              <div class="ml-4 rounded-pill d-lg-none d-md-block" v-ripple v-bind="attrs" v-on="on">
                <v-avatar class="elevation-1 white mr-2" size="40px">
                  <img src="@/assets/images/no-pict.png" alt="Avatar" />
                </v-avatar>
                <span class="font-weight-medium">{{ user.fullname }}</span>
                <v-icon class="mx-2" small>$dropdown</v-icon>
              </div>
            </template>

            <v-list dense>
              <v-list-item link to="/">
                <v-list-item-title v-text="'Notifikasi'"></v-list-item-title>
              </v-list-item>
              <v-list-item link @click="logout">
                <v-list-item-title v-text="'Logout'"></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
        </v-app-bar>

        <v-container fluid style="margin-top: 64px">
          <div class="content-wrapper mx-2">
            <transition name="fade-up">
              <router-view v-if="loaded" @page-changed="pageChanged"></router-view>
            </transition>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import menu from './menu';

import esignature from './esignature/esignature';
import versioning from './esignature/versioning';
import concepts from './esignature/concepts';
import rulesandresources from './esignature/rulesandresources';

import quickstart from './quickstart/quickstart';

import howtoguide from './howtoguide/howtoguide';
import requestingsignature from './howtoguide/requestingsignature';
import workingenvelope from './howtoguide/workingenvelope';
import workingdocument from './howtoguide/workingdocument';
import workingtab from './howtoguide/workingtab';
import workingbrand from './howtoguide/workingbrand';
import workingpermission from './howtoguide/workingpermission';
import implementingfactor from './howtoguide/implementingfactor';

import sdkandtools from './sdkandtools/sdkandtools';
import csdk from './sdkandtools/csdk';
import javasdk from './sdkandtools/javasdk';
import nodejssdk from './sdkandtools/nodejssdk';
import phpsdk from './sdkandtools/phpsdk';
import pythonsdk from './sdkandtools/pythonsdk';
import rubysdk from './sdkandtools/rubysdk';

import golive from './golive/golive';

import setting from './setting';
import history from './history';

import token from './auth/token';
import description from './auth/description';
import signature from './auth/signature';

import akun from './apiservices/akun';
// import notif from './apiservices/notification';
import eSignatures from './apiservices/esignature';
import topup from './apiservices/topup';

import errorCode from './errorcode/error';
import toGuide from './guide/e-trans';
import toCont from "./guide/guide";
// import toCertif from './guide/e-certif';
// import toPay from './guide/payment';
import stampDoc from './guide/stampDoc'

export default {
  data() {
    return {
      breadcrumbs: [],
      loaded: true,
      drawer: null,
      notifications: [1],
      pageTitle: '',
      admins: [
        ['Management', 'mdi-account-multiple-outline'],
        ['Settings', 'mdi-cog-outline'],
      ],
      cruds: [
        ['Create', 'mdi-plus-outline'],
        ['Read', 'mdi-file-outline'],
        ['Update', 'mdi-update'],
        ['Delete', 'mdi-delete'],
      ],
    };
  },
  setup() {
    return {
      menu,
      stampDoc,
      esignature,
      versioning,
      concepts,
      rulesandresources,
      quickstart,
      howtoguide,
      requestingsignature,
      workingenvelope,
      workingdocument,
      workingtab,
      workingbrand,
      workingpermission,
      implementingfactor,
      sdkandtools,
      csdk,
      javasdk,
      nodejssdk,
      phpsdk,
      pythonsdk,
      rubysdk,
      golive,
      setting,
      history,
      token,
      signature,
      description,
      akun,
      eSignatures,
      topup,
      errorCode,
      toGuide,
      toCertif: [],
      toCont,
      notif: [],
      toPay: [],
      ...mapGetters({ user: 'user' }),
    };
  },
  created() {
    this.$root.$on('set-page-title', (pageTitle) => {
      this.pageTitle = pageTitle;
    });

    let path = '/' + this.$route.path.split('/')[1];
    let index = this._.findIndex(this.items, { link: path });
    if (index != -1) {
      this.pageTitle = this.items[index].title;
      this.breadcrumbs.push({
        text: this.items[index].title,
        disabled: true,
        href: path,
      });
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/');
      });
    },
    updateLocation(item) {
      // this.pageChanged(0, item);
      this.pageTitle = item.title;
    },
    pageChanged(segment, location = null) {
      this.breadcrumbs.splice(segment);
      if (location != null) {
        this.pageTitle = location.title;
        this.breadcrumbs.map((item) => (item.disabled = false));
        this.breadcrumbs.push({
          text: location.title,
          disabled: true,
          href: location.link,
        });
      }

      document.title =
        this.breadcrumbs
          .slice()
          .reverse()
          .map((val) => val.text)
          .join(' / ') +
        ' - ' +
        process.env.VUE_APP_TITLE;
    },
  },
};
</script>

<style>
.fs-1 {
  padding: 0 8px !important;
}
.fs-1 .v-list-item__title {
  font-size: 1rem !important;
  line-height: 1.25 !important;
}
</style>
