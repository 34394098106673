<template>
    <v-card rounded="lg" class="bg-white py-4 px-5">
        <vue-markdown :source="quickstart"> </vue-markdown>
    </v-card>
</template>

<script>
import quickstart from "./quickstart.md";
export default {
    setup() {
        return {
            quickstart,
        };
    },
};
</script>
