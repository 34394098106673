<template>
  <v-card rounded="lg" class="bg-white py-4">
    <p class="text-h5">New Document Signing</p>
    <p class="font-weight-bold">
      API ini digunakan untuk penandatangan dokumen baru.
    </p>
    <!-- <p class="font-weight-bold">
			waiting ..
		</p> -->
    <div class="col-md-8 ps-0">
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                POST
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tab" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Key
              </th>
              <th class="text-left text-white">
                Value
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Deskripsi
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in head" :key="item.key">
              <td>{{ item.key }}</td>
              <td>{{ item.val }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.desc }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="font-weight-bold mb-1">Body Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Field
              </th>
              <th class="text-left text-white">
                Data Type
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Informasi Umum
              </th>
              <th class="text-left text-white">
                Contoh
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in body" :key="item.key">
              <td>{{ item.field }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.info }}</td>
              <td>{{ item.ex }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="text-h5">Request & Response Payload Sample</p>
      <p class="font-weight-bold mb-1">Request</p>
      <v-card class="pa-2" outlined>
        <p>
          <vue-code-highlight lang="curl"
            >curl --location --request POST
            'https://api-v1.momofin.com/v1/econtract/signing/document' \
            --header 'x-access-token:
            eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImZmYmIyNmMwLTBiMDUtMTFlYy1hMzg1LTY5MjRkYTcwMzIyNSIsImJ1c2luZXNzX2lkIjoiZmZiYjI2YzEtMGIwNS0xMWVjLWEzODUtNjkyNGRhNzAzMjI1Iiwicm9sZXMiOltdLCJpYXQiOjE2MzkxMDk0NDcsImV4cCI6MTYzOTE5NTg0N30.6QlShVSMNqxrwCAKbrIdzvgqqI4QHo72rfIJOOL7cAM'
            \ --data-raw '{ "document_id":6, "otp":"328881" }'
          </vue-code-highlight>
        </p>
      </v-card>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      tab: [
        {
          req: "Input Format",
          post: "application/json"
        },
        {
          req: "Output Format",
          post: "application/json"
        },
        {
          req: "Endpoint",
          post: "https://api-v1.momofin.com/v1/econtract/signing/document"
        }
      ],
      head: [
        {
          key: "",
          val: " ",
          mandatory: "",
          length: " ",
          desc: "Token JWT yang didapatkan setelah login"
        }
      ],
      body: [
        {
          field: "document_id",
          type: "",
          mandatory: "",
          length: "",
          info: "",
          ex: ""
        },
        {
          field: "otp",
          type: "",
          mandatory: "",
          length: "",
          info: "",
          ex: ""
        }
      ]
    };
  }
};
</script>

