<template>
  <v-card rounded="lg" class="bg-white py-4">
    <!-- <p class="text-uppercase text-h4">dokumen</p>
		<p class="font-weight-bold">
			Informasi Umum
		</p>
		<p class="font-weight-bold">
			API ini digunakan untuk menampilkan detail list dokumen.
		</p> -->
    <div class="col-md-8 ps-0">
      <p class="text-h5">List Dokumen</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tab" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Key
              </th>
              <th class="text-left text-white">
                Value
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Deskripsi
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in head" :key="item.key">
              <td>{{ item.key }}</td>
              <td>{{ item.val }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.desc }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <!-- <div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in body" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>  -->
    <div class="col-md-8 ps-0">
      <p class="text-h5">Request & Response Payload Sample</p>
      <p class="font-weight-bold mb-1">Request</p>
      <v-card class="pa-2" outlined>
        <vue-code-highlight language="curl">
          curl --location --request GET
          'https://api-v1.momofin.com/v1/econtract/document/list' \ --header
          'x-access-token:
          eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjcyM2JlMzkwLTVjYmItMTFlYy1iYTY5LTMxNjljNWYzMmU1MCIsImJ1c2luZXNzX2lkIjoiNzIzYmUzOTEtNWNiYi0xMWVjLWJhNjktMzE2OWM1ZjMyZTUwIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTY0MDk1NTg2OSwiZXhwIjoxNjQxODE5ODY5fQ.JqNlGAVLpDN98H9EPgkEXpZd4qp9rU2zLTDKv36bLD4'
        </vue-code-highlight>
      </v-card>
    </div>

    <div class="col-md-8 ps-0">
      <p class="ftext-h5">Detail Dokumen</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabDetail" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Key
              </th>
              <th class="text-left text-white">
                Value
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Deskripsi
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in headDetail" :key="item.key">
              <td>{{ item.key }}</td>
              <td>{{ item.val }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.desc }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <!-- <div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in bodyDetail" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>  -->
    <div class="col-md-8 ps-0">
      <p class="text-h5">Request & Response Payload Sample</p>
      <p class="font-weight-bold mb-1">Request</p>
      <v-card class="pa-2" outlined>
        <p>
          <vue-code-highlight lang="curl"
            >curl --location --request GET
            'https://api-v1.momofin.com/v1/econtract/document/detail/3' \
            --header 'x-access-token:
            eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjcyM2JlMzkwLTVjYmItMTFlYy1iYTY5LTMxNjljNWYzMmU1MCIsImJ1c2luZXNzX2lkIjoiNzIzYmUzOTEtNWNiYi0xMWVjLWJhNjktMzE2OWM1ZjMyZTUwIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTY0MDk1NTg2OSwiZXhwIjoxNjQxODE5ODY5fQ.JqNlGAVLpDN98H9EPgkEXpZd4qp9rU2zLTDKv36bLD4'
          </vue-code-highlight>
        </p>
      </v-card>
      <p class="font-weight-bold mb-1">Normal Response</p>
      <v-card class="pa-2" outlined> </v-card>
    </div>

    <div class="col-md-8 ps-0">
      <p class="text-h5">Audit Trail</p>
      <p class="font-weight-bold">
        Informasi Umum
      </p>
      <p class="font-weight-bold">
        API ini digunakan untuk merekam proses bukti dokumenter yang dibuat
        seperti proses pembubuhan tanda tangan dan materai elektronik pada
        sebuah dokumen tertentu.
      </p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabAudit" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Key
              </th>
              <th class="text-left text-white">
                Value
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Deskripsi
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in headAudit" :key="item.key">
              <td>{{ item.key }}</td>
              <td>{{ item.val }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.desc }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <!-- <div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in bodyAudit" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>  -->
    <div class="col-md-8 ps-0">
      <p class="text-h5">Request & Response Payload Sample</p>
      <p class="font-weight-bold mb-1">Request</p>
      <v-card class="pa-2" outlined>
        <p>
          <vue-code-highlight lang="curl"
            >curl --location --request GET
            'https://api-v1.momofin.com/v1/econtract/document/activities?id=3' \
            --header 'x-access-token:
            eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjNiMzA3MTIwLTViYzgtMTFlYy05M2M2LTc5YzFlNzkyMWZjYiIsImJ1c2luZXNzX2lkIjoiM2IzMDcxMjEtNWJjOC0xMWVjLTkzYzYtNzljMWU3OTIxZmNiIiwicm9sZXMiOltdLCJpYXQiOjE2NDEzMTA4MDYsImV4cCI6MTY0MjE3NDgwNn0.j0nC_n78CgM_Jc0dUtOcgTVni8kHXBecqIFPvrNHAhs'
          </vue-code-highlight>
        </p>
      </v-card>
      <p class="font-weight-bold mb-1">Normal Response</p>
      <v-card class="pa-2" outlined>
        <p>
          <vue-code-highlight lang="json">
<pre>
{
  "code": 200,
  "success": true,
  "message": "Dokumen ditemukan",
  "data": {
    "document": {
      "id": 3,
      "document_name": "test-1639646653102.pdf",
      "status": "pending",
      "signer": [
        {
          "name": "hanan"
        },
        {
          "name": "marulli"
        }
      ]
    },
    "activities": [
      {
        "actor": "hanan kusuma",
        "ip": "127.0.0.1",
        "type": "send",
        "notes": "mengirim ke maruli",
        "datetime": "04 Jan 2022 - 15:46"
      },
      {
        "actor": "hanan kusuma",
        "ip": "127.0.0.1",
        "type": "signing",
        "notes": "menandatangi dokumen",
        "datetime": "04 Jan 2022 - 15:46"
      },
      {
        "actor": "hanan kusuma",
        "ip": "127.0.0.1",
        "type": "open",
        "notes": "membuka dokumen",
        "datetime": "04 Jan 2022 - 15:46"
      },
      {
        "actor": "",
        "ip": "",
        "type": "finished",
        "notes": "dokumen selesai",
        "datetime": "04 Jan 2022 - 15:46"
      }
    ]
  }
}
</pre
            >
          </vue-code-highlight>
        </p>
      </v-card>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      tab: [
        {
          req: "Input Format",
          post: "application/json"
        },
        {
          req: "Output Format",
          post: "application/json"
        },
        {
          req: "Endpoint",
          post: "https://api-v1.momofin.com/v1/econtract/document/list"
        }
      ],
      head: [
        {
          key: "",
          val: " ",
          mandatory: "",
          length: " ",
          desc: "Token JWT yang didapatkan setelah Login"
        },
        {
          key: "",
          val: " ",
          mandatory: "",
          length: " ",
          desc: ""
        }
      ],
      body: [
        {
          field: " ",
          type: " ",
          mandatory: " ",
          length: "",
          info: "",
          ex: ""
        },
        {
          field: " ",
          type: " ",
          mandatory: " ",
          length: "",
          info: "",
          ex: ""
        }
      ],

      tabDetail: [
        {
          req: "Input Format",
          post: "application/json"
        },
        {
          req: "Output Format",
          post: "application/json"
        },
        {
          req: "Endpoint",
          post:
            "https://api-v1.momofin.com/v1/econtract/document/detail/{id dokumen}"
        }
      ],
      headDetail: [
        {
          key: "",
          val: " ",
          mandatory: "",
          length: " ",
          desc: "Token JWT yang didapatkan setelah Login"
        },
        {
          key: "",
          val: " ",
          mandatory: "",
          length: " ",
          desc: ""
        }
      ],
      bodyDetail: [
        {
          field: " ",
          type: " ",
          mandatory: " ",
          length: "",
          info: "",
          ex: ""
        },
        {
          field: " ",
          type: " ",
          mandatory: " ",
          length: "",
          info: "",
          ex: ""
        }
      ],

      tabAudit: [
        {
          req: "Input Format",
          post: "application/json"
        },
        {
          req: "Output Format",
          post: "application/json"
        },
        {
          req: "Endpoint",
          post:
            "https://api-v1.momofin.com/v1/econtract/document/activities?id={id dokumen}"
        }
      ],
      headAudit: [
        {
          key: "",
          val: " ",
          mandatory: "",
          length: " ",
          desc: "Token JWT yang didapatkan setelah Login"
        },
        {
          key: "",
          val: " ",
          mandatory: "",
          length: " ",
          desc: ""
        }
      ],
      bodyAudit: [
        {
          field: "id",
          type: " ",
          mandatory: "Ya",
          length: "",
          info: "",
          ex: ""
        },
        {
          field: " ",
          type: " ",
          mandatory: " ",
          length: "",
          info: "",
          ex: ""
        }
      ]
    };
  }
};
</script>
