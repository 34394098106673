<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">Bulk Disbursement</p>
    <p class="font-weight-bold">
      Api ini digunakan untuk melakukan proses pembayaran secara massal, dimana untuk data secara massal bisa dilakukan penginputan data secara langsung atau dengan cara mengunggah berupa file dokumen sesuai dengan format tertentu. Untuk pembayaran massal diperlukan verifikasi / approved dari pihak pihak terkait. Selain itu juga bisa dibuat pengelompokkan data penerima(misalkan untuk kebutuhan payroll, bayar tagihan ke banyak pihak, dll) individual, pembayaran secara massal ataupun pembayaran terjadwal.
    </p>
		<create />
	</v-card>
</template>

<script>
import create from './content-disb/bulk.vue';

export default {
	components: {
    create
   },
   data() {
    return {
    };
  },
};
</script>



