export default [
  { title: "Upload Dokumen", icon: "", link: "/docs/api-services/upload-document" },
  { title: "Penempatan Tandatangan", icon: "", link: "/docs/api-services/penempatan-ttd" },
  { title: "Get Billing Info", icon: "", link: "/docs/api-services/get-billing" },
  { title: "Checkout Dokumen", icon: "", link: "/docs/api-services/checkout-document" },
  { title: "Get Document Payment Status", icon: "", link: "/docs/api-services/get-document" },
  { title: "Upload Tandatangan", icon: "", link: "/docs/api-services/upload-ttd" },
  { title: "Penandatangan Dokumen", icon: "", link: "/docs/api-services/ttd-document" },
  { title: "Detail Dokumen", icon: "", link: "/docs/api-services/detail-document" },
  { title: "Kontak", icon: "", link: "/docs/api-services/contact" },		
];
