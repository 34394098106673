export default [
  { title: "User Management", icon: "", link: "/docs/api-services/user-management" },
  { title: "Roles", icon: "", link: "/docs/api-services/roles" },
  { title: "Users", icon: "", link: "/docs/api-services/users" },
  { title: "Permissions", icon: "", link: "/docs/api-services/permission" },
  { title: "Subscribe", icon: "", link: "/docs/api-services/subscribe" },
  { title: "Account Activation", icon: "", link: "/docs/api-services/activate-account" },
  { title: "Organization Type", icon: "", link: "/docs/api-services/organize-type" },
  { title: "Activation Options", icon: "", link: "/docs/api-services/activate-option" },
  { title: "Client", icon: "", link: "/docs/api-services/client" },	

  // { title: "Registrasi", icon: "", link: "/docs/api-services/registrasi" },
  // { title: "Masuk / Login", icon: "", link: "/docs/api-services/login" },
  // { title: "Profile", icon: "", link: "/docs/api-services/profile" },
  // { title: "e-KYC", icon: "", link: "/docs/api-services/e-kyc" },
  // { title: "Ubah Password", icon: "", link: "/docs/api-services/ubah-password" },
  // { title: "Lupa Password", icon: "", link: "/docs/api-services/lupa-password" },
  // { title: "Reset Password", icon: "", link: "/docs/api-services/reset-password" },					
  // { title: "Refresh Token", icon: "", link: "/docs/api-services/refresh-token" },
];
