<template>
  <v-card rounded="lg" class="bg-white py-4">
		<p class="text-capitalize text-h5">reset password</p>
		<p class="font-weight-bold">
			Informasi Umum
		</p>
		<p class="font-weight-bold">
			API ini digunakan untuk melakukan proses reset password user login system.
		</p>
		<div class="col-md-8 ps-0">			
			<!-- <p class="font-weight-bold mb-1">Header Parameter</p> -->
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tab" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in body" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/auth/users/reset_password' \
				--data-raw '{
					"email":"kusuma.hanan135@gmail.com",
					"code":"HYU76Z",
					"new_password":"abcdefg",
					"new_password_confirm":"abcdefg"
				}'</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>
	</v-card>
</template>

<script>
export default {
	data() {
    return {
		tab: 
			[
        {
					req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/auth/users/forgot_password',
        },
      ],
      head: 
			[
        {
          key: '',
          val: ' ',
          mandatory: '',
          length: ' ',
          desc: '',
        },
        {
          key: '',
          val: ' ',
          mandatory: '',
          length: ' ',
          desc: '',
        },
      ],
      body: 
			[
        {
          field: 'email',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: 'Email User',
          ex:'abc@abc.com'
        },
        {
          field: 'code',
          type: 'String',
          mandatory: ' ',
          length: '',
          info: ' ',
          ex:' '
        },
        {
          field: 'new_password',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: 'Password baru yang ingin digunakan',
          ex:''
        },
        {
          field: 'new_password_confirm',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: 'Konfirmasi password baru yang ingin digunakan',
          ex:''
        }
      ],
      resp: 
			[
        {
          field: '',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
        {
          field: '',
          type: '',
          mandatory: '',
          length: '',
          info: '',
          ex:''
        },
      ]
    };
  },
};
</script>



