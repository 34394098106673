<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">Certificate</p>
    <!-- <p class="font-weight-bold">
      API ini digunakan untuk menambahkan nama - nama channel doc yang digunakan. 
    </p> -->
    <certificate />
	</v-card>
</template>

<script>
import certificate from './content-certif/certificate.vue';

export default {
	components: {
    certificate,
   },
   data() {
    return {
    };
  },
};
</script>



