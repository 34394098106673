<template>
  <v-card rounded="lg" class="bg-white py-4">
		<!-- <p class="font-weight-bold">
			waiting ..
		</p> -->
		<div class="col-md-8 ps-0">			
			<p class="text-h5">List</p>
			<p class="font-weight-bold">
				API ini digunakan menampilkan daftar voucher
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tab" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/econtract/voucher/list' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjNiMzA3MTIwLTViYzgtMTFlYy05M2M2LTc5YzFlNzkyMWZjYiIsImJ1c2luZXNzX2lkIjoiM2IzMDcxMjEtNWJjOC0xMWVjLTkzYzYtNzljMWU3OTIxZmNiIiwicm9sZXMiOltdLCJpYXQiOjE2NDMzODY0NzYsImV4cCI6MTY0NDI1MDQ3Nn0.1e4YekPucmifCS2HAem1TEYYPDipij4qXjByd3Vgaec'
				</pre></vue-code-highlight>
				</p>
			</v-card>
			<p class="font-weight-bold mb-1">Normal Response</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p>
					<vue-code-highlight lang="json"><pre>{
  "code": 201,<br>
  "success": true,<br>
  "data": [<br>
    {<br>
      "id": 1,<br>
      "title": "Tandatangan Gratis",<br>
      "user_id": "3b307120-5bc8-11ec-93c6-79c1e7921fcb",<br>
      "business_id": null,<br>
      "code": "SGN-12FSA",<br>
      "value": [<br>
        {<br>
          "product": "ESIGN",<br>
          "amount": 2,<br>
          "description": "2 Tandatangan Gratis"<br>
        }<br>
      ],<br>
      "rules": {<br>
        "multiple_signer": true<br>
      },<br>
      "created_at": "2022-01-28 11:10:10",<br>
      "expired_at": "2022-02-28",<br>
      "used_at": null,<br>
      "document_id": null,<br>
      "description": [<br>
        "Berlaku untuk penandatanganan dokumen dengan jumlah penandatangan minimal 2 Orang"<br>
      ]<br>
    },<br>
    {<br>
      "id": 2,<br>
      "title": "Tandatangan Gratis",<br>
      "user_id": "3b307120-5bc8-11ec-93c6-79c1e7921fcb",<br>
      "business_id": null,<br>
      "code": "SGN-ABCDE",<br>
      "value": [<br>
        {<br>
          "product": "ESIGN",<br>
          "amount": 2,<br>
          "description": "2 Tandatangan Gratis"<br>
        }<br>
      ],<br>
      "rules": {<br>
        "multiple_signer": true<br>
      },<br>
      "created_at": "2022-01-28 11:10:10",<br>
      "expired_at": "2022-02-28",<br>
      "used_at": null,<br>
      "document_id": null,<br>
      "description": [<br>
        "Berlaku untuk penandatanganan dokumen dengan jumlah penandatangan minimal 2 Orang"<br>
      ]<br>
    },<br>
  ]<br>
}<br>
</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>

		<div class="col-md-8 ps-0">			
			<p class="text-h5">Detail</p>
			<p class="font-weight-bold">
				API ini digunakan menampilkan detail voucher
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabDetail" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/econtract/voucher/detail/1' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjNiMzA3MTIwLTViYzgtMTFlYy05M2M2LTc5YzFlNzkyMWZjYiIsImJ1c2luZXNzX2lkIjoiM2IzMDcxMjEtNWJjOC0xMWVjLTkzYzYtNzljMWU3OTIxZmNiIiwicm9sZXMiOltdLCJpYXQiOjE2NDI1OTU0NjYsImV4cCI6MTY0MzQ1OTQ2Nn0.IihdpaIsTYpF0kPOV1dgLqdbgi0WgARzE2bZKdABFwM'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>
	</v-card>
</template>

<script>
export default {
   data() {
    return {
			tab: 
			[
        {
          req: 'Input Format',
          post: 'multipart/form-data',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/econtract/voucher/list',
        },
      ],
      head: 
			[
        {
          key: '',
          val: ' ',
          mandatory: '',
          length: ' ',
          desc: 'Token JWT yang didapatkan setelah login',
        },
      ],
      tabDetail: 
			[
        {
          req: 'Input Format',
          post: 'multipart/form-data',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/econtract/voucher/detail/1',
        },
      ],
    };
  },
};
</script>



