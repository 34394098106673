<template>
  <v-card rounded="lg" class="bg-white py-4">
    <p class="text-h5">Document Billing Info</p>
    <p class="font-weight-bold">
      API digunakan untuk menampilkan informasi tagihan dokumen.
    </p>
    <div class="col-md-8 ps-0">
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tab" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="font-weight-bold mb-1">Params</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <tbody>
            <tr>
              <td>document_id</td>
              <td>141</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="text-h5">Request & Response Payload Sample</p>
      <p class="font-weight-bold mb-1">Request</p>
      <v-card class="pa-2" outlined>
        <p>
          <vue-code-highlight lang="curl"
            ><pre>
curl --location --request GET 'https://api-v1.momofin.com/v1/econtract/document/billing?document_id=138' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjNiMzA3MTIwLTViYzgtMTFlYy05M2M2LTc5YzFlNzkyMWZjYiIsImJ1c2luZXNzX2lkIjoiM2IzMDcxMjEtNWJjOC0xMWVjLTkzYzYtNzljMWU3OTIxZmNiIiwicm9sZXMiOltdLCJpYXQiOjE2NDIyNTg1NDIsImV4cCI6MTY0MzEyMjU0Mn0.1AsvqVFEfcgkZ7j8U-rLe6hDz93px4sOqXDnRTFmHRE'
				</pre
            ></vue-code-highlight
          >
        </p>
      </v-card>
      <p class="font-weight-bold mb-1">Normal Response</p>
      <v-card class="pa-2" outlined>
        <p>
          <vue-code-highlight lang="json"
            ><pre>
{
  "code": 201,
  "success": true,
  "data": {
    "document": {
      "name": "signing_tes-1642259325219.pdf",
      "size": "",
      "page": 1,
      "is_owner": true
    },
    "billing": [
      {
        "id": 164,
        "is_owner": true,
        "name": "hanan",
        "billing": {
          "emet": 0,
          "esign": 3
        },
        "is_paid": false,
        "payment_info": null,
        "paid_by_me": true
      }
    ],
    "total_billing": {
      "emet": 0,
      "esign": 3
    },
    "balance": {
      "esign": 0,
      "emet": 0
    }
  }
}

					</pre
            ></vue-code-highlight
          >
        </p>
      </v-card>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      tab: [
        {
          req: "Input Format",
          post: "multipart/form-data"
        },
        {
          req: "Output Format",
          post: "application/json"
        },
        {
          req: "Endpoint",
          post:
            "https://api-v1.momofin.com/v1/econtract/document/billing?document_id=141"
        }
      ],
      head: [
        {
          key: "",
          val: " ",
          mandatory: "",
          length: " ",
          desc: "Token JWT yang didapatkan setelah login"
        }
      ]
    };
  }
};
</script>

