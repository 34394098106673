<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">Payment</p>
    <!-- <p class="font-weight-bold">
      Api ini digunakan untuk melakukan proses penerimaan uang atau untuk memmpermudah orang lain melakukan pembayaran dengan cara klik tautan yang sudah didapatkan.
    </p> -->

    <!-- Get Payment Detail -->
    <div class="col-md-8 ps-0"> 
      <p class="text-h5">Get Payment Detail</p>
      <p class="font-weight-bold">
        API ini digunakan untuk menampilkan detail pembayaran.
      </p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabGet" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Params</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Transaction Type
              </th>
              <th class="text-left text-white">
                Payment
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in paramGet" :key="item.key">
              <td>{{ item.type }}</td>
              <td>{{ item.payment }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="text-h6">Request & Response Payload Sample</p>      
      <p class="font-weight-bold mb-1">Request</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/etransfer/payment/detail?transaction_type=payment&token=504c2d3030303133353032'</pre></vue-code-highlight>
        </p>
      </v-card>
    </div>

    <!-- Get Invoice Detail -->
    <div class="col-md-8 ps-0"> 
      <p class="text-h5">Get Invoice Detail</p>
      <p class="font-weight-bold">
        API ini untuk menampilkan detail tagihan.
      </p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabGetI" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Params</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Transaction Type
              </th>
              <th class="text-left text-white">
                Payment
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in paramGet" :key="item.key">
              <td>{{ item.type }}</td>
              <td>{{ item.payment }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="text-h6">Request & Response Payload Sample</p>      
      <p class="font-weight-bold mb-1">Request</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/etransfer/payment/detail?transaction_type=invoice&token=504c2d3030303133353032'</pre></vue-code-highlight>
        </p>
      </v-card>
    </div>

    <!-- Payment Select Method -->
    <div class="col-md-8 ps-0"> 
      <p class="text-h5">Payment Select Method</p>
      <p class="font-weight-bold">
        API ini digunakan untuk pilihan metode pembayaran.
      </p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                POST
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabPayment" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Content Type
              </th>
              <th class="text-left text-white">
                Client Id
              </th>
              <th class="text-left text-white">
                Api Key
              </th>
              <th class="text-left text-white">
                X-access-token
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in headPayment" :key="item.key">
              <td>{{ item.type }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.api }}</td>
              <td>{{ item.token }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Body Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Field
              </th>
              <th class="text-left text-white">
                Data Type
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Informasi Umum
              </th>
              <th class="text-left text-white">
                Contoh
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in bodyPayment" :key="item.key">
              <td>{{ item.field }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.info }}</td>
              <td>{{ item.ex }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="text-h6">Request & Response Payload Sample</p>      
      <p class="font-weight-bold mb-1">Request</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/etransfer/payment/payment_method' \
        --data-raw '{<br>
            "transaction_type":"payment",<br>
            "token":"504c2d303030343833363531",<br>
            "payment":{<br>
              "channel":"bank_transfer",<br>
              "value":"bca"<br>
            }<br>
        }'<br>
        </pre></vue-code-highlight>
        </p>
      </v-card>
    </div>

    <!-- Proses Payment -->
    <div class="col-md-8 ps-0"> 
      <p class="text-h5">Proses Payment</p>
      <p class="font-weight-bold">
        API ini digunakan untuk memproses pembayaran.
      </p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                POST
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabProcess" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Body Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Field
              </th>
              <th class="text-left text-white">
                Data Type
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Informasi Umum
              </th>
              <th class="text-left text-white">
                Contoh
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in bodyPayment" :key="item.key">
              <td>{{ item.field }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.info }}</td>
              <td>{{ item.ex }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="text-h6">Request & Response Payload Sample</p>      
      <p class="font-weight-bold mb-1">Request</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/etransfer/payment/process' \
        --data-raw '{<br>
            "transaction_type":"payment",<br>
            "token":"504c2d303030343833363531",<br>
            "payment":{<br>
              "channel":"bank_transfer",<br>
              "value":"bca"<br>
            }<br>
        }'<br>
        </pre></vue-code-highlight>
        </p>
      </v-card>
    </div>

    <!-- Proses Payment Invoice -->
    <div class="col-md-8 ps-0"> 
      <p class="text-h5">Proses Payment Invoice</p>
      <p class="font-weight-bold">
        API ini digunakan untuk memproses pembayaran tagihan.
      </p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                POST
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabPI" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Body Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Field
              </th>
              <th class="text-left text-white">
                Data Type
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Informasi Umum
              </th>
              <th class="text-left text-white">
                Contoh
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in bodyPI" :key="item.key">
              <td>{{ item.field }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.info }}</td>
              <td>{{ item.ex }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="text-h6">Request & Response Payload Sample</p>      
      <p class="font-weight-bold mb-1">Request</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/etransfer/payment/process' \
        --data-raw '{<br>
            "transaction_type":"invoice",<br>
            "token":"504c2d3030303133353032"<br>
        }'
        </pre></vue-code-highlight>
        </p>
      </v-card>
    </div>

	</v-card>
</template>

<script>

export default {
	components: {
   },
   data() {
    return {
      tabGet: 
      [
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/payment/detail?transaction_type=payment&token=504c2d3030303133353032',
        },
      ],
      paramGet:
      [
        {
          type:'token',
          payment:'504c2d3030303133353032'
        }
      ],
      tabGetI: 
      [
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/payment/detail?transaction_type=invoice&token=504c2d3030303133353032',
        },
      ],
      tabPayment: 
      [
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/payment/payment_method',
        },
      ],
      headPayment:
      [
        {
          type:'',
          id:'',
          api:'',
          token:'Token JWT yang didapatkan setelah login'
        }
      ],
      bodyPayment: 
      [
        {
          field: 'transaction_type',
          type: 'String',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'token',
          type: '',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'payment',
          type: '',
          mandatory: ' ',
          length: '',
          info: 'Channel dan value',
          ex:''
        },  
      ],
      tabProcess: 
      [
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/payment/process',
        },
      ],
      tabPI: 
      [
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/payment/process',
        },
      ],
      bodyPI: 
      [
        {
          field: 'transaction_type',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'token',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },  
      ],
    };
  },
};
</script>



