<template>
    <v-card rounded="lg" class="bg-white py-4 px-5">
        <vue-markdown :source="callingMethod"> </vue-markdown>
    </v-card>
</template>

<script>
import callingMethod from "./calling-methods.md";
import prism from "prismjs"
export default {
    mounted() {
        prism.highlightAll()
    },
    setup() {
        return {
            callingMethod,
        };
    },
};
</script>
