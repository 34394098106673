<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">Disbursement</p>
    <p class="font-weight-bold">
      Api ini digunakan untuk melakukan proses pembayaran / transfer baik pembayaran individual, pembayaran secara massal ataupun pembayaran terjadwal.
    </p>
		<disb />
	</v-card>
</template>

<script>
import disb from './content-disb/disbursement.vue';

export default {
	components: {
    disb,
   },
   data() {
    return {
    };
  },
};
</script>



