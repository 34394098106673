<template>
  <v-card rounded="lg" class="bg-white py-4">
		<p class="text-h4">Auth</p>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Get JWT Token</p>
			<!-- <p class="font-weight-bold">
				API ini digunakan untuk penandatangan dokumen baru. 
			</p> -->
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								GET
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tab" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Key
							</th>
							<th class="text-left text-white">
								Value
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Deskripsi
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in head" :key="item.key">
							<td>{{ item.key }}</td>
							<td>{{ item.val }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.desc }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h5">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/econtract/auth/get_token' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjcyM2JlMzkwLTVjYmItMTFlYy1iYTY5LTMxNjljNWYzMmU1MCIsImJ1c2luZXNzX2lkIjoiNzIzYmUzOTEtNWNiYi0xMWVjLWJhNjktMzE2OWM1ZjMyZTUwIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzOTc1MTgzNCwiZXhwIjoxNjQwNjE1ODM0fQ.hpg2EHwhJX2-PohSJUHMcGc5vceSsHVQWd1D9uOlKBw'
				</pre></vue-code-highlight>
				</p>
			</v-card>
		</div>
	</v-card>
</template>

<script>
export default {
   data() {
    return {
			tab: 
			[
        {
					req: 'Input Format',
					post: 'application/json',
        },
        {
					req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/econtract/auth/get_token',
        },
      ],
      head: 
			[
        {
          key: '',
          val: ' ',
          mandatory: '',
          length: ' ',
          desc: 'Token JWT yang didapatkan setelah login',
        },
      ],
    };
  },
};
</script>



