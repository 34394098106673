<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">Notifikasi</p>
    <p class="font-weight-bold">
      API ini digunakan untuk mengatur data notifikasi. 
    </p>
    <notif />
	</v-card>
</template>

<script>
import notif from './content-contract/notif.vue';

export default {
	components: {
    notif,
   },
   data() {
    return {
    };
  },
};
</script>



