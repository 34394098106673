<template>
  <v-card rounded="lg" class="bg-white py-4">
		<!-- Approve Disbursement -->
		<div class="col-md-8 ps-0">	
			<p class="text-h5">Approve Disbursement</p>
			<p class="font-weight-bold">
				API ini digunakan untuk mengesahkan / menyetujui proses pembayaran. 
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabApprove" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Content Type
							</th>
							<th class="text-left text-white">
								Client Id
							</th>
							<th class="text-left text-white">
								Api Key
							</th>
							<th class="text-left text-white">
								X-access-token
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in headCr" :key="item.key">
							<td>{{ item.type }}</td>
							<td>{{ item.id }}</td>
							<td>{{ item.api }}</td>
							<td>{{ item.token }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in bodyApprove" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h6">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl">curl --location --request POST 'https://api-v1.momofin.com/v1/etransfer/disbursement/approve' \
				--header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNjUxNzI0NSwiZXhwIjoxNjM2NjAzNjQ1fQ.MFvhVjk84sHC92PkSczzALkcNqAR-rWDkwTyaIuceEE' \
				--data-raw '{
						"disbursement_id":"4ee23589-b812-4114-953c-ef851664e328"
				}'
				</vue-code-highlight>
				</p>
			</v-card>
		</div>

		<!-- Request OTP Release -->
		<div class="col-md-8 ps-0">	
			<p class="text-h5">Request OTP Release</p>
			<p class="font-weight-bold">
				API ini digunakan untuk meminta kode OTP untuk proses verifikasi pembayaran.
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabRq" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Content Type
							</th>
							<th class="text-left text-white">
								Client Id
							</th>
							<th class="text-left text-white">
								Api Key
							</th>
							<th class="text-left text-white">
								X-access-token
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in headCr" :key="item.key">
							<td>{{ item.type }}</td>
							<td>{{ item.id }}</td>
							<td>{{ item.api }}</td>
							<td>{{ item.token }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in bodyRq" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h6">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl">curl --location --request POST 'https://api-v1.momofin.com/v1/etransfer/disbursement/release_code' \
				--header 'x-access-token: acess token' \
				--data-raw '{
						"disbursement_id":"ID disbursement to be processed"
				}'
				</vue-code-highlight>
				</p>
			</v-card>
		</div>

		<!-- Release Disbursement -->
		<div class="col-md-8 ps-0">	
			<p class="text-h5">Release Disbursement</p>
			<p class="font-weight-bold">
				API ini digunakan untuk menginformasikan bahwa pembayaran telah selesai/ di proses / disetujui.
			</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Request Method
							</th>
							<th class="text-left text-white">
								POST
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in tabRl" :key="item.req">
							<td>{{ item.req }}</td>
							<td>{{ item.post }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Header Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Content Type
							</th>
							<th class="text-left text-white">
								Client Id
							</th>
							<th class="text-left text-white">
								Api Key
							</th>
							<th class="text-left text-white">
								X-access-token
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in headCr" :key="item.key">
							<td>{{ item.type }}</td>
							<td>{{ item.id }}</td>
							<td>{{ item.api }}</td>
							<td>{{ item.token }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="font-weight-bold mb-1">Body Parameter</p>
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<thead style="background: #0a3564;">
						<tr>
							<th class="text-left text-white">
								Field
							</th>
							<th class="text-left text-white">
								Data Type
							</th>
							<th class="text-left text-white">
								Mandatory
							</th>
							<th class="text-left text-white">
								Length
							</th>
							<th class="text-left text-white">
								Informasi Umum
							</th>
							<th class="text-left text-white">
								Contoh
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in bodyRl" :key="item.key">
							<td>{{ item.field }}</td>
							<td>{{ item.type }}</td>
							<td>{{ item.mandatory }}</td>
							<td>{{ item.length }}</td>
							<td>{{ item.info }}</td>
							<td>{{ item.ex }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="col-md-8 ps-0">			
			<p class="text-h6">Request & Response Payload Sample</p>			
			<p class="font-weight-bold mb-1">Request</p>
			<v-card
				class="pa-2"
				outlined
			>
				<p><vue-code-highlight lang="curl">curl --location --request POST 'https://api-v1.momofin.com/v1/etransfer/disbursement/release' \
				--header 'x-access-token: acess token' \
				--data-raw '{
						"disbursement_id":"Required ;ID disbursement to be processed",
						"otp_code":"Required",
						"payment_channel":""
				}'
				</vue-code-highlight>
				</p>
			</v-card>
		</div>

	</v-card>
</template>

<script>
export default {
   data() {
    return {
			tabApprove: 
			[
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/disbursement/approve',
        },
      ],
      bodyApprove: 
			[
        {
          field: 'disbursement_id',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },      
      ],
      headCr:
      [
				{
					type:'',
					id:'',
					api:'',
					token:'Token JWT yang didapatkan setelah login'
				}
      ],
      tabRq: 
			[
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/disbursement/release_code',
        },
      ],
      bodyRq: 
			[
        {
          field: 'disbursement_id',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: '',
          ex:''
        },      
      ],
      tabRl: 
			[
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/disbursement/release',
        },
      ],
      bodyRl: 
			[
        {
          field: 'disbursement_id',
          type: 'String',
          mandatory: 'Ya',
          length: '',
          info: 'disbursement_id”Required; ID disbursement to be processed”',
          ex:''
        },
        {
          field: 'otp_code',
          type: ' ',
          mandatory: 'Ya',
          length: '',
          info: 'Required',
          ex:''
        },
        {
          field: 'payment_channel',
          type: ' ',
          mandatory: 'Ya',
          length: '',
          info: 'Optional, default value balance, option[balance, bank transfer dll])',
          ex:''
        },      
      ],
    };
  },
};
</script>



