import Vue from "vue";
import VueRouter from "vue-router";
import Views from "../views";
// import Home from '../views/Home'
// import Unauth from '../views/unauth'
// import store from '../store'
import * as Signature from "../views/docs/e-signature";
import * as Quickstart from "../views/docs/quickstart";
import * as GoLive from "../views/docs/go-live";
import * as sdkTools from "../views/docs/sdk-tools";
import * as HowToGuides from "../views/docs/how-to-guides";
import * as apiServices from "../views/docs/api-services";
import * as auth from "../views/docs/auth";
import * as guide from "../views/docs/guide";
import * as error from "../views/docs/error";
import * as Stamping from "../views/docs/stamping";

Vue.use(VueRouter);
let routes = {
  guest: [
    {
      component: async () => await import("@/views/econtract/standalone"),
      path: "/econtract/standalone",
      name: "dashboard"
    },

    // Overview
    { path: "/", name: "dashboard", component: Views.User.Dashboard },
    { path: "/docs/e-sign", component: Signature.Signature },
    {
      path: "/docs/versioning/new",
      name: "whats-new",
      component: Signature.whatsNewVue
    },
    {
      path: "/docs/versioning/release-notes",
      name: "release-notes",
      component: Signature.releaseNotes
    },
    { path: "/docs/versioning/upgrading", component: Signature.upgrading },
    { path: "/docs/concepts", name: "concepts", component: Signature.Concepts },
    {
      path: "/docs/concepts/envelopes/",
      name: "envelopes",
      component: Signature.Envelope
    },
    {
      path: "/docs/concepts/envelopes/search",
      name: "envelopes-search",
      component: Signature.searchingVue
    },
    {
      path: "/docs/concepts/envelopes/lock",
      component: Signature.envelopeLock
    },
    {
      path: "/docs/concepts/envelopes/bulk-sending",
      name: "bulk-send",
      component: Signature.bulkSending
    },
    {
      path: "/docs/concepts/envelopes/folders",
      name: "folders",
      component: Signature.folders
    },
    {
      path: "/docs/concepts/templates",
      name: "template",
      component: Signature.Template
    },
    {
      path: "/docs/concepts/templates/creating",
      name: "template-creating",
      component: Signature.creatingAndUsingTemplate
    },
    {
      path: "/docs/concepts/templates/sharing",
      name: "template-sharing",
      component: Signature.Sharing
    },
    {
      path: "/docs/concepts/templates/composite",
      name: "template-composite",
      component: Signature.Composite
    },
    { path: "/docs/concepts/tabs", name: "tabs", component: Signature.Tabs },
    {
      path: "/docs/concepts/tabs/auto-place",
      name: "tabs-autoplace",
      component: Signature.autoPlace
    },
    {
      path: "/docs/concepts/tabs/fixed",
      name: "tabs-fixed",
      component: Signature.fixed
    },
    {
      path: "/docs/concepts/tabs/pdf-transform",
      component: Signature.pdfTransformVue
    },
    {
      path: "/docs/concepts/tabs/data-replication",
      component: Signature.dataReplicationVue
    },
    {
      path: "/docs/concepts/tabs/calculated-fields",
      component: Signature.calculatedFieldsVue
    },
    {
      path: "/docs/concepts/tabs/conditional-fields",
      component: Signature.conditionalFieldsVue
    },
    {
      path: "/docs/concepts/tabs/custom-tabs",
      component: Signature.customTabsVue
    },
    { path: "/docs/concepts/tabs/payment", component: Signature.paymentVue },
    {
      path: "/docs/concepts/tabs/prefilled-tabs",
      component: Signature.prefilledTabsVue
    },

    {
      path: "/docs/concepts/documents",
      name: "documents",
      component: Signature.Document
    },
    {
      path: "/docs/concepts/documents/purging",
      name: "documents-purging",
      component: Signature.Purging
    },
    {
      path: "/docs/concepts/documents/authoritative-copies",
      name: "documents-authoritative-copies",
      component: Signature.authoritativeCopies
    },
    {
      path: "/docs/concepts/documents/supplemental",
      name: "supplemental",
      component: Signature.supplementalVue
    },
    {
      path: "/docs/concepts/documents/attachments",
      name: "attachments",
      component: Signature.attachmentsVue
    },
    {
      path: "/docs/concepts/recipients",
      name: "recipients",
      component: Signature.Recipients
    },
    {
      path: "/docs/concepts/recipients/auth",
      name: "recipients-auth",
      component: Signature.Auth
    },
    {
      path: "/docs/concepts/recipients/pause-unpause",
      name: "recipients-pause-unpause",
      component: Signature.pauseUnpause
    },
    {
      path: "/docs/concepts/recipients/conditional-recipients",
      name: "recipients-conditional-recipients",
      component: Signature.ConditionalRecipient
    },
    {
      path: "/docs/concepts/embedding/",
      name: "EmbeddedSigning",
      component: Signature.EmbeddedSigning
    },
    {
      path: "/docs/concepts/branding/",
      name: "branding",
      component: Signature.Branding
    },
    {
      path: "/docs/concepts/permissions/",
      name: "permissions",
      component: Signature.Permission
    },
    {
      path: "/docs/concepts/standards-based-signatures",
      component: Signature.StandardBased
    },
    { path: "/docs/concepts/responsive/", component: Signature.Responsive },
    {
      path: "/docs/concepts/responsive/params",
      component: Signature.RequestParam
    },
    {
      path: "/docs/concepts/responsive/setting-tabs/",
      component: Signature.settingTabs
    },
    { path: "/docs/concepts/sms-delivery/", component: Signature.SmsDelivery },
    {
      path: "/docs/concepts/sms-delivery/using-sms-esignature",
      component: Signature.usingSmsEsignatureVue
    },
    {
      path: "/docs/concepts/sms-delivery/sms-delivery-limitations",
      component: Signature.smsDeliveryLimitationsVue
    },
    {
      path: "/docs/concepts/rules-and-limits/",
      component: Signature.rulesResourceLimits
    },
    {
      path: "/docs/concepts/rules-and-limits/requests/",
      component: Signature.formattingApiRequestVue
    },
    {
      path: "/docs/concepts/rules-and-limits/responses/",
      component: Signature.handlingApiResponseVue
    },
    { path: "/docs/status-and-error-codes/", component: Signature.statusError },
    { path: "/docs/api-dashboard/", component: Signature.ApiDashboard },

    // sSignature
    {
      path: "/docs/authentication",
      name: "authentication",
      component: Signature.Authentication
    },

    // Quickstart
    { path: "/docs/go-live", name: "go-live", component: GoLive.goLive },
    {
      path: "/docs/go-live-troubleshooting",
      name: "go-live-troubleshooting",
      component: GoLive.goLiveTroubleshooting
    },
    {
      path: "/docs/after-go-live",
      name: "after-go-live",
      component: GoLive.afterGoLive
    },

    // How to guides
    {
      path: "/docs/how-to-guides",
      name: "how-to-guides",
      component: HowToGuides.howToGuides
    },
    {
      path: "/docs/code-example-launcher",
      name: "code-example-launcher",
      component: HowToGuides.codeExample
    },
    {
      path: "/docs/embed-the-docusign",
      name: "embed-the-docusign",
      component: HowToGuides.embedDocuSign
    },

    {
      path: "/docs/request-signature-email",
      name: "request-signature-email",
      component: HowToGuides.requestSignatureEmail
    },
    {
      path: "/docs/request-signature-embed",
      name: "request-signature-embed",
      component: HowToGuides.requestSignatureEmbed
    },
    {
      path: "/docs/request-signature-template",
      name: "request-signature-template",
      component: HowToGuides.requestSignatureTemplate
    },
    {
      path: "/docs/request-signature-through",
      name: "request-signature-through",
      component: HowToGuides.requestSignatureThrough
    },
    {
      path: "/docs/request-signature-sms",
      name: "request-signature-sms",
      component: HowToGuides.requestSignatureSms
    },
    {
      path: "/docs/request-signature-payment",
      name: "request-signature-payment",
      component: HowToGuides.requestSignaturePayment
    },

    {
      path: "/docs/get-envelope-information",
      name: "get-envelope-information",
      component: HowToGuides.getEnvelopeInformation
    },
    {
      path: "/docs/list-envelope-recipient",
      name: "list-envelope-recipient",
      component: HowToGuides.listEnvelopeRecipient
    },
    {
      path: "/docs/list-envelope-status",
      name: "list-envelope-status",
      component: HowToGuides.listEnvelopeStatus
    },
    {
      path: "/docs/create-template",
      name: "create-template",
      component: HowToGuides.createTemplate
    },
    {
      path: "/docs/send-envelope",
      name: "send-envelope",
      component: HowToGuides.sendEnvelope
    },
    {
      path: "/docs/bulk-send-envelope",
      name: "bulk-send-envelope",
      component: HowToGuides.bulkSendEnvelope
    },
    {
      path: "/docs/pause-signature",
      name: "pause-signature",
      component: HowToGuides.pauseSignature
    },
    {
      path: "/docs/unpause-signature",
      name: "unpause-signature",
      component: HowToGuides.unpauseSignature
    },
    {
      path: "/docs/use-recipient",
      name: "use-recipient",
      component: HowToGuides.useRecipient
    },

    {
      path: "/docs/attach-document",
      name: "attach-document",
      component: HowToGuides.attachDocument
    },
    {
      path: "/docs/convert-pdf",
      name: "convert-pdf",
      component: HowToGuides.convertPdf
    },
    {
      path: "/docs/create-signable",
      name: "create-signable",
      component: HowToGuides.createSignable
    },
    {
      path: "/docs/download-envelope",
      name: "download-envelope",
      component: HowToGuides.downloadEnvelope
    },
    {
      path: "/docs/list-envelope-document",
      name: "list-envelope-document",
      component: HowToGuides.listEnvelopeDocument
    },

    {
      path: "/docs/get-envelope-tabs",
      name: "get-envelope-tabs",
      component: HowToGuides.getEnvelopeTabs
    },
    {
      path: "/docs/get-envelope-custom",
      name: "get-envelope-custom",
      component: HowToGuides.getEnvelopeCustom
    },
    {
      path: "/docs/set-envelope-tabs",
      name: "download-envelope",
      component: HowToGuides.setEnvelopeTabs
    },
    {
      path: "/docs/set-tabs-value",
      name: "set-tabs-value",
      component: HowToGuides.setTabsValue
    },

    {
      path: "/docs/apply-brand-template",
      name: "apply-brand-template",
      component: HowToGuides.applyBrandTemplate
    },
    {
      path: "/docs/apply-brand",
      name: "apply-brand",
      component: HowToGuides.applyBrand
    },
    {
      path: "/docs/create-brand",
      name: "create-brand",
      component: HowToGuides.createBrand
    },

    {
      path: "/docs/create-permission",
      name: "create-permission",
      component: HowToGuides.createPermission
    },
    {
      path: "/docs/delete-permission",
      name: "delete-permission",
      component: HowToGuides.deletePermission
    },
    {
      path: "/docs/set-permission",
      name: "set-permission",
      component: HowToGuides.setPermission
    },
    {
      path: "/docs/update-permission",
      name: "update-permission",
      component: HowToGuides.updatePermission
    },

    {
      path: "/docs/require-access",
      name: "require-access",
      component: HowToGuides.requireAccess
    },
    {
      path: "/docs/require-id",
      name: "require-id",
      component: HowToGuides.requireId
    },
    {
      path: "/docs/require-knowledge",
      name: "require-knowledge",
      component: HowToGuides.requireKnowledge
    },
    {
      path: "/docs/require-phone",
      name: "require-phone",
      component: HowToGuides.requirePhone
    },

    // SDK and tools
    {
      path: "/docs/sdk-and-tools",
      name: "sdk-and-tools",
      component: sdkTools.sdkAndTools
    },
    { path: "/docs/c-sdk", name: "c-sdk", component: sdkTools.cSdk },
    {
      path: "/docs/c-sdk-access-modal",
      name: "c-sdk-access-modal",
      component: sdkTools.cSdkAccessModel
    },
    {
      path: "/docs/c-sdk-authentication",
      name: "c-sdk-authentication",
      component: sdkTools.cSdkAuthentication
    },
    {
      path: "/docs/c-sdk-calling-methods",
      name: "c-sdk-calling-methods",
      component: sdkTools.cSdkCallingMethods
    },
    {
      path: "/docs/c-sdk-setup-configuration",
      name: "c-sdk-setup-configuration",
      component: sdkTools.cSdkSetupConfiguration
    },

    { path: "/docs/java-sdk", name: "java-sdk", component: sdkTools.javaSdk },
    {
      path: "/docs/java-sdk-access-modal",
      name: "java-sdk-access-modal",
      component: sdkTools.javaSdkAccessModel
    },
    {
      path: "/docs/java-sdk-authentication",
      name: "java-sdk-authentication",
      component: sdkTools.javaSdkAuthentication
    },
    {
      path: "/docs/java-sdk-calling-methods",
      name: "java-sdk-calling-methods",
      component: sdkTools.javaSdkCallingMethods
    },
    {
      path: "/docs/java-sdk-setup-configuration",
      name: "java-sdk-setup-configuration",
      component: sdkTools.javaSdkSetupConfiguration
    },

    {
      path: "/docs/nodejs-sdk",
      name: "nodejs-sdk",
      component: sdkTools.nodeJsSdk
    },
    {
      path: "/docs/nodejs-sdk-access-modal",
      name: "nodejs-sdk-access-modal",
      component: sdkTools.nodeJsSdkAccessModel
    },
    {
      path: "/docs/nodejs-sdk-authentication",
      name: "nodejs-sdk-authentication",
      component: sdkTools.nodeJsSdkAuthentication
    },
    {
      path: "/docs/nodejs-sdk-calling-methods",
      name: "nodejs-sdk-calling-methods",
      component: sdkTools.nodeJsSdkCallingMethods
    },
    {
      path: "/docs/nodejs-sdk-setup-configuration",
      name: "nodejs-sdk-setup-configuration",
      component: sdkTools.nodeJsSdkSetupConfiguration
    },

    { path: "/docs/php-sdk", name: "php-sdk", component: sdkTools.phpSdk },
    {
      path: "/docs/php-sdk-access-modal",
      name: "php-sdk-access-modal",
      component: sdkTools.phpSdkAccessModel
    },
    {
      path: "/docs/php-sdk-authentication",
      name: "php-sdk-authentication",
      component: sdkTools.phpSdkAuthentication
    },
    {
      path: "/docs/php-sdk-calling-methods",
      name: "php-sdk-calling-methods",
      component: sdkTools.phpSdkCallingMethods
    },
    {
      path: "/docs/php-sdk-setup-configuration",
      name: "php-sdk-setup-configuration",
      component: sdkTools.phpSdkSetupConfiguration
    },

    {
      path: "/docs/python-sdk",
      name: "python-sdk",
      component: sdkTools.pythonSdk
    },
    {
      path: "/docs/python-sdk-access-modal",
      name: "python-sdk-access-modal",
      component: sdkTools.pythonSdkAccessModel
    },
    {
      path: "/docs/python-sdk-authentication",
      name: "python-sdk-authentication",
      component: sdkTools.pythonSdkAuthentication
    },
    {
      path: "/docs/python-sdk-calling-methods",
      name: "python-sdk-calling-methods",
      component: sdkTools.pythonSdkCallingMethods
    },
    {
      path: "/docs/python-sdk-setup-configuration",
      name: "python-sdk-setup-configuration",
      component: sdkTools.pythonSdkSetupConfiguration
    },

    { path: "/docs/ruby-sdk", name: "ruby-sdk", component: sdkTools.rubySdk },
    {
      path: "/docs/ruby-sdk-access-modal",
      name: "ruby-sdk-access-modal",
      component: sdkTools.rubySdkAccessModel
    },
    {
      path: "/docs/ruby-sdk-authentication",
      name: "ruby-sdk-authentication",
      component: sdkTools.rubySdkAuthentication
    },
    {
      path: "/docs/ruby-sdk-calling-methods",
      name: "ruby-sdk-calling-methods",
      component: sdkTools.rubySdkCallingMethods
    },
    {
      path: "/docs/ruby-sdk-setup-configuration",
      name: "ruby-sdk-setup-configuration",
      component: sdkTools.rubySdkSetupConfiguration
    },

    // Go live
    {
      path: "/docs/quickstart",
      name: "quickstart",
      component: Quickstart.Quickstart
    },
    {
      path: "/docs/quickstart-overview",
      name: "quickstart-overview",
      component: Quickstart.QuickstartOverview
    },
    {
      path: "/api-reference",
      redirect: "https://developers.docusign.com/docs/esign-rest-api/reference/"
    },

    //autentifikasi
    {
      path: "/docs/auth/autentifikasi",
      name: "autentifikasi",
      component: auth.auth
    },
    { path: "/docs/auth/token", name: "token", component: auth.token },

    //api-services
    {
      path: "/docs/api-services/user-management",
      name: "user-management",
      component: apiServices.Manage
    },
    {
      path: "/docs/api-services/roles",
      name: "roles",
      component: apiServices.Roles
    },
    {
      path: "/docs/api-services/users",
      name: "users",
      component: apiServices.Users
    },
    {
      path: "/docs/api-services/permission",
      name: "permission",
      component: apiServices.Permission
    },
    {
      path: "/docs/api-services/subscribe",
      name: "subscribe",
      component: apiServices.Subscribe
    },
    {
      path: "/docs/api-services/activate-account",
      name: "activate-account",
      component: apiServices.Activation
    },
    {
      path: "/docs/api-services/organize-type",
      name: "organize-type",
      component: apiServices.Organize
    },
    {
      path: "/docs/api-services/activate-option",
      name: "activate-option",
      component: apiServices.ActivationOption
    },
    {
      path: "/docs/api-services/client",
      name: "client",
      component: apiServices.Client
    },
    {
      path: "/docs/api-services/notification",
      name: "notification",
      component: apiServices.Notif
    },

    //guide
    {
      path: "/docs/guide/e-transfer",
      name: "e-transfer",
      component: guide.eTransfer
    },
    {
      path: "/docs/guide/disbursement",
      name: "disbursement",
      component: guide.disbursement
    },
    {
      path: "/docs/guide/bulk-disbursement",
      name: "bulk-disbursement",
      component: guide.bulk
    },
    {
      path: "/docs/guide/single-disbursement",
      name: "single-disbursement",
      component: guide.single
    },
    {
      path: "/docs/guide/schedule-disbursement",
      name: "schedule-disbursement",
      component: guide.schedule
    },
    {
      path: "/docs/guide/approve-release",
      name: "approve-release",
      component: guide.approval
    },

    //e-transfer
    {
      path: "/docs/guide/payment-link",
      name: "payment-link",
      component: guide.paymentLink
    },
    { path: "/docs/guide/invoice", name: "invoice", component: guide.invoice },
    {
      path: "/docs/guide/transaction",
      name: "transaction",
      component: guide.transaction
    },
    { path: "/docs/guide/balance", name: "balance", component: guide.balance },
    {
      path: "/docs/guide/services",
      name: "services",
      component: guide.services
    },
    { path: "/docs/guide/payment", name: "payment", component: guide.payment },
    { path: "/docs/guide/xfers", name: "xfers", component: guide.xfers },

    //e-contract
    {
      path: "/docs/guide/document",
      name: "document",
      component: guide.document
    },
    { path: "/docs/guide/auth", name: "auth", component: guide.auth },
    {
      path: "/docs/guide/inventory",
      name: "inventory",
      component: guide.inventory
    },
    { path: "/docs/guide/contact", name: "contact", component: guide.contact },
    {
      path: "/docs/guide/homepage",
      name: "homepage",
      component: guide.homepage
    },
    {
      path: "/docs/guide/notification",
      name: "notification",
      component: guide.notification
    },
    {
      path: "/docs/guide/speciment",
      name: "speciment",
      component: guide.specimen
    },
    { path: "/docs/guide/master", name: "master", component: guide.master },
    { path: "/docs/guide/voucher", name: "voucher", component: guide.voucher },

    // e-certificate
    {
      path: "/docs/guide/e-certificate",
      name: "e-certificate",
      component: guide.certif
    },
    {
      path: "/docs/guide/template",
      name: "template",
      component: guide.template
    },
    { path: "/docs/guide/design", name: "design", component: guide.design },
    {
      path: "/docs/guide/recipients",
      name: "recipients",
      component: guide.recipients
    },
    {
      path: "/docs/guide/credential",
      name: "credential",
      component: guide.credential
    },
    { path: "/docs/guide/schema", name: "schema", component: guide.schema },
    { path: "/docs/guide/publish", name: "publish", component: guide.publish },
    {
      path: "/docs/guide/certificate",
      name: "certificate",
      component: guide.certificate
    },
    //payment
    {
      path: "/docs/guide/payment-contract",
      name: "payment-contract",
      component: guide.paymentContract
    },

    //error
    {
      path: "/docs/error/http-result",
      name: "http-result",
      component: error.Http
    },
    { path: "/docs/error/erros", name: "erros", component: error.Error },
    {
      path: "/docs/error/error-scope",
      name: "error-scope",
      component: error.Scope
    },
    {
      path: "/docs/error/error-code",
      name: "error-code",
      component: error.Code
    },

    {
      path: "/docs/api-services/registrasi",
      name: "registrasi",
      component: apiServices.Registrasi
    },
    {
      path: "/docs/api-services/login",
      name: "login",
      component: apiServices.Login
    },
    {
      path: "/docs/api-services/profile",
      name: "profile",
      component: apiServices.Profile
    },
    {
      path: "/docs/api-services/e-kyc",
      name: "e-kyc",
      component: apiServices.eKyc
    },
    {
      path: "/docs/api-services/ubah-password",
      name: "ubah-password",
      component: apiServices.UbahPassword
    },
    {
      path: "/docs/api-services/lupa-password",
      name: "lupa-password",
      component: apiServices.LupaPassword
    },
    {
      path: "/docs/api-services/reset-password",
      name: "reset-password",
      component: apiServices.ResetPassword
    },
    {
      path: "/docs/api-services/refresh-token",
      name: "refresh-token",
      component: apiServices.RefreshToken
    },

    {
      path: "/docs/api-services/upload-document",
      name: "upload-document",
      component: apiServices.uploadDocument
    },
    {
      path: "/docs/api-services/penempatan-ttd",
      name: "penempatan-ttd",
      component: apiServices.penempatanTtd
    },
    {
      path: "/docs/api-services/get-billing",
      name: "get-billing",
      component: apiServices.getBilling
    },
    {
      path: "/docs/api-services/checkout-document",
      name: "checkout-document",
      component: apiServices.checkoutDoc
    },
    {
      path: "/docs/api-services/get-document",
      name: "get-document",
      component: apiServices.getDoc
    },
    {
      path: "/docs/api-services/upload-ttd",
      name: "upload-ttd",
      component: apiServices.uploadTtd
    },
    {
      path: "/docs/api-services/ttd-document",
      name: "ttd-document",
      component: apiServices.ttdDoc
    },
    {
      path: "/docs/api-services/detail-document",
      name: "detail-document",
      component: apiServices.detailDoc
    },
    {
      path: "/docs/api-services/contact",
      name: "contact",
      component: apiServices.contact
    },

    {
      path: "/docs/api-services/product",
      name: "product",
      component: apiServices.product
    },
    {
      path: "/docs/api-services/top-up",
      name: "top-up",
      component: apiServices.topUp
    },
    {
      path: "/docs/api-services/user-balance",
      name: "user-balance",
      component: apiServices.userBalance
    },
    {
      path: "/docs/api-services/document",
      name: "document",
      component: apiServices.document
    },
    { component: Stamping.GetBalance, path: "/econtract/payment-point" },
    // ],
    // guest: [
    // { path: '/', name: 'login', component: Views.Auth.Login },
    // { path: '/registration', name: 'registration', component: Views.Auth.Registration },
    // { path: '/reset-password/:id', name: 'reset-password', component: Views.Auth.ResetPassword },
    // { path: '/send-reset', name: 'send-reset', component: Views.Auth.SendReset },
    // { path: '/success-email/:id', name: 'success-email', component: Views.Auth.EmailVerifikasi },
    // { path: '/success-reset', name: 'reset-success', component: Views.Auth.SuccessReset },
  ],
  default: [
    { path: "*", name: "404 Not Found", component: Views.Errors.NotFound }
  ]
};

// routes.auth.forEach(route => { route.meta = { auth: true } });
routes.guest.forEach(route => {
  route.meta = { guest: true };
});
routes = [
  // ...routes.auth,
  ...routes.guest,
  ...routes.default
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// router.beforeEach((to, from, next) => {
//   if (to.path == '/' && store.getters.isLoggedIn) {
//     return next('/dashboard')
//   }
//   if (to.meta.auth && !store.getters.isLoggedIn) {
//     sessionStorage.setItem('momofin_auth', location.pathname)
//     return next('/')
//   }
//   if (to.meta.guest && store.getters.isLoggedIn) {
//     return next('/dashboard')
//   }
//   return next()
// })

router.beforeResolve((to, from, next) => {
  // prism.highlightAll()
  next();
});

export default router;

