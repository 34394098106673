<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">Invoice</p>
    <!-- <p class="font-weight-bold">
      Api ini digunakan untuk melakukan proses penerimaan uang atau untuk memmpermudah orang lain melakukan pembayaran dengan cara klik tautan yang sudah didapatkan.
    </p> -->

    <!-- List Invoice -->
    <div class="col-md-8 ps-0"> 
      <p class="text-h5">List Invoice</p>
      <p class="font-weight-bold">
        API ini digunakan untuk menampilkan daftar tagihan.
      </p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabList" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Content Type
              </th>
              <th class="text-left text-white">
                Client Id
              </th>
              <th class="text-left text-white">
                Api Key
              </th>
              <th class="text-left text-white">
                X-access-token
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in headCr" :key="item.key">
              <td>{{ item.type }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.api }}</td>
              <td>{{ item.token }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="text-h6">Request & Response Payload Sample</p>      
      <p class="font-weight-bold mb-1">Request</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/etransfer/invoice' \
        --header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNDE3OTg4MSwiZXhwIjoxNjM0MjY2MjgxfQ.yJ2UidqXpMi7xp0oq29ONPUgSjDf6vWQbyL4GNYTDgo'
        </pre></vue-code-highlight>
        </p>
      </v-card>
      <p class="font-weight-bold mb-1">Response</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p>
          <vue-code-highlight lang="json"><pre>
            {
  "code": 201,<br>
  "success": true,<br>
  "message": "List Invoice",<br>
  "result": {<br>
    "invoice": [<br>
      {<br>
        "id": "938b19be-4534-4b8c-b695-d6f8872e4d2a",<br>
        "business_id": "03cf9cc1-176b-11ec-b89e-3f8fa25a5bac",<br>
        "code": "DF000389162",<br>
        "fullname": "Hanan",<br>
        "email": "kusuma.hanan@gmail.com",<br>
        "mobilephone": "fdfd",<br>
        "description": "sfsf",<br>
        "payment_option": [<br>
          {<br>
            "type": "bank_transfer",<br>
            "label": "Bank Transfer",<br>
            "options": [<br>
              {<br>
                "bank_name": "bca",<br>
                "account_number": "24234325"<br>
              }<br>
            ]<br>
          },<br>
          {<br>
            "type": "retail",<br>
            "label": "Retail Outlet",<br>
            "options": [<br>
              {<br>
                "name": "Alfamart"<br>
              },<br>
              {<br>
                "name": "Indomart"<br>
              }<br>
            ]<br>
          }<br>
        ],<br>
        "payment_method": null,<br>
        "total_amount": 12000,<br>
        "item": [
          {<br>
            "name": "krupuk",<br>
            "qty": 10,<br>
            "price": 500,<br>
            "total_price": 5000<br>
          },<br>
          {<br>
            "name": "tempe",<br>
            "qty": 10,<br>
            "price": 700,<br>
            "total_price": 7000<br>
          }<br>
        ],<br>
        "note": null,<br>
        "status": "send",<br>
        "recurring": false,<br>
        "recurring_periode": null,<br>
        "due_date": "2021-10-15 07:00:00",<br>
        "createdAt": "2021-10-14 11:42:38",<br>
        "updatedAt": "2021-10-14 11:42:38"<br>
      }<br>
    ],<br>
    "pagination": {<br>
      "total_data": 1,<br>
      "total_page": 1,<br>
      "total_display": 1,<br>
      "first_page": false,<br>
      "last_page": false,<br>
      "prev": 0,<br>
      "current": 1,<br>
      "next": 0,<br>
      "detail": []<br>
    }<br>
  }<br>
}<br>

          </pre></vue-code-highlight>
        </p>
      </v-card>
    </div>

    <!-- Create Invoice -->
    <div class="col-md-8 ps-0"> 
      <p class="text-h5">Create Invoice</p>
      <p class="font-weight-bold">
        API ini digunakan untuk membuat tagihan baru.
      </p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                POST
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabCreate" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Content Type
              </th>
              <th class="text-left text-white">
                Client Id
              </th>
              <th class="text-left text-white">
                Api Key
              </th>
              <th class="text-left text-white">
                X-access-token
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in headCr" :key="item.key">
              <td>{{ item.type }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.api }}</td>
              <td>{{ item.token }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Body Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Field
              </th>
              <th class="text-left text-white">
                Data Type
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Informasi Umum
              </th>
              <th class="text-left text-white">
                Contoh
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in bodyCreate" :key="item.key">
              <td>{{ item.field }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.info }}</td>
              <td>{{ item.ex }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="text-h6">Request & Response Payload Sample</p>      
      <p class="font-weight-bold mb-1">Request</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/etransfer/invoice/create' \
      --header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNDE3OTg4MSwiZXhwIjoxNjM0MjY2MjgxfQ.yJ2UidqXpMi7xp0oq29ONPUgSjDf6vWQbyL4GNYTDgo' \
--data-raw '{<br>
    "fullname":"Hanan",<br>
    "description":"fsd",<br>
    "mobilephone":"fdfd",<br>
    "email":"kusuma.hanan@gmail.com",<br>
    "total_amount":12000,<br>
    "description":"sfsf",<br>
    "payment_option":[{<br>
        "type":"bank_transfer",<br>
        "label":"Bank Transfer",<br>
        "options":[{<br>
            "bank_name":"bca",<br>
            "account_number":"24234325"<br>
        }]<br>
    },<br>
    {<br>
        "type":"retail",<br>
        "label":"Retail Outlet",<br>
        "options":[{<br>
            "name":"Alfamart"<br>
        },<br>
        {<br>
            "name":"Indomart"<br>
        }]<br>
    }<br>
    ],<br>
    "due_date":"2021-10-15",<br>
    "item":[{<br>
        "name":"krupuk",<br>
        "qty":10,<br>
        "price":500,<br>
        "total_price":5000<br>
    },<br>
    {<br>
        "name":"tempe",<br>
        "qty":10,<br>
        "price":700,<br>
        "total_price":7000<br>
    }],<br>
    "recurring":false,<br>
    "recurring_periode":null<br>

}'<br>
</pre></vue-code-highlight>
        </p>
      </v-card>
    </div>

    <!-- Detail Invoice -->
    <div class="col-md-8 ps-0"> 
      <p class="text-h5">Detail Invoice</p>
      <p class="font-weight-bold">
        API ini digunakan menampilkan detail tagihan.  
      </p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabDetail" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Content Type
              </th>
              <th class="text-left text-white">
                Client Id
              </th>
              <th class="text-left text-white">
                Api Key
              </th>
              <th class="text-left text-white">
                X-access-token
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in headCr" :key="item.key">
              <td>{{ item.type }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.api }}</td>
              <td>{{ item.token }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="text-h6">Request & Response Payload Sample</p>      
      <p class="font-weight-bold mb-1">Request</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p><vue-code-highlight lang="curl"><pre>ccurl --location --request GET 'https://api-v1.momofin.com/v1/etransfer/invoice/c423a6b8-9b44-4af1-afcf-06c67663bcff' \
        --header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNDgwMDk4NSwiZXhwIjoxNjM0ODg3Mzg1fQ.To-pTQHIxevyOuVnj9Ef3HhtZXVeZSYmHM5_qr8kDOE'

        </pre></vue-code-highlight>
        </p>
      </v-card>
    </div>
	</v-card>
</template>

<script>

export default {
	components: {
   },
   data() {
    return {
      tabList: 
      [
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/invoice',
        },
      ],
      tabCreate: 
      [
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/invoice/create',
        },
      ],
      bodyCreate: 
      [
        {
          field: 'fullname',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        },  
        {
          field: 'mobilephone',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'email',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        },  
        {
          field: 'description',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'total_amount',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        },  
        {
          field: 'due_date',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'note',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        },  
        {          
          field: 'item',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        },  
        {
          field: 'payment_option',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'recurring',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        },  
        {
          field: 'recurring_periode',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        },      
      ],
      headCr:
      [
        {
          type:'',
          id:'',
          api:'',
          token:'Token JWT yang didapatkan setelah login'
        }
      ],
      tabDetail: 
      [
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'hhttps://api-v1.momofin.com/v1/etransfer/invoice/c423a6b8-9b44-4af1-afcf-06c67663bcff',
        },
      ],
    };
  },
};
</script>



