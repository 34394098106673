<template>
  <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">Payment Link</p>
    <p class="font-weight-bold">
      Api ini digunakan untuk melakukan proses penerimaan uang atau untuk memmpermudah orang lain melakukan pembayaran dengan cara klik tautan yang sudah didapatkan.
    </p>

    <!-- List Payment Link -->
    <div class="col-md-8 ps-0"> 
      <p class="text-h5">List Payment Link</p>
      <p class="font-weight-bold">
        API ini digunakan untuk menampilkan daftar tautan pembayaran.  
      </p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabList" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Content Type
              </th>
              <th class="text-left text-white">
                Client Id
              </th>
              <th class="text-left text-white">
                Api Key
              </th>
              <th class="text-left text-white">
                X-access-token
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in headCr" :key="item.key">
              <td>{{ item.type }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.api }}</td>
              <td>{{ item.token }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="text-h6">Request & Response Payload Sample</p>      
      <p class="font-weight-bold mb-1">Request</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/etransfer/payment_link' \
        --header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNDgwMDk4NSwiZXhwIjoxNjM0ODg3Mzg1fQ.To-pTQHIxevyOuVnj9Ef3HhtZXVeZSYmHM5_qr8kDOE'
        </pre></vue-code-highlight>
        </p>
      </v-card>
    </div>

    <!-- Create Payment Link -->
    <div class="col-md-8 ps-0"> 
      <p class="text-h5">Create Payment Link</p>
      <p class="font-weight-bold">
        API ini digunakan untuk membuat pembayaran.  
      </p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                POST
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabCreate" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Content Type
              </th>
              <th class="text-left text-white">
                Client Id
              </th>
              <th class="text-left text-white">
                Api Key
              </th>
              <th class="text-left text-white">
                X-access-token
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in headCr" :key="item.key">
              <td>{{ item.type }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.api }}</td>
              <td>{{ item.token }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Body Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Field
              </th>
              <th class="text-left text-white">
                Data Type
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Informasi Umum
              </th>
              <th class="text-left text-white">
                Contoh
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in bodyCreate" :key="item.key">
              <td>{{ item.field }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.info }}</td>
              <td>{{ item.ex }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="text-h6">Request & Response Payload Sample</p>      
      <p class="font-weight-bold mb-1">Request</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p><vue-code-highlight lang="curl"><pre>curl --location --request POST 'https://api-v1.momofin.com/v1/etransfer/payment_link/create' \
        --header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjlkOGIzY2EwLTBiMDYtMTFlYy1iZjIwLWYxNzk5MTUxOTM1YSIsImJ1c2luZXNzX2lkIjoiOWQ4YjYzYjAtMGIwNi0xMWVjLWJmMjAtZjE3OTkxNTE5MzVhIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNTQ2NjI0NSwiZXhwIjoxNjM3MTk0MjQ1fQ.bY9EWMGy6OdjnwxmJxS0Iut9ZSjHwW1vzpnZdJKPwvo' \
        --data-raw '{<br>
            "fullname":"Hanan Kusuma","mobilephone":"08532523532", <br>"email":"kusuma.hanan@gmail.com", <br>
            "description":"test payment link", <br>
            "total_amount":1000000, <br>
            "due_date":"2021-11-10",<br>
            "note":"", <br>
            "send_email":false,<br>
            "recurring":false,<br>
            "recurring_periode":""<br>
        }'<br>
        </pre></vue-code-highlight>
        </p>
      </v-card>
    </div>

    <!-- Detail Payment Link -->
    <div class="col-md-8 ps-0"> 
      <p class="text-h5">Detail Payment Link</p>
      <p class="font-weight-bold">
        API ini digunakan untuk menampilkan detail pembayaran.  
      </p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabDetail" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Content Type
              </th>
              <th class="text-left text-white">
                Client Id
              </th>
              <th class="text-left text-white">
                Api Key
              </th>
              <th class="text-left text-white">
                X-access-token
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in headCr" :key="item.key">
              <td>{{ item.type }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.api }}</td>
              <td>{{ item.token }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">     
      <p class="text-h6">Request & Response Payload Sample</p>      
      <p class="font-weight-bold mb-1">Request</p>
      <v-card
        class="pa-2"
        outlined
      >
        <p><vue-code-highlight lang="curl"><pre>curl --location --request GET 'https://api-v1.momofin.com/v1/etransfer/payment_link/b498429d-4625-4a83-93ed-449e44928119' \
        --header 'x-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAzY2Y5Y2MwLTE3NmItMTFlYy1iODllLTNmOGZhMjVhNWJhYyIsImJ1c2luZXNzX2lkIjoiMDNjZjljYzEtMTc2Yi0xMWVjLWI4OWUtM2Y4ZmEyNWE1YmFjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTYzNDgwMDk4NSwiZXhwIjoxNjM0ODg3Mzg1fQ.To-pTQHIxevyOuVnj9Ef3HhtZXVeZSYmHM5_qr8kDOE'
        </pre></vue-code-highlight>
        </p>
      </v-card>
    </div>
	</v-card>
</template>

<script>

export default {
	components: {
   },
   data() {
    return {
      tabList: 
      [
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/payment_link',
        },
      ],
      tabCreate: 
      [
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/payment_link/create',
        },
      ],
      bodyCreate: 
      [
        {
          field: 'fullname',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        },  
        {
          field: 'mobilephone',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'email',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        },  
        {
          field: 'description',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'total_amount',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        },  
        {
          field: 'due_date',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'note',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        },  
        {
          field: 'send_email',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        },
        {
          field: 'recurring',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        },  
        {
          field: 'recurring_periode',
          type: ' ',
          mandatory: ' ',
          length: '',
          info: '',
          ex:''
        },      
      ],
      headCr:
      [
        {
          type:'',
          id:'',
          api:'',
          token:'Token JWT yang didapatkan setelah login'
        }
      ],
      tabDetail: 
      [
        {
          req: 'Input Format',
          post: 'application/json',
        },
        {
          req: 'Output Format',
          post: 'application/json',
        },
        {
          req: 'Endpoint',
          post: 'https://api-v1.momofin.com/v1/etransfer/payment_link/b498429d-4625-4a83-93ed-449e44928119',
        },
      ],
    };
  },
};
</script>



