<template>
  <v-card rounded="lg" class="bg-white py-4">
    <p class="text-capitalize text-h5">Flow Upload and Signing</p>

    <!-- Get Original -->
    <div class="col-md-8 ps-0">
      <p class="text-h5">Get Original</p>
      <!-- p class="font-weight-bold">
				API ini digunakan untuk menambahkan nama - nama channel Bank yang digunakan. 
			</p> -->
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabGo" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Content Type
              </th>
              <th class="text-left text-white">
                Client Id
              </th>
              <th class="text-left text-white">
                Api Key
              </th>
              <th class="text-left text-white">
                X-access-token
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in headGo" :key="item.key">
              <td>{{ item.type }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.api }}</td>
              <td>{{ item.token }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="text-h6">Request & Response Payload Sample</p>
      <p class="font-weight-bold mb-1">Request</p>
      <v-card class="pa-2" outlined>
        <p>
          <vue-code-highlight lang="curl"
            >curl --location --request GET
            'https://api-v1.momofin.com/v1/econtract/momofingo/document/original-docs/8714'
            \ --header 'x-access-token:
            eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjNiMzA3MTIwLTViYzgtMTFlYy05M2M2LTc5YzFlNzkyMWZjYiIsImJ1c2luZXNzX2lkIjoiM2IzMDcxMjEtNWJjOC0xMWVjLTkzYzYtNzljMWU3OTIxZmNiIiwicm9sZXMiOltdLCJpYXQiOjE2MzkzNzEwMDIsImV4cCI6MTY0MDIzNTAwMn0.eROv1T-t_Qc1NTe1Ls0WiUvRfbdiHM2TC6dC41hYddM'
          </vue-code-highlight>
        </p>
      </v-card>
    </div>

    <!-- Get Data Signer By Document -->
    <div class="col-md-8 ps-0">
      <p class="text-h5">Get Data Signer By Document</p>
      <!-- <p class="font-weight-bold">
				Api ini digunakan untuk menambahkan detail akun bank yang digunakan.
			</p> -->
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                GET
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tabGd" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Content Type
              </th>
              <th class="text-left text-white">
                Client Id
              </th>
              <th class="text-left text-white">
                Api Key
              </th>
              <th class="text-left text-white">
                X-access-token
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in headGo" :key="item.key">
              <td>{{ item.type }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.api }}</td>
              <td>{{ item.token }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="text-h6">Request & Response Payload Sample</p>
      <p class="font-weight-bold mb-1">Request</p>
      <v-card class="pa-2" outlined>
        <p>
          <vue-code-highlight lang="curl"
            >curl --location --request GET
            'https://api-v1.momofin.com/v1/econtract/document/data_signer/3' \
            --header 'x-access-token:
            eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjcyM2JlMzkwLTVjYmItMTFlYy1iYTY5LTMxNjljNWYzMmU1MCIsImJ1c2luZXNzX2lkIjoiNzIzYmUzOTEtNWNiYi0xMWVjLWJhNjktMzE2OWM1ZjMyZTUwIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImlhdCI6MTY0MDk1NTg2OSwiZXhwIjoxNjQxODE5ODY5fQ.JqNlGAVLpDN98H9EPgkEXpZd4qp9rU2zLTDKv36bLD4'
          </vue-code-highlight>
        </p>
      </v-card>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      tabGo: [
        {
          req: "Input Format",
          post: "application/json",
        },
        {
          req: "Output Format",
          post: "application/json",
        },
        {
          req: "Endpoint",
          post:
            "https://api-v1.momofin.com/v1/econtract/document/download_original_file/2",
        },
      ],
      tabGe: [
        {
          req: "Input Format",
          post: "application/json",
        },
        {
          req: "Output Format",
          post: "application/json",
        },
        {
          req: "Endpoint",
          post:
            "https://api-v1.momofin.com/v1/econtract/document/download_edited_file/2",
        },
      ],
      tabGd: [
        {
          req: "Input Format",
          post: "application/json",
        },
        {
          req: "Output Format",
          post: "application/json",
        },
        {
          req: "Endpoint",
          post:
            "https://api-v1.momofin.com/v1/econtract/document/data_signer/3",
        },
      ],
    };
  },
};
</script>
