<template>
    <v-card rounded="lg" class="bg-white py-4 px-5">
		<p class="text-uppercase text-h4">ERROR CODE</p>
		<p class="">
		Error code mendeskripsikan tentang alasan alasan error berupa kode tertentu.</p>
		<div class="col-md-8 ps-0">		
			<p class="text-h5">Enumeration</p>	
			<v-simple-table class="border-primary">
				<template v-slot:default>
					<tbody>
						<tr>
							<td>104</td>
							<td> </td>
						</tr>
						<tr>
							<td>204</td>
							<td></td>
						</tr>
						<tr>
							<td>410</td>
							<td> </td>
						</tr>
						<tr>
							<td>404</td>
							<td></td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
    </v-card>
</template>

<script>
export default {
    setup() {
        return {
        };
    },
};
</script>
