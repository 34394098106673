export default [
  { title: "Get envelope information", icon: "", link: "/docs/get-envelope-information" },
  { title: "List envelope recipients", icon: "", link: "/docs/list-envelope-recipient" },
  { title: "List envelope status changes", icon: "", link: "/docs/list-envelope-status" },
  { title: "Create a template", icon: "", link: "/docs/create-template" },
  { title: "Send an envelope via your app (embedded sending)", icon: "", link: "/docs/send-envelope" },
  { title: "Bulk send envelopes", icon: "", link: "/docs/bulk-send-envelope" },
  { title: "Pause a signature workflow", icon: "", link: "/docs/pause-signature" },
  { title: "Unpause a signature workflow", icon: "", link: "/docs/unpause-signature" },
  { title: "Use conditional recipients", icon: "", link: "/docs/use-recipient" },
];
