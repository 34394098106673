<template>
    <v-card rounded="lg" class="bg-white py-4 px-5">
        <vue-markdown :source="javaSdk"> </vue-markdown>
    </v-card>
</template>

<script>
import javaSdk from "./java-sdk.md";
export default {
    setup() {
        return {
            javaSdk,
        };
    },
};
</script>
